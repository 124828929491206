/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

//import* as APITypes from "../API";
/*Mutation<InputType, OutputType> = string & {
  __generatedMutationInput: InputType;
  __generatedMutationOutput: OutputType;
*/

exports.reportWatchProgress = /* GraphQL */ `mutation ReportWatchProgress($contentId: ID!, $seconds: Int!) {
  reportWatchProgress(contentId: $contentId, seconds: $seconds)
}
` ; /* GeneratedMutation<
  APITypes.ReportWatchProgressMutationVariables,
  APITypes.ReportWatchProgressMutation
 ; */ 
exports.createUser = /* GraphQL */ `mutation CreateUser(
  $input: CreateUserInput!
  $condition: ModelUserConditionInput
) {
  createUser(input: $input, condition: $condition) {
    id
    name
    email
    createdAt
    updatedAt
    watchList {
      items {
        id
        userID
        contentID
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
    watchProgresses {
      items {
        id
        owner
        seconds
        contentId
        count
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
    notifications
    appSettingsPreferences
    stats
    __typename
  }
}
` ; /* GeneratedMutation<
  APITypes.CreateUserMutationVariables,
  APITypes.CreateUserMutation
 ; */ 
exports.updateUser = /* GraphQL */ `mutation UpdateUser(
  $input: UpdateUserInput!
  $condition: ModelUserConditionInput
) {
  updateUser(input: $input, condition: $condition) {
    id
    name
    email
    createdAt
    updatedAt
    watchList {
      items {
        id
        userID
        contentID
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
    watchProgresses {
      items {
        id
        owner
        seconds
        contentId
        count
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
    notifications
    appSettingsPreferences
    stats
    __typename
  }
}
` ; /* GeneratedMutation<
  APITypes.UpdateUserMutationVariables,
  APITypes.UpdateUserMutation
 ; */ 
exports.deleteUser = /* GraphQL */ `mutation DeleteUser(
  $input: DeleteUserInput!
  $condition: ModelUserConditionInput
) {
  deleteUser(input: $input, condition: $condition) {
    id
    name
    email
    createdAt
    updatedAt
    watchList {
      items {
        id
        userID
        contentID
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
    watchProgresses {
      items {
        id
        owner
        seconds
        contentId
        count
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
    notifications
    appSettingsPreferences
    stats
    __typename
  }
}
` ; /* GeneratedMutation<
  APITypes.DeleteUserMutationVariables,
  APITypes.DeleteUserMutation
 ; */ 
exports.createGame = /* GraphQL */ `mutation CreateGame(
  $input: CreateGameInput!
  $condition: ModelGameConditionInput
) {
  createGame(input: $input, condition: $condition) {
    id
    name
    description
    homeTeamId
    homeTeam {
      id
      name
      sport
      leagueId
      league {
        id
        imageURL
        sport
        sportTypeTags
        region
        description
        title
        imageFilename
        blurHash
        createdBy
        updatedBy
        primaryColor
        secondaryColor
        textColorForPrimaryColor
        textColorForSecondaryColor
        backgroundColor
        textColorForBackgroundColor
        abbreviation
        fullName
        buyTicketsUrl
        publishBuyTicketsAtDate
        expirationBuyTicketsAtDate
        logoImageId
        bannerImageId
        extraImage1Id
        extraImage2Id
        extraImage3Id
        extraImage4Id
        extraImage5Id
        yearFounded
        yearInauguralSeason
        numberOfTeams
        leagueInfoUrl
        leaguePromoVideoContentId
        currentSeason
        createdAt
        updatedAt
        streamingSportsAndLeaguesPageLeaguesId
        __typename
      }
      location
      imageURL
      imageFilename
      blurHash
      createdBy
      updatedBy
      createdAt
      updatedAt
      __typename
    }
    awayTeamId
    awayTeam {
      id
      name
      sport
      leagueId
      league {
        id
        imageURL
        sport
        sportTypeTags
        region
        description
        title
        imageFilename
        blurHash
        createdBy
        updatedBy
        primaryColor
        secondaryColor
        textColorForPrimaryColor
        textColorForSecondaryColor
        backgroundColor
        textColorForBackgroundColor
        abbreviation
        fullName
        buyTicketsUrl
        publishBuyTicketsAtDate
        expirationBuyTicketsAtDate
        logoImageId
        bannerImageId
        extraImage1Id
        extraImage2Id
        extraImage3Id
        extraImage4Id
        extraImage5Id
        yearFounded
        yearInauguralSeason
        numberOfTeams
        leagueInfoUrl
        leaguePromoVideoContentId
        currentSeason
        createdAt
        updatedAt
        streamingSportsAndLeaguesPageLeaguesId
        __typename
      }
      location
      imageURL
      imageFilename
      blurHash
      createdBy
      updatedBy
      createdAt
      updatedAt
      __typename
    }
    gameStartDateTime
    gameEndDateTime
    status
    tags
    seasonYear
    seasonType
    week
    fullWeek
    GB_LastScanStart
    GB_LastScanEnd
    quicksandStartIntervalSeconds
    quicksandEndIntervalSeconds
    sport
    leagueId
    league {
      id
      imageURL
      sport
      sportTypeTags
      region
      description
      title
      imageFilename
      blurHash
      createdBy
      updatedBy
      primaryColor
      secondaryColor
      textColorForPrimaryColor
      textColorForSecondaryColor
      backgroundColor
      textColorForBackgroundColor
      abbreviation
      fullName
      buyTicketsUrl
      publishBuyTicketsAtDate
      expirationBuyTicketsAtDate
      logoImageId
      logoImage {
        id
        name
        altText
        imageFilename
        blurHash
        description
        tags
        createdBy
        updatedBy
        searchSink
        createdAt
        updatedAt
        __typename
      }
      bannerImageId
      bannerImage {
        id
        name
        altText
        imageFilename
        blurHash
        description
        tags
        createdBy
        updatedBy
        searchSink
        createdAt
        updatedAt
        __typename
      }
      extraImage1Id
      extraImage1 {
        id
        name
        altText
        imageFilename
        blurHash
        description
        tags
        createdBy
        updatedBy
        searchSink
        createdAt
        updatedAt
        __typename
      }
      extraImage2Id
      extraImage2 {
        id
        name
        altText
        imageFilename
        blurHash
        description
        tags
        createdBy
        updatedBy
        searchSink
        createdAt
        updatedAt
        __typename
      }
      extraImage3Id
      extraImage3 {
        id
        name
        altText
        imageFilename
        blurHash
        description
        tags
        createdBy
        updatedBy
        searchSink
        createdAt
        updatedAt
        __typename
      }
      extraImage4Id
      extraImage4 {
        id
        name
        altText
        imageFilename
        blurHash
        description
        tags
        createdBy
        updatedBy
        searchSink
        createdAt
        updatedAt
        __typename
      }
      extraImage5Id
      extraImage5 {
        id
        name
        altText
        imageFilename
        blurHash
        description
        tags
        createdBy
        updatedBy
        searchSink
        createdAt
        updatedAt
        __typename
      }
      yearFounded
      yearInauguralSeason
      numberOfTeams
      leagueInfoUrl
      leaguePromoVideoContentId
      currentSeason
      createdAt
      updatedAt
      streamingSportsAndLeaguesPageLeaguesId
      __typename
    }
    nlseAssetHouseCode
    stats
    searchSink
    imageFilename
    blurHash
    createdBy
    updatedBy
    createdAt
    updatedAt
    programGamesId
    __typename
  }
}
` ; /* GeneratedMutation<
  APITypes.CreateGameMutationVariables,
  APITypes.CreateGameMutation
 ; */ 
exports.updateGame = /* GraphQL */ `mutation UpdateGame(
  $input: UpdateGameInput!
  $condition: ModelGameConditionInput
) {
  updateGame(input: $input, condition: $condition) {
    id
    name
    description
    homeTeamId
    homeTeam {
      id
      name
      sport
      leagueId
      league {
        id
        imageURL
        sport
        sportTypeTags
        region
        description
        title
        imageFilename
        blurHash
        createdBy
        updatedBy
        primaryColor
        secondaryColor
        textColorForPrimaryColor
        textColorForSecondaryColor
        backgroundColor
        textColorForBackgroundColor
        abbreviation
        fullName
        buyTicketsUrl
        publishBuyTicketsAtDate
        expirationBuyTicketsAtDate
        logoImageId
        bannerImageId
        extraImage1Id
        extraImage2Id
        extraImage3Id
        extraImage4Id
        extraImage5Id
        yearFounded
        yearInauguralSeason
        numberOfTeams
        leagueInfoUrl
        leaguePromoVideoContentId
        currentSeason
        createdAt
        updatedAt
        streamingSportsAndLeaguesPageLeaguesId
        __typename
      }
      location
      imageURL
      imageFilename
      blurHash
      createdBy
      updatedBy
      createdAt
      updatedAt
      __typename
    }
    awayTeamId
    awayTeam {
      id
      name
      sport
      leagueId
      league {
        id
        imageURL
        sport
        sportTypeTags
        region
        description
        title
        imageFilename
        blurHash
        createdBy
        updatedBy
        primaryColor
        secondaryColor
        textColorForPrimaryColor
        textColorForSecondaryColor
        backgroundColor
        textColorForBackgroundColor
        abbreviation
        fullName
        buyTicketsUrl
        publishBuyTicketsAtDate
        expirationBuyTicketsAtDate
        logoImageId
        bannerImageId
        extraImage1Id
        extraImage2Id
        extraImage3Id
        extraImage4Id
        extraImage5Id
        yearFounded
        yearInauguralSeason
        numberOfTeams
        leagueInfoUrl
        leaguePromoVideoContentId
        currentSeason
        createdAt
        updatedAt
        streamingSportsAndLeaguesPageLeaguesId
        __typename
      }
      location
      imageURL
      imageFilename
      blurHash
      createdBy
      updatedBy
      createdAt
      updatedAt
      __typename
    }
    gameStartDateTime
    gameEndDateTime
    status
    tags
    seasonYear
    seasonType
    week
    fullWeek
    GB_LastScanStart
    GB_LastScanEnd
    quicksandStartIntervalSeconds
    quicksandEndIntervalSeconds
    sport
    leagueId
    league {
      id
      imageURL
      sport
      sportTypeTags
      region
      description
      title
      imageFilename
      blurHash
      createdBy
      updatedBy
      primaryColor
      secondaryColor
      textColorForPrimaryColor
      textColorForSecondaryColor
      backgroundColor
      textColorForBackgroundColor
      abbreviation
      fullName
      buyTicketsUrl
      publishBuyTicketsAtDate
      expirationBuyTicketsAtDate
      logoImageId
      logoImage {
        id
        name
        altText
        imageFilename
        blurHash
        description
        tags
        createdBy
        updatedBy
        searchSink
        createdAt
        updatedAt
        __typename
      }
      bannerImageId
      bannerImage {
        id
        name
        altText
        imageFilename
        blurHash
        description
        tags
        createdBy
        updatedBy
        searchSink
        createdAt
        updatedAt
        __typename
      }
      extraImage1Id
      extraImage1 {
        id
        name
        altText
        imageFilename
        blurHash
        description
        tags
        createdBy
        updatedBy
        searchSink
        createdAt
        updatedAt
        __typename
      }
      extraImage2Id
      extraImage2 {
        id
        name
        altText
        imageFilename
        blurHash
        description
        tags
        createdBy
        updatedBy
        searchSink
        createdAt
        updatedAt
        __typename
      }
      extraImage3Id
      extraImage3 {
        id
        name
        altText
        imageFilename
        blurHash
        description
        tags
        createdBy
        updatedBy
        searchSink
        createdAt
        updatedAt
        __typename
      }
      extraImage4Id
      extraImage4 {
        id
        name
        altText
        imageFilename
        blurHash
        description
        tags
        createdBy
        updatedBy
        searchSink
        createdAt
        updatedAt
        __typename
      }
      extraImage5Id
      extraImage5 {
        id
        name
        altText
        imageFilename
        blurHash
        description
        tags
        createdBy
        updatedBy
        searchSink
        createdAt
        updatedAt
        __typename
      }
      yearFounded
      yearInauguralSeason
      numberOfTeams
      leagueInfoUrl
      leaguePromoVideoContentId
      currentSeason
      createdAt
      updatedAt
      streamingSportsAndLeaguesPageLeaguesId
      __typename
    }
    nlseAssetHouseCode
    stats
    searchSink
    imageFilename
    blurHash
    createdBy
    updatedBy
    createdAt
    updatedAt
    programGamesId
    __typename
  }
}
` ; /* GeneratedMutation<
  APITypes.UpdateGameMutationVariables,
  APITypes.UpdateGameMutation
 ; */ 
exports.deleteGame = /* GraphQL */ `mutation DeleteGame(
  $input: DeleteGameInput!
  $condition: ModelGameConditionInput
) {
  deleteGame(input: $input, condition: $condition) {
    id
    name
    description
    homeTeamId
    homeTeam {
      id
      name
      sport
      leagueId
      league {
        id
        imageURL
        sport
        sportTypeTags
        region
        description
        title
        imageFilename
        blurHash
        createdBy
        updatedBy
        primaryColor
        secondaryColor
        textColorForPrimaryColor
        textColorForSecondaryColor
        backgroundColor
        textColorForBackgroundColor
        abbreviation
        fullName
        buyTicketsUrl
        publishBuyTicketsAtDate
        expirationBuyTicketsAtDate
        logoImageId
        bannerImageId
        extraImage1Id
        extraImage2Id
        extraImage3Id
        extraImage4Id
        extraImage5Id
        yearFounded
        yearInauguralSeason
        numberOfTeams
        leagueInfoUrl
        leaguePromoVideoContentId
        currentSeason
        createdAt
        updatedAt
        streamingSportsAndLeaguesPageLeaguesId
        __typename
      }
      location
      imageURL
      imageFilename
      blurHash
      createdBy
      updatedBy
      createdAt
      updatedAt
      __typename
    }
    awayTeamId
    awayTeam {
      id
      name
      sport
      leagueId
      league {
        id
        imageURL
        sport
        sportTypeTags
        region
        description
        title
        imageFilename
        blurHash
        createdBy
        updatedBy
        primaryColor
        secondaryColor
        textColorForPrimaryColor
        textColorForSecondaryColor
        backgroundColor
        textColorForBackgroundColor
        abbreviation
        fullName
        buyTicketsUrl
        publishBuyTicketsAtDate
        expirationBuyTicketsAtDate
        logoImageId
        bannerImageId
        extraImage1Id
        extraImage2Id
        extraImage3Id
        extraImage4Id
        extraImage5Id
        yearFounded
        yearInauguralSeason
        numberOfTeams
        leagueInfoUrl
        leaguePromoVideoContentId
        currentSeason
        createdAt
        updatedAt
        streamingSportsAndLeaguesPageLeaguesId
        __typename
      }
      location
      imageURL
      imageFilename
      blurHash
      createdBy
      updatedBy
      createdAt
      updatedAt
      __typename
    }
    gameStartDateTime
    gameEndDateTime
    status
    tags
    seasonYear
    seasonType
    week
    fullWeek
    GB_LastScanStart
    GB_LastScanEnd
    quicksandStartIntervalSeconds
    quicksandEndIntervalSeconds
    sport
    leagueId
    league {
      id
      imageURL
      sport
      sportTypeTags
      region
      description
      title
      imageFilename
      blurHash
      createdBy
      updatedBy
      primaryColor
      secondaryColor
      textColorForPrimaryColor
      textColorForSecondaryColor
      backgroundColor
      textColorForBackgroundColor
      abbreviation
      fullName
      buyTicketsUrl
      publishBuyTicketsAtDate
      expirationBuyTicketsAtDate
      logoImageId
      logoImage {
        id
        name
        altText
        imageFilename
        blurHash
        description
        tags
        createdBy
        updatedBy
        searchSink
        createdAt
        updatedAt
        __typename
      }
      bannerImageId
      bannerImage {
        id
        name
        altText
        imageFilename
        blurHash
        description
        tags
        createdBy
        updatedBy
        searchSink
        createdAt
        updatedAt
        __typename
      }
      extraImage1Id
      extraImage1 {
        id
        name
        altText
        imageFilename
        blurHash
        description
        tags
        createdBy
        updatedBy
        searchSink
        createdAt
        updatedAt
        __typename
      }
      extraImage2Id
      extraImage2 {
        id
        name
        altText
        imageFilename
        blurHash
        description
        tags
        createdBy
        updatedBy
        searchSink
        createdAt
        updatedAt
        __typename
      }
      extraImage3Id
      extraImage3 {
        id
        name
        altText
        imageFilename
        blurHash
        description
        tags
        createdBy
        updatedBy
        searchSink
        createdAt
        updatedAt
        __typename
      }
      extraImage4Id
      extraImage4 {
        id
        name
        altText
        imageFilename
        blurHash
        description
        tags
        createdBy
        updatedBy
        searchSink
        createdAt
        updatedAt
        __typename
      }
      extraImage5Id
      extraImage5 {
        id
        name
        altText
        imageFilename
        blurHash
        description
        tags
        createdBy
        updatedBy
        searchSink
        createdAt
        updatedAt
        __typename
      }
      yearFounded
      yearInauguralSeason
      numberOfTeams
      leagueInfoUrl
      leaguePromoVideoContentId
      currentSeason
      createdAt
      updatedAt
      streamingSportsAndLeaguesPageLeaguesId
      __typename
    }
    nlseAssetHouseCode
    stats
    searchSink
    imageFilename
    blurHash
    createdBy
    updatedBy
    createdAt
    updatedAt
    programGamesId
    __typename
  }
}
` ; /* GeneratedMutation<
  APITypes.DeleteGameMutationVariables,
  APITypes.DeleteGameMutation
 ; */ 
exports.createProgram = /* GraphQL */ `mutation CreateProgram(
  $input: CreateProgramInput!
  $condition: ModelProgramConditionInput
) {
  createProgram(input: $input, condition: $condition) {
    id
    name
    description
    tvRating
    sport
    leagueId
    league {
      id
      imageURL
      sport
      sportTypeTags
      region
      description
      title
      imageFilename
      blurHash
      createdBy
      updatedBy
      primaryColor
      secondaryColor
      textColorForPrimaryColor
      textColorForSecondaryColor
      backgroundColor
      textColorForBackgroundColor
      abbreviation
      fullName
      buyTicketsUrl
      publishBuyTicketsAtDate
      expirationBuyTicketsAtDate
      logoImageId
      logoImage {
        id
        name
        altText
        imageFilename
        blurHash
        description
        tags
        createdBy
        updatedBy
        searchSink
        createdAt
        updatedAt
        __typename
      }
      bannerImageId
      bannerImage {
        id
        name
        altText
        imageFilename
        blurHash
        description
        tags
        createdBy
        updatedBy
        searchSink
        createdAt
        updatedAt
        __typename
      }
      extraImage1Id
      extraImage1 {
        id
        name
        altText
        imageFilename
        blurHash
        description
        tags
        createdBy
        updatedBy
        searchSink
        createdAt
        updatedAt
        __typename
      }
      extraImage2Id
      extraImage2 {
        id
        name
        altText
        imageFilename
        blurHash
        description
        tags
        createdBy
        updatedBy
        searchSink
        createdAt
        updatedAt
        __typename
      }
      extraImage3Id
      extraImage3 {
        id
        name
        altText
        imageFilename
        blurHash
        description
        tags
        createdBy
        updatedBy
        searchSink
        createdAt
        updatedAt
        __typename
      }
      extraImage4Id
      extraImage4 {
        id
        name
        altText
        imageFilename
        blurHash
        description
        tags
        createdBy
        updatedBy
        searchSink
        createdAt
        updatedAt
        __typename
      }
      extraImage5Id
      extraImage5 {
        id
        name
        altText
        imageFilename
        blurHash
        description
        tags
        createdBy
        updatedBy
        searchSink
        createdAt
        updatedAt
        __typename
      }
      yearFounded
      yearInauguralSeason
      numberOfTeams
      leagueInfoUrl
      leaguePromoVideoContentId
      currentSeason
      createdAt
      updatedAt
      streamingSportsAndLeaguesPageLeaguesId
      __typename
    }
    tags
    games {
      items {
        id
        name
        description
        homeTeamId
        awayTeamId
        gameStartDateTime
        gameEndDateTime
        status
        tags
        seasonYear
        seasonType
        week
        fullWeek
        GB_LastScanStart
        GB_LastScanEnd
        quicksandStartIntervalSeconds
        quicksandEndIntervalSeconds
        sport
        leagueId
        nlseAssetHouseCode
        stats
        searchSink
        imageFilename
        blurHash
        createdBy
        updatedBy
        createdAt
        updatedAt
        programGamesId
        __typename
      }
      nextToken
      __typename
    }
    streamingLiveScheduleEvents {
      items {
        id
        title
        description
        imageURL
        DateTime
        filterDateTime
        gameId
        contentId
        leagueId
        tags
        playbackURL
        createdBy
        updatedBy
        version
        imageFilename
        blurHash
        createdAt
        updatedAt
        programStreamingLiveScheduleEventsId
        __typename
      }
      nextToken
      __typename
    }
    createdBy
    updatedBy
    imageFilename
    blurHash
    seasons
    publishAtDate
    expirationAtDate
    onlyShowEpisodesInAProgramAsOneItem
    availabilityStatus
    createdAt
    updatedAt
    __typename
  }
}
` ; /* GeneratedMutation<
  APITypes.CreateProgramMutationVariables,
  APITypes.CreateProgramMutation
 ; */ 
exports.updateProgram = /* GraphQL */ `mutation UpdateProgram(
  $input: UpdateProgramInput!
  $condition: ModelProgramConditionInput
) {
  updateProgram(input: $input, condition: $condition) {
    id
    name
    description
    tvRating
    sport
    leagueId
    league {
      id
      imageURL
      sport
      sportTypeTags
      region
      description
      title
      imageFilename
      blurHash
      createdBy
      updatedBy
      primaryColor
      secondaryColor
      textColorForPrimaryColor
      textColorForSecondaryColor
      backgroundColor
      textColorForBackgroundColor
      abbreviation
      fullName
      buyTicketsUrl
      publishBuyTicketsAtDate
      expirationBuyTicketsAtDate
      logoImageId
      logoImage {
        id
        name
        altText
        imageFilename
        blurHash
        description
        tags
        createdBy
        updatedBy
        searchSink
        createdAt
        updatedAt
        __typename
      }
      bannerImageId
      bannerImage {
        id
        name
        altText
        imageFilename
        blurHash
        description
        tags
        createdBy
        updatedBy
        searchSink
        createdAt
        updatedAt
        __typename
      }
      extraImage1Id
      extraImage1 {
        id
        name
        altText
        imageFilename
        blurHash
        description
        tags
        createdBy
        updatedBy
        searchSink
        createdAt
        updatedAt
        __typename
      }
      extraImage2Id
      extraImage2 {
        id
        name
        altText
        imageFilename
        blurHash
        description
        tags
        createdBy
        updatedBy
        searchSink
        createdAt
        updatedAt
        __typename
      }
      extraImage3Id
      extraImage3 {
        id
        name
        altText
        imageFilename
        blurHash
        description
        tags
        createdBy
        updatedBy
        searchSink
        createdAt
        updatedAt
        __typename
      }
      extraImage4Id
      extraImage4 {
        id
        name
        altText
        imageFilename
        blurHash
        description
        tags
        createdBy
        updatedBy
        searchSink
        createdAt
        updatedAt
        __typename
      }
      extraImage5Id
      extraImage5 {
        id
        name
        altText
        imageFilename
        blurHash
        description
        tags
        createdBy
        updatedBy
        searchSink
        createdAt
        updatedAt
        __typename
      }
      yearFounded
      yearInauguralSeason
      numberOfTeams
      leagueInfoUrl
      leaguePromoVideoContentId
      currentSeason
      createdAt
      updatedAt
      streamingSportsAndLeaguesPageLeaguesId
      __typename
    }
    tags
    games {
      items {
        id
        name
        description
        homeTeamId
        awayTeamId
        gameStartDateTime
        gameEndDateTime
        status
        tags
        seasonYear
        seasonType
        week
        fullWeek
        GB_LastScanStart
        GB_LastScanEnd
        quicksandStartIntervalSeconds
        quicksandEndIntervalSeconds
        sport
        leagueId
        nlseAssetHouseCode
        stats
        searchSink
        imageFilename
        blurHash
        createdBy
        updatedBy
        createdAt
        updatedAt
        programGamesId
        __typename
      }
      nextToken
      __typename
    }
    streamingLiveScheduleEvents {
      items {
        id
        title
        description
        imageURL
        DateTime
        filterDateTime
        gameId
        contentId
        leagueId
        tags
        playbackURL
        createdBy
        updatedBy
        version
        imageFilename
        blurHash
        createdAt
        updatedAt
        programStreamingLiveScheduleEventsId
        __typename
      }
      nextToken
      __typename
    }
    createdBy
    updatedBy
    imageFilename
    blurHash
    seasons
    publishAtDate
    expirationAtDate
    onlyShowEpisodesInAProgramAsOneItem
    availabilityStatus
    createdAt
    updatedAt
    __typename
  }
}
` ; /* GeneratedMutation<
  APITypes.UpdateProgramMutationVariables,
  APITypes.UpdateProgramMutation
 ; */ 
exports.deleteProgram = /* GraphQL */ `mutation DeleteProgram(
  $input: DeleteProgramInput!
  $condition: ModelProgramConditionInput
) {
  deleteProgram(input: $input, condition: $condition) {
    id
    name
    description
    tvRating
    sport
    leagueId
    league {
      id
      imageURL
      sport
      sportTypeTags
      region
      description
      title
      imageFilename
      blurHash
      createdBy
      updatedBy
      primaryColor
      secondaryColor
      textColorForPrimaryColor
      textColorForSecondaryColor
      backgroundColor
      textColorForBackgroundColor
      abbreviation
      fullName
      buyTicketsUrl
      publishBuyTicketsAtDate
      expirationBuyTicketsAtDate
      logoImageId
      logoImage {
        id
        name
        altText
        imageFilename
        blurHash
        description
        tags
        createdBy
        updatedBy
        searchSink
        createdAt
        updatedAt
        __typename
      }
      bannerImageId
      bannerImage {
        id
        name
        altText
        imageFilename
        blurHash
        description
        tags
        createdBy
        updatedBy
        searchSink
        createdAt
        updatedAt
        __typename
      }
      extraImage1Id
      extraImage1 {
        id
        name
        altText
        imageFilename
        blurHash
        description
        tags
        createdBy
        updatedBy
        searchSink
        createdAt
        updatedAt
        __typename
      }
      extraImage2Id
      extraImage2 {
        id
        name
        altText
        imageFilename
        blurHash
        description
        tags
        createdBy
        updatedBy
        searchSink
        createdAt
        updatedAt
        __typename
      }
      extraImage3Id
      extraImage3 {
        id
        name
        altText
        imageFilename
        blurHash
        description
        tags
        createdBy
        updatedBy
        searchSink
        createdAt
        updatedAt
        __typename
      }
      extraImage4Id
      extraImage4 {
        id
        name
        altText
        imageFilename
        blurHash
        description
        tags
        createdBy
        updatedBy
        searchSink
        createdAt
        updatedAt
        __typename
      }
      extraImage5Id
      extraImage5 {
        id
        name
        altText
        imageFilename
        blurHash
        description
        tags
        createdBy
        updatedBy
        searchSink
        createdAt
        updatedAt
        __typename
      }
      yearFounded
      yearInauguralSeason
      numberOfTeams
      leagueInfoUrl
      leaguePromoVideoContentId
      currentSeason
      createdAt
      updatedAt
      streamingSportsAndLeaguesPageLeaguesId
      __typename
    }
    tags
    games {
      items {
        id
        name
        description
        homeTeamId
        awayTeamId
        gameStartDateTime
        gameEndDateTime
        status
        tags
        seasonYear
        seasonType
        week
        fullWeek
        GB_LastScanStart
        GB_LastScanEnd
        quicksandStartIntervalSeconds
        quicksandEndIntervalSeconds
        sport
        leagueId
        nlseAssetHouseCode
        stats
        searchSink
        imageFilename
        blurHash
        createdBy
        updatedBy
        createdAt
        updatedAt
        programGamesId
        __typename
      }
      nextToken
      __typename
    }
    streamingLiveScheduleEvents {
      items {
        id
        title
        description
        imageURL
        DateTime
        filterDateTime
        gameId
        contentId
        leagueId
        tags
        playbackURL
        createdBy
        updatedBy
        version
        imageFilename
        blurHash
        createdAt
        updatedAt
        programStreamingLiveScheduleEventsId
        __typename
      }
      nextToken
      __typename
    }
    createdBy
    updatedBy
    imageFilename
    blurHash
    seasons
    publishAtDate
    expirationAtDate
    onlyShowEpisodesInAProgramAsOneItem
    availabilityStatus
    createdAt
    updatedAt
    __typename
  }
}
` ; /* GeneratedMutation<
  APITypes.DeleteProgramMutationVariables,
  APITypes.DeleteProgramMutation
 ; */ 
exports.createContent = /* GraphQL */ `mutation CreateContent(
  $input: CreateContentInput!
  $condition: ModelContentConditionInput
) {
  createContent(input: $input, condition: $condition) {
    id
    title
    description
    note
    video
    gameId
    watchList {
      items {
        id
        userID
        contentID
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
    game {
      id
      name
      description
      homeTeamId
      homeTeam {
        id
        name
        sport
        leagueId
        location
        imageURL
        imageFilename
        blurHash
        createdBy
        updatedBy
        createdAt
        updatedAt
        __typename
      }
      awayTeamId
      awayTeam {
        id
        name
        sport
        leagueId
        location
        imageURL
        imageFilename
        blurHash
        createdBy
        updatedBy
        createdAt
        updatedAt
        __typename
      }
      gameStartDateTime
      gameEndDateTime
      status
      tags
      seasonYear
      seasonType
      week
      fullWeek
      GB_LastScanStart
      GB_LastScanEnd
      quicksandStartIntervalSeconds
      quicksandEndIntervalSeconds
      sport
      leagueId
      league {
        id
        imageURL
        sport
        sportTypeTags
        region
        description
        title
        imageFilename
        blurHash
        createdBy
        updatedBy
        primaryColor
        secondaryColor
        textColorForPrimaryColor
        textColorForSecondaryColor
        backgroundColor
        textColorForBackgroundColor
        abbreviation
        fullName
        buyTicketsUrl
        publishBuyTicketsAtDate
        expirationBuyTicketsAtDate
        logoImageId
        bannerImageId
        extraImage1Id
        extraImage2Id
        extraImage3Id
        extraImage4Id
        extraImage5Id
        yearFounded
        yearInauguralSeason
        numberOfTeams
        leagueInfoUrl
        leaguePromoVideoContentId
        currentSeason
        createdAt
        updatedAt
        streamingSportsAndLeaguesPageLeaguesId
        __typename
      }
      nlseAssetHouseCode
      stats
      searchSink
      imageFilename
      blurHash
      createdBy
      updatedBy
      createdAt
      updatedAt
      programGamesId
      __typename
    }
    vodEntryId
    vodEntry {
      id
      body
      contentIdString
      dashUrl
      dashPlaylist
      hlsUrl
      hlsPlaylist
      stage
      publishedAt
      createdBy
      updatedBy
      errorStatus
      errorDescripton
      createdAt
      updatedAt
      __typename
    }
    programId
    program {
      id
      name
      description
      tvRating
      sport
      leagueId
      league {
        id
        imageURL
        sport
        sportTypeTags
        region
        description
        title
        imageFilename
        blurHash
        createdBy
        updatedBy
        primaryColor
        secondaryColor
        textColorForPrimaryColor
        textColorForSecondaryColor
        backgroundColor
        textColorForBackgroundColor
        abbreviation
        fullName
        buyTicketsUrl
        publishBuyTicketsAtDate
        expirationBuyTicketsAtDate
        logoImageId
        bannerImageId
        extraImage1Id
        extraImage2Id
        extraImage3Id
        extraImage4Id
        extraImage5Id
        yearFounded
        yearInauguralSeason
        numberOfTeams
        leagueInfoUrl
        leaguePromoVideoContentId
        currentSeason
        createdAt
        updatedAt
        streamingSportsAndLeaguesPageLeaguesId
        __typename
      }
      tags
      games {
        nextToken
        __typename
      }
      streamingLiveScheduleEvents {
        nextToken
        __typename
      }
      createdBy
      updatedBy
      imageFilename
      blurHash
      seasons
      publishAtDate
      expirationAtDate
      onlyShowEpisodesInAProgramAsOneItem
      availabilityStatus
      createdAt
      updatedAt
      __typename
    }
    tvRating
    contentType
    applicationType
    nlseAssetHouseCode
    episode
    episodeId
    episodeOrder
    episodeSeason
    isSupplementalContent
    supplementalContentOrder
    mainContentId
    publishAtDate
    expirationAtDate
    availabilityStatus
    contentLength
    seasonYear
    seasonType
    location
    leagueId
    league {
      id
      imageURL
      sport
      sportTypeTags
      region
      description
      title
      imageFilename
      blurHash
      createdBy
      updatedBy
      primaryColor
      secondaryColor
      textColorForPrimaryColor
      textColorForSecondaryColor
      backgroundColor
      textColorForBackgroundColor
      abbreviation
      fullName
      buyTicketsUrl
      publishBuyTicketsAtDate
      expirationBuyTicketsAtDate
      logoImageId
      logoImage {
        id
        name
        altText
        imageFilename
        blurHash
        description
        tags
        createdBy
        updatedBy
        searchSink
        createdAt
        updatedAt
        __typename
      }
      bannerImageId
      bannerImage {
        id
        name
        altText
        imageFilename
        blurHash
        description
        tags
        createdBy
        updatedBy
        searchSink
        createdAt
        updatedAt
        __typename
      }
      extraImage1Id
      extraImage1 {
        id
        name
        altText
        imageFilename
        blurHash
        description
        tags
        createdBy
        updatedBy
        searchSink
        createdAt
        updatedAt
        __typename
      }
      extraImage2Id
      extraImage2 {
        id
        name
        altText
        imageFilename
        blurHash
        description
        tags
        createdBy
        updatedBy
        searchSink
        createdAt
        updatedAt
        __typename
      }
      extraImage3Id
      extraImage3 {
        id
        name
        altText
        imageFilename
        blurHash
        description
        tags
        createdBy
        updatedBy
        searchSink
        createdAt
        updatedAt
        __typename
      }
      extraImage4Id
      extraImage4 {
        id
        name
        altText
        imageFilename
        blurHash
        description
        tags
        createdBy
        updatedBy
        searchSink
        createdAt
        updatedAt
        __typename
      }
      extraImage5Id
      extraImage5 {
        id
        name
        altText
        imageFilename
        blurHash
        description
        tags
        createdBy
        updatedBy
        searchSink
        createdAt
        updatedAt
        __typename
      }
      yearFounded
      yearInauguralSeason
      numberOfTeams
      leagueInfoUrl
      leaguePromoVideoContentId
      currentSeason
      createdAt
      updatedAt
      streamingSportsAndLeaguesPageLeaguesId
      __typename
    }
    sport
    tags
    stats
    searchSink
    imageFilename
    blurHash
    createdBy
    updatedBy
    reservationArn
    reservationAuthorized
    reservationName
    reservationPlaybackURL
    reservationTags
    reservationType
    oldReservationArn
    liveToVodPlaybackURL
    errorStatus
    errorMessageForVideoUpload
    createdAt
    updatedAt
    __typename
  }
}
` ; /* GeneratedMutation<
  APITypes.CreateContentMutationVariables,
  APITypes.CreateContentMutation
 ; */ 
exports.updateContent = /* GraphQL */ `mutation UpdateContent(
  $input: UpdateContentInput!
  $condition: ModelContentConditionInput
) {
  updateContent(input: $input, condition: $condition) {
    id
    title
    description
    note
    video
    gameId
    watchList {
      items {
        id
        userID
        contentID
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
    game {
      id
      name
      description
      homeTeamId
      homeTeam {
        id
        name
        sport
        leagueId
        location
        imageURL
        imageFilename
        blurHash
        createdBy
        updatedBy
        createdAt
        updatedAt
        __typename
      }
      awayTeamId
      awayTeam {
        id
        name
        sport
        leagueId
        location
        imageURL
        imageFilename
        blurHash
        createdBy
        updatedBy
        createdAt
        updatedAt
        __typename
      }
      gameStartDateTime
      gameEndDateTime
      status
      tags
      seasonYear
      seasonType
      week
      fullWeek
      GB_LastScanStart
      GB_LastScanEnd
      quicksandStartIntervalSeconds
      quicksandEndIntervalSeconds
      sport
      leagueId
      league {
        id
        imageURL
        sport
        sportTypeTags
        region
        description
        title
        imageFilename
        blurHash
        createdBy
        updatedBy
        primaryColor
        secondaryColor
        textColorForPrimaryColor
        textColorForSecondaryColor
        backgroundColor
        textColorForBackgroundColor
        abbreviation
        fullName
        buyTicketsUrl
        publishBuyTicketsAtDate
        expirationBuyTicketsAtDate
        logoImageId
        bannerImageId
        extraImage1Id
        extraImage2Id
        extraImage3Id
        extraImage4Id
        extraImage5Id
        yearFounded
        yearInauguralSeason
        numberOfTeams
        leagueInfoUrl
        leaguePromoVideoContentId
        currentSeason
        createdAt
        updatedAt
        streamingSportsAndLeaguesPageLeaguesId
        __typename
      }
      nlseAssetHouseCode
      stats
      searchSink
      imageFilename
      blurHash
      createdBy
      updatedBy
      createdAt
      updatedAt
      programGamesId
      __typename
    }
    vodEntryId
    vodEntry {
      id
      body
      contentIdString
      dashUrl
      dashPlaylist
      hlsUrl
      hlsPlaylist
      stage
      publishedAt
      createdBy
      updatedBy
      errorStatus
      errorDescripton
      createdAt
      updatedAt
      __typename
    }
    programId
    program {
      id
      name
      description
      tvRating
      sport
      leagueId
      league {
        id
        imageURL
        sport
        sportTypeTags
        region
        description
        title
        imageFilename
        blurHash
        createdBy
        updatedBy
        primaryColor
        secondaryColor
        textColorForPrimaryColor
        textColorForSecondaryColor
        backgroundColor
        textColorForBackgroundColor
        abbreviation
        fullName
        buyTicketsUrl
        publishBuyTicketsAtDate
        expirationBuyTicketsAtDate
        logoImageId
        bannerImageId
        extraImage1Id
        extraImage2Id
        extraImage3Id
        extraImage4Id
        extraImage5Id
        yearFounded
        yearInauguralSeason
        numberOfTeams
        leagueInfoUrl
        leaguePromoVideoContentId
        currentSeason
        createdAt
        updatedAt
        streamingSportsAndLeaguesPageLeaguesId
        __typename
      }
      tags
      games {
        nextToken
        __typename
      }
      streamingLiveScheduleEvents {
        nextToken
        __typename
      }
      createdBy
      updatedBy
      imageFilename
      blurHash
      seasons
      publishAtDate
      expirationAtDate
      onlyShowEpisodesInAProgramAsOneItem
      availabilityStatus
      createdAt
      updatedAt
      __typename
    }
    tvRating
    contentType
    applicationType
    nlseAssetHouseCode
    episode
    episodeId
    episodeOrder
    episodeSeason
    isSupplementalContent
    supplementalContentOrder
    mainContentId
    publishAtDate
    expirationAtDate
    availabilityStatus
    contentLength
    seasonYear
    seasonType
    location
    leagueId
    league {
      id
      imageURL
      sport
      sportTypeTags
      region
      description
      title
      imageFilename
      blurHash
      createdBy
      updatedBy
      primaryColor
      secondaryColor
      textColorForPrimaryColor
      textColorForSecondaryColor
      backgroundColor
      textColorForBackgroundColor
      abbreviation
      fullName
      buyTicketsUrl
      publishBuyTicketsAtDate
      expirationBuyTicketsAtDate
      logoImageId
      logoImage {
        id
        name
        altText
        imageFilename
        blurHash
        description
        tags
        createdBy
        updatedBy
        searchSink
        createdAt
        updatedAt
        __typename
      }
      bannerImageId
      bannerImage {
        id
        name
        altText
        imageFilename
        blurHash
        description
        tags
        createdBy
        updatedBy
        searchSink
        createdAt
        updatedAt
        __typename
      }
      extraImage1Id
      extraImage1 {
        id
        name
        altText
        imageFilename
        blurHash
        description
        tags
        createdBy
        updatedBy
        searchSink
        createdAt
        updatedAt
        __typename
      }
      extraImage2Id
      extraImage2 {
        id
        name
        altText
        imageFilename
        blurHash
        description
        tags
        createdBy
        updatedBy
        searchSink
        createdAt
        updatedAt
        __typename
      }
      extraImage3Id
      extraImage3 {
        id
        name
        altText
        imageFilename
        blurHash
        description
        tags
        createdBy
        updatedBy
        searchSink
        createdAt
        updatedAt
        __typename
      }
      extraImage4Id
      extraImage4 {
        id
        name
        altText
        imageFilename
        blurHash
        description
        tags
        createdBy
        updatedBy
        searchSink
        createdAt
        updatedAt
        __typename
      }
      extraImage5Id
      extraImage5 {
        id
        name
        altText
        imageFilename
        blurHash
        description
        tags
        createdBy
        updatedBy
        searchSink
        createdAt
        updatedAt
        __typename
      }
      yearFounded
      yearInauguralSeason
      numberOfTeams
      leagueInfoUrl
      leaguePromoVideoContentId
      currentSeason
      createdAt
      updatedAt
      streamingSportsAndLeaguesPageLeaguesId
      __typename
    }
    sport
    tags
    stats
    searchSink
    imageFilename
    blurHash
    createdBy
    updatedBy
    reservationArn
    reservationAuthorized
    reservationName
    reservationPlaybackURL
    reservationTags
    reservationType
    oldReservationArn
    liveToVodPlaybackURL
    errorStatus
    errorMessageForVideoUpload
    createdAt
    updatedAt
    __typename
  }
}
` ; /* GeneratedMutation<
  APITypes.UpdateContentMutationVariables,
  APITypes.UpdateContentMutation
 ; */ 
exports.deleteContent = /* GraphQL */ `mutation DeleteContent(
  $input: DeleteContentInput!
  $condition: ModelContentConditionInput
) {
  deleteContent(input: $input, condition: $condition) {
    id
    title
    description
    note
    video
    gameId
    watchList {
      items {
        id
        userID
        contentID
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
    game {
      id
      name
      description
      homeTeamId
      homeTeam {
        id
        name
        sport
        leagueId
        location
        imageURL
        imageFilename
        blurHash
        createdBy
        updatedBy
        createdAt
        updatedAt
        __typename
      }
      awayTeamId
      awayTeam {
        id
        name
        sport
        leagueId
        location
        imageURL
        imageFilename
        blurHash
        createdBy
        updatedBy
        createdAt
        updatedAt
        __typename
      }
      gameStartDateTime
      gameEndDateTime
      status
      tags
      seasonYear
      seasonType
      week
      fullWeek
      GB_LastScanStart
      GB_LastScanEnd
      quicksandStartIntervalSeconds
      quicksandEndIntervalSeconds
      sport
      leagueId
      league {
        id
        imageURL
        sport
        sportTypeTags
        region
        description
        title
        imageFilename
        blurHash
        createdBy
        updatedBy
        primaryColor
        secondaryColor
        textColorForPrimaryColor
        textColorForSecondaryColor
        backgroundColor
        textColorForBackgroundColor
        abbreviation
        fullName
        buyTicketsUrl
        publishBuyTicketsAtDate
        expirationBuyTicketsAtDate
        logoImageId
        bannerImageId
        extraImage1Id
        extraImage2Id
        extraImage3Id
        extraImage4Id
        extraImage5Id
        yearFounded
        yearInauguralSeason
        numberOfTeams
        leagueInfoUrl
        leaguePromoVideoContentId
        currentSeason
        createdAt
        updatedAt
        streamingSportsAndLeaguesPageLeaguesId
        __typename
      }
      nlseAssetHouseCode
      stats
      searchSink
      imageFilename
      blurHash
      createdBy
      updatedBy
      createdAt
      updatedAt
      programGamesId
      __typename
    }
    vodEntryId
    vodEntry {
      id
      body
      contentIdString
      dashUrl
      dashPlaylist
      hlsUrl
      hlsPlaylist
      stage
      publishedAt
      createdBy
      updatedBy
      errorStatus
      errorDescripton
      createdAt
      updatedAt
      __typename
    }
    programId
    program {
      id
      name
      description
      tvRating
      sport
      leagueId
      league {
        id
        imageURL
        sport
        sportTypeTags
        region
        description
        title
        imageFilename
        blurHash
        createdBy
        updatedBy
        primaryColor
        secondaryColor
        textColorForPrimaryColor
        textColorForSecondaryColor
        backgroundColor
        textColorForBackgroundColor
        abbreviation
        fullName
        buyTicketsUrl
        publishBuyTicketsAtDate
        expirationBuyTicketsAtDate
        logoImageId
        bannerImageId
        extraImage1Id
        extraImage2Id
        extraImage3Id
        extraImage4Id
        extraImage5Id
        yearFounded
        yearInauguralSeason
        numberOfTeams
        leagueInfoUrl
        leaguePromoVideoContentId
        currentSeason
        createdAt
        updatedAt
        streamingSportsAndLeaguesPageLeaguesId
        __typename
      }
      tags
      games {
        nextToken
        __typename
      }
      streamingLiveScheduleEvents {
        nextToken
        __typename
      }
      createdBy
      updatedBy
      imageFilename
      blurHash
      seasons
      publishAtDate
      expirationAtDate
      onlyShowEpisodesInAProgramAsOneItem
      availabilityStatus
      createdAt
      updatedAt
      __typename
    }
    tvRating
    contentType
    applicationType
    nlseAssetHouseCode
    episode
    episodeId
    episodeOrder
    episodeSeason
    isSupplementalContent
    supplementalContentOrder
    mainContentId
    publishAtDate
    expirationAtDate
    availabilityStatus
    contentLength
    seasonYear
    seasonType
    location
    leagueId
    league {
      id
      imageURL
      sport
      sportTypeTags
      region
      description
      title
      imageFilename
      blurHash
      createdBy
      updatedBy
      primaryColor
      secondaryColor
      textColorForPrimaryColor
      textColorForSecondaryColor
      backgroundColor
      textColorForBackgroundColor
      abbreviation
      fullName
      buyTicketsUrl
      publishBuyTicketsAtDate
      expirationBuyTicketsAtDate
      logoImageId
      logoImage {
        id
        name
        altText
        imageFilename
        blurHash
        description
        tags
        createdBy
        updatedBy
        searchSink
        createdAt
        updatedAt
        __typename
      }
      bannerImageId
      bannerImage {
        id
        name
        altText
        imageFilename
        blurHash
        description
        tags
        createdBy
        updatedBy
        searchSink
        createdAt
        updatedAt
        __typename
      }
      extraImage1Id
      extraImage1 {
        id
        name
        altText
        imageFilename
        blurHash
        description
        tags
        createdBy
        updatedBy
        searchSink
        createdAt
        updatedAt
        __typename
      }
      extraImage2Id
      extraImage2 {
        id
        name
        altText
        imageFilename
        blurHash
        description
        tags
        createdBy
        updatedBy
        searchSink
        createdAt
        updatedAt
        __typename
      }
      extraImage3Id
      extraImage3 {
        id
        name
        altText
        imageFilename
        blurHash
        description
        tags
        createdBy
        updatedBy
        searchSink
        createdAt
        updatedAt
        __typename
      }
      extraImage4Id
      extraImage4 {
        id
        name
        altText
        imageFilename
        blurHash
        description
        tags
        createdBy
        updatedBy
        searchSink
        createdAt
        updatedAt
        __typename
      }
      extraImage5Id
      extraImage5 {
        id
        name
        altText
        imageFilename
        blurHash
        description
        tags
        createdBy
        updatedBy
        searchSink
        createdAt
        updatedAt
        __typename
      }
      yearFounded
      yearInauguralSeason
      numberOfTeams
      leagueInfoUrl
      leaguePromoVideoContentId
      currentSeason
      createdAt
      updatedAt
      streamingSportsAndLeaguesPageLeaguesId
      __typename
    }
    sport
    tags
    stats
    searchSink
    imageFilename
    blurHash
    createdBy
    updatedBy
    reservationArn
    reservationAuthorized
    reservationName
    reservationPlaybackURL
    reservationTags
    reservationType
    oldReservationArn
    liveToVodPlaybackURL
    errorStatus
    errorMessageForVideoUpload
    createdAt
    updatedAt
    __typename
  }
}
` ; /* GeneratedMutation<
  APITypes.DeleteContentMutationVariables,
  APITypes.DeleteContentMutation
 ; */ 
exports.createStreamingHomePage = /* GraphQL */ `mutation CreateStreamingHomePage(
  $input: CreateStreamingHomePageInput!
  $condition: ModelStreamingHomePageConditionInput
) {
  createStreamingHomePage(input: $input, condition: $condition) {
    id
    categoryTitle
    categoryOrder
    contentIDs
    programIDs
    leagueIDs
    sport
    sportTags
    sportTagsBooleanOp
    contentLayoutStyle
    createdAt
    updatedAt
    __typename
  }
}
` ; /* GeneratedMutation<
  APITypes.CreateStreamingHomePageMutationVariables,
  APITypes.CreateStreamingHomePageMutation
 ; */ 
exports.updateStreamingHomePage = /* GraphQL */ `mutation UpdateStreamingHomePage(
  $input: UpdateStreamingHomePageInput!
  $condition: ModelStreamingHomePageConditionInput
) {
  updateStreamingHomePage(input: $input, condition: $condition) {
    id
    categoryTitle
    categoryOrder
    contentIDs
    programIDs
    leagueIDs
    sport
    sportTags
    sportTagsBooleanOp
    contentLayoutStyle
    createdAt
    updatedAt
    __typename
  }
}
` ; /* GeneratedMutation<
  APITypes.UpdateStreamingHomePageMutationVariables,
  APITypes.UpdateStreamingHomePageMutation
 ; */ 
exports.deleteStreamingHomePage = /* GraphQL */ `mutation DeleteStreamingHomePage(
  $input: DeleteStreamingHomePageInput!
  $condition: ModelStreamingHomePageConditionInput
) {
  deleteStreamingHomePage(input: $input, condition: $condition) {
    id
    categoryTitle
    categoryOrder
    contentIDs
    programIDs
    leagueIDs
    sport
    sportTags
    sportTagsBooleanOp
    contentLayoutStyle
    createdAt
    updatedAt
    __typename
  }
}
` ; /* GeneratedMutation<
  APITypes.DeleteStreamingHomePageMutationVariables,
  APITypes.DeleteStreamingHomePageMutation
 ; */ 
exports.createStreamingSportsAndLeaguesPage = /* GraphQL */ `mutation CreateStreamingSportsAndLeaguesPage(
  $input: CreateStreamingSportsAndLeaguesPageInput!
  $condition: ModelStreamingSportsAndLeaguesPageConditionInput
) {
  createStreamingSportsAndLeaguesPage(input: $input, condition: $condition) {
    id
    categoryTitle
    categoryOrder
    sport
    sportTags
    leagues {
      items {
        id
        imageURL
        sport
        sportTypeTags
        region
        description
        title
        imageFilename
        blurHash
        createdBy
        updatedBy
        primaryColor
        secondaryColor
        textColorForPrimaryColor
        textColorForSecondaryColor
        backgroundColor
        textColorForBackgroundColor
        abbreviation
        fullName
        buyTicketsUrl
        publishBuyTicketsAtDate
        expirationBuyTicketsAtDate
        logoImageId
        bannerImageId
        extraImage1Id
        extraImage2Id
        extraImage3Id
        extraImage4Id
        extraImage5Id
        yearFounded
        yearInauguralSeason
        numberOfTeams
        leagueInfoUrl
        leaguePromoVideoContentId
        currentSeason
        createdAt
        updatedAt
        streamingSportsAndLeaguesPageLeaguesId
        __typename
      }
      nextToken
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` ; /* GeneratedMutation<
  APITypes.CreateStreamingSportsAndLeaguesPageMutationVariables,
  APITypes.CreateStreamingSportsAndLeaguesPageMutation
 ; */ 
exports.updateStreamingSportsAndLeaguesPage = /* GraphQL */ `mutation UpdateStreamingSportsAndLeaguesPage(
  $input: UpdateStreamingSportsAndLeaguesPageInput!
  $condition: ModelStreamingSportsAndLeaguesPageConditionInput
) {
  updateStreamingSportsAndLeaguesPage(input: $input, condition: $condition) {
    id
    categoryTitle
    categoryOrder
    sport
    sportTags
    leagues {
      items {
        id
        imageURL
        sport
        sportTypeTags
        region
        description
        title
        imageFilename
        blurHash
        createdBy
        updatedBy
        primaryColor
        secondaryColor
        textColorForPrimaryColor
        textColorForSecondaryColor
        backgroundColor
        textColorForBackgroundColor
        abbreviation
        fullName
        buyTicketsUrl
        publishBuyTicketsAtDate
        expirationBuyTicketsAtDate
        logoImageId
        bannerImageId
        extraImage1Id
        extraImage2Id
        extraImage3Id
        extraImage4Id
        extraImage5Id
        yearFounded
        yearInauguralSeason
        numberOfTeams
        leagueInfoUrl
        leaguePromoVideoContentId
        currentSeason
        createdAt
        updatedAt
        streamingSportsAndLeaguesPageLeaguesId
        __typename
      }
      nextToken
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` ; /* GeneratedMutation<
  APITypes.UpdateStreamingSportsAndLeaguesPageMutationVariables,
  APITypes.UpdateStreamingSportsAndLeaguesPageMutation
 ; */ 
exports.deleteStreamingSportsAndLeaguesPage = /* GraphQL */ `mutation DeleteStreamingSportsAndLeaguesPage(
  $input: DeleteStreamingSportsAndLeaguesPageInput!
  $condition: ModelStreamingSportsAndLeaguesPageConditionInput
) {
  deleteStreamingSportsAndLeaguesPage(input: $input, condition: $condition) {
    id
    categoryTitle
    categoryOrder
    sport
    sportTags
    leagues {
      items {
        id
        imageURL
        sport
        sportTypeTags
        region
        description
        title
        imageFilename
        blurHash
        createdBy
        updatedBy
        primaryColor
        secondaryColor
        textColorForPrimaryColor
        textColorForSecondaryColor
        backgroundColor
        textColorForBackgroundColor
        abbreviation
        fullName
        buyTicketsUrl
        publishBuyTicketsAtDate
        expirationBuyTicketsAtDate
        logoImageId
        bannerImageId
        extraImage1Id
        extraImage2Id
        extraImage3Id
        extraImage4Id
        extraImage5Id
        yearFounded
        yearInauguralSeason
        numberOfTeams
        leagueInfoUrl
        leaguePromoVideoContentId
        currentSeason
        createdAt
        updatedAt
        streamingSportsAndLeaguesPageLeaguesId
        __typename
      }
      nextToken
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` ; /* GeneratedMutation<
  APITypes.DeleteStreamingSportsAndLeaguesPageMutationVariables,
  APITypes.DeleteStreamingSportsAndLeaguesPageMutation
 ; */ 
exports.createLiveStreamChannel = /* GraphQL */ `mutation CreateLiveStreamChannel(
  $input: CreateLiveStreamChannelInput!
  $condition: ModelLiveStreamChannelConditionInput
) {
  createLiveStreamChannel(input: $input, condition: $condition) {
    id
    channel_name
    cdn_url
    endpoint_url
    is_active
    is_deleted
    stats
    searchSink
    inputType
    stackStatus
    stackStatusReason
    stackSubjectToPeriodicUpdates
    stackParameters
    stackOutputs
    stackArn
    stackReady
    stackOutputCloudFrontCmafEndpoint
    stackOutputCloudFrontDashEndpoint
    stackOutputCloudFrontHlsEndpoint
    stackOutputDemoBucketConsole
    stackOutputDemoPlayer
    stackOutputLogsBucketConsole
    stackOutputMediaLiveChannelConsole
    stackOutputMediaLiveChannelId
    stackOutputMediaLiveMetrics
    stackOutputMediaLivePrimaryEndpoint
    stackOutputMediaLiveSecondaryEndpoint
    stackOutputMediaPackageMetrics
    stackOutputAnonymousMetricUUID
    stackOutputAppRegistryConsole
    scheduledStartTime
    createdAt
    updatedAt
    __typename
  }
}
` ; /* GeneratedMutation<
  APITypes.CreateLiveStreamChannelMutationVariables,
  APITypes.CreateLiveStreamChannelMutation
 ; */ 
exports.updateLiveStreamChannel = /* GraphQL */ `mutation UpdateLiveStreamChannel(
  $input: UpdateLiveStreamChannelInput!
  $condition: ModelLiveStreamChannelConditionInput
) {
  updateLiveStreamChannel(input: $input, condition: $condition) {
    id
    channel_name
    cdn_url
    endpoint_url
    is_active
    is_deleted
    stats
    searchSink
    inputType
    stackStatus
    stackStatusReason
    stackSubjectToPeriodicUpdates
    stackParameters
    stackOutputs
    stackArn
    stackReady
    stackOutputCloudFrontCmafEndpoint
    stackOutputCloudFrontDashEndpoint
    stackOutputCloudFrontHlsEndpoint
    stackOutputDemoBucketConsole
    stackOutputDemoPlayer
    stackOutputLogsBucketConsole
    stackOutputMediaLiveChannelConsole
    stackOutputMediaLiveChannelId
    stackOutputMediaLiveMetrics
    stackOutputMediaLivePrimaryEndpoint
    stackOutputMediaLiveSecondaryEndpoint
    stackOutputMediaPackageMetrics
    stackOutputAnonymousMetricUUID
    stackOutputAppRegistryConsole
    scheduledStartTime
    createdAt
    updatedAt
    __typename
  }
}
` ; /* GeneratedMutation<
  APITypes.UpdateLiveStreamChannelMutationVariables,
  APITypes.UpdateLiveStreamChannelMutation
 ; */ 
exports.deleteLiveStreamChannel = /* GraphQL */ `mutation DeleteLiveStreamChannel(
  $input: DeleteLiveStreamChannelInput!
  $condition: ModelLiveStreamChannelConditionInput
) {
  deleteLiveStreamChannel(input: $input, condition: $condition) {
    id
    channel_name
    cdn_url
    endpoint_url
    is_active
    is_deleted
    stats
    searchSink
    inputType
    stackStatus
    stackStatusReason
    stackSubjectToPeriodicUpdates
    stackParameters
    stackOutputs
    stackArn
    stackReady
    stackOutputCloudFrontCmafEndpoint
    stackOutputCloudFrontDashEndpoint
    stackOutputCloudFrontHlsEndpoint
    stackOutputDemoBucketConsole
    stackOutputDemoPlayer
    stackOutputLogsBucketConsole
    stackOutputMediaLiveChannelConsole
    stackOutputMediaLiveChannelId
    stackOutputMediaLiveMetrics
    stackOutputMediaLivePrimaryEndpoint
    stackOutputMediaLiveSecondaryEndpoint
    stackOutputMediaPackageMetrics
    stackOutputAnonymousMetricUUID
    stackOutputAppRegistryConsole
    scheduledStartTime
    createdAt
    updatedAt
    __typename
  }
}
` ; /* GeneratedMutation<
  APITypes.DeleteLiveStreamChannelMutationVariables,
  APITypes.DeleteLiveStreamChannelMutation
 ; */ 
exports.createVodEntry = /* GraphQL */ `mutation CreateVodEntry(
  $input: CreateVodEntryInput!
  $condition: ModelVodEntryConditionInput
) {
  createVodEntry(input: $input, condition: $condition) {
    id
    body
    contentIdString
    dashUrl
    dashPlaylist
    hlsUrl
    hlsPlaylist
    stage
    publishedAt
    createdBy
    updatedBy
    errorStatus
    errorDescripton
    createdAt
    updatedAt
    __typename
  }
}
` ; /* GeneratedMutation<
  APITypes.CreateVodEntryMutationVariables,
  APITypes.CreateVodEntryMutation
 ; */ 
exports.updateVodEntry = /* GraphQL */ `mutation UpdateVodEntry(
  $input: UpdateVodEntryInput!
  $condition: ModelVodEntryConditionInput
) {
  updateVodEntry(input: $input, condition: $condition) {
    id
    body
    contentIdString
    dashUrl
    dashPlaylist
    hlsUrl
    hlsPlaylist
    stage
    publishedAt
    createdBy
    updatedBy
    errorStatus
    errorDescripton
    createdAt
    updatedAt
    __typename
  }
}
` ; /* GeneratedMutation<
  APITypes.UpdateVodEntryMutationVariables,
  APITypes.UpdateVodEntryMutation
 ; */ 
exports.deleteVodEntry = /* GraphQL */ `mutation DeleteVodEntry(
  $input: DeleteVodEntryInput!
  $condition: ModelVodEntryConditionInput
) {
  deleteVodEntry(input: $input, condition: $condition) {
    id
    body
    contentIdString
    dashUrl
    dashPlaylist
    hlsUrl
    hlsPlaylist
    stage
    publishedAt
    createdBy
    updatedBy
    errorStatus
    errorDescripton
    createdAt
    updatedAt
    __typename
  }
}
` ; /* GeneratedMutation<
  APITypes.DeleteVodEntryMutationVariables,
  APITypes.DeleteVodEntryMutation
 ; */ 
exports.createVodDeleteInProgress = /* GraphQL */ `mutation CreateVodDeleteInProgress(
  $input: CreateVodDeleteInProgressInput!
  $condition: ModelVodDeleteInProgressConditionInput
) {
  createVodDeleteInProgress(input: $input, condition: $condition) {
    id
    destinationBucketContinuationToken
    body
    contentIdString
    dashUrl
    dashPlaylist
    hlsUrl
    hlsPlaylist
    note
    createdAt
    updatedAt
    __typename
  }
}
` ; /* GeneratedMutation<
  APITypes.CreateVodDeleteInProgressMutationVariables,
  APITypes.CreateVodDeleteInProgressMutation
 ; */ 
exports.updateVodDeleteInProgress = /* GraphQL */ `mutation UpdateVodDeleteInProgress(
  $input: UpdateVodDeleteInProgressInput!
  $condition: ModelVodDeleteInProgressConditionInput
) {
  updateVodDeleteInProgress(input: $input, condition: $condition) {
    id
    destinationBucketContinuationToken
    body
    contentIdString
    dashUrl
    dashPlaylist
    hlsUrl
    hlsPlaylist
    note
    createdAt
    updatedAt
    __typename
  }
}
` ; /* GeneratedMutation<
  APITypes.UpdateVodDeleteInProgressMutationVariables,
  APITypes.UpdateVodDeleteInProgressMutation
 ; */ 
exports.deleteVodDeleteInProgress = /* GraphQL */ `mutation DeleteVodDeleteInProgress(
  $input: DeleteVodDeleteInProgressInput!
  $condition: ModelVodDeleteInProgressConditionInput
) {
  deleteVodDeleteInProgress(input: $input, condition: $condition) {
    id
    destinationBucketContinuationToken
    body
    contentIdString
    dashUrl
    dashPlaylist
    hlsUrl
    hlsPlaylist
    note
    createdAt
    updatedAt
    __typename
  }
}
` ; /* GeneratedMutation<
  APITypes.DeleteVodDeleteInProgressMutationVariables,
  APITypes.DeleteVodDeleteInProgressMutation
 ; */ 
exports.createWatchProgress = /* GraphQL */ `mutation CreateWatchProgress(
  $input: CreateWatchProgressInput!
  $condition: ModelWatchProgressConditionInput
) {
  createWatchProgress(input: $input, condition: $condition) {
    id
    owner
    seconds
    contentId
    content {
      id
      title
      description
      note
      video
      gameId
      watchList {
        nextToken
        __typename
      }
      game {
        id
        name
        description
        homeTeamId
        awayTeamId
        gameStartDateTime
        gameEndDateTime
        status
        tags
        seasonYear
        seasonType
        week
        fullWeek
        GB_LastScanStart
        GB_LastScanEnd
        quicksandStartIntervalSeconds
        quicksandEndIntervalSeconds
        sport
        leagueId
        nlseAssetHouseCode
        stats
        searchSink
        imageFilename
        blurHash
        createdBy
        updatedBy
        createdAt
        updatedAt
        programGamesId
        __typename
      }
      vodEntryId
      vodEntry {
        id
        body
        contentIdString
        dashUrl
        dashPlaylist
        hlsUrl
        hlsPlaylist
        stage
        publishedAt
        createdBy
        updatedBy
        errorStatus
        errorDescripton
        createdAt
        updatedAt
        __typename
      }
      programId
      program {
        id
        name
        description
        tvRating
        sport
        leagueId
        tags
        createdBy
        updatedBy
        imageFilename
        blurHash
        seasons
        publishAtDate
        expirationAtDate
        onlyShowEpisodesInAProgramAsOneItem
        availabilityStatus
        createdAt
        updatedAt
        __typename
      }
      tvRating
      contentType
      applicationType
      nlseAssetHouseCode
      episode
      episodeId
      episodeOrder
      episodeSeason
      isSupplementalContent
      supplementalContentOrder
      mainContentId
      publishAtDate
      expirationAtDate
      availabilityStatus
      contentLength
      seasonYear
      seasonType
      location
      leagueId
      league {
        id
        imageURL
        sport
        sportTypeTags
        region
        description
        title
        imageFilename
        blurHash
        createdBy
        updatedBy
        primaryColor
        secondaryColor
        textColorForPrimaryColor
        textColorForSecondaryColor
        backgroundColor
        textColorForBackgroundColor
        abbreviation
        fullName
        buyTicketsUrl
        publishBuyTicketsAtDate
        expirationBuyTicketsAtDate
        logoImageId
        bannerImageId
        extraImage1Id
        extraImage2Id
        extraImage3Id
        extraImage4Id
        extraImage5Id
        yearFounded
        yearInauguralSeason
        numberOfTeams
        leagueInfoUrl
        leaguePromoVideoContentId
        currentSeason
        createdAt
        updatedAt
        streamingSportsAndLeaguesPageLeaguesId
        __typename
      }
      sport
      tags
      stats
      searchSink
      imageFilename
      blurHash
      createdBy
      updatedBy
      reservationArn
      reservationAuthorized
      reservationName
      reservationPlaybackURL
      reservationTags
      reservationType
      oldReservationArn
      liveToVodPlaybackURL
      errorStatus
      errorMessageForVideoUpload
      createdAt
      updatedAt
      __typename
    }
    count
    createdAt
    updatedAt
    __typename
  }
}
` ; /* GeneratedMutation<
  APITypes.CreateWatchProgressMutationVariables,
  APITypes.CreateWatchProgressMutation
 ; */ 
exports.updateWatchProgress = /* GraphQL */ `mutation UpdateWatchProgress(
  $input: UpdateWatchProgressInput!
  $condition: ModelWatchProgressConditionInput
) {
  updateWatchProgress(input: $input, condition: $condition) {
    id
    owner
    seconds
    contentId
    content {
      id
      title
      description
      note
      video
      gameId
      watchList {
        nextToken
        __typename
      }
      game {
        id
        name
        description
        homeTeamId
        awayTeamId
        gameStartDateTime
        gameEndDateTime
        status
        tags
        seasonYear
        seasonType
        week
        fullWeek
        GB_LastScanStart
        GB_LastScanEnd
        quicksandStartIntervalSeconds
        quicksandEndIntervalSeconds
        sport
        leagueId
        nlseAssetHouseCode
        stats
        searchSink
        imageFilename
        blurHash
        createdBy
        updatedBy
        createdAt
        updatedAt
        programGamesId
        __typename
      }
      vodEntryId
      vodEntry {
        id
        body
        contentIdString
        dashUrl
        dashPlaylist
        hlsUrl
        hlsPlaylist
        stage
        publishedAt
        createdBy
        updatedBy
        errorStatus
        errorDescripton
        createdAt
        updatedAt
        __typename
      }
      programId
      program {
        id
        name
        description
        tvRating
        sport
        leagueId
        tags
        createdBy
        updatedBy
        imageFilename
        blurHash
        seasons
        publishAtDate
        expirationAtDate
        onlyShowEpisodesInAProgramAsOneItem
        availabilityStatus
        createdAt
        updatedAt
        __typename
      }
      tvRating
      contentType
      applicationType
      nlseAssetHouseCode
      episode
      episodeId
      episodeOrder
      episodeSeason
      isSupplementalContent
      supplementalContentOrder
      mainContentId
      publishAtDate
      expirationAtDate
      availabilityStatus
      contentLength
      seasonYear
      seasonType
      location
      leagueId
      league {
        id
        imageURL
        sport
        sportTypeTags
        region
        description
        title
        imageFilename
        blurHash
        createdBy
        updatedBy
        primaryColor
        secondaryColor
        textColorForPrimaryColor
        textColorForSecondaryColor
        backgroundColor
        textColorForBackgroundColor
        abbreviation
        fullName
        buyTicketsUrl
        publishBuyTicketsAtDate
        expirationBuyTicketsAtDate
        logoImageId
        bannerImageId
        extraImage1Id
        extraImage2Id
        extraImage3Id
        extraImage4Id
        extraImage5Id
        yearFounded
        yearInauguralSeason
        numberOfTeams
        leagueInfoUrl
        leaguePromoVideoContentId
        currentSeason
        createdAt
        updatedAt
        streamingSportsAndLeaguesPageLeaguesId
        __typename
      }
      sport
      tags
      stats
      searchSink
      imageFilename
      blurHash
      createdBy
      updatedBy
      reservationArn
      reservationAuthorized
      reservationName
      reservationPlaybackURL
      reservationTags
      reservationType
      oldReservationArn
      liveToVodPlaybackURL
      errorStatus
      errorMessageForVideoUpload
      createdAt
      updatedAt
      __typename
    }
    count
    createdAt
    updatedAt
    __typename
  }
}
` ; /* GeneratedMutation<
  APITypes.UpdateWatchProgressMutationVariables,
  APITypes.UpdateWatchProgressMutation
 ; */ 
exports.deleteWatchProgress = /* GraphQL */ `mutation DeleteWatchProgress(
  $input: DeleteWatchProgressInput!
  $condition: ModelWatchProgressConditionInput
) {
  deleteWatchProgress(input: $input, condition: $condition) {
    id
    owner
    seconds
    contentId
    content {
      id
      title
      description
      note
      video
      gameId
      watchList {
        nextToken
        __typename
      }
      game {
        id
        name
        description
        homeTeamId
        awayTeamId
        gameStartDateTime
        gameEndDateTime
        status
        tags
        seasonYear
        seasonType
        week
        fullWeek
        GB_LastScanStart
        GB_LastScanEnd
        quicksandStartIntervalSeconds
        quicksandEndIntervalSeconds
        sport
        leagueId
        nlseAssetHouseCode
        stats
        searchSink
        imageFilename
        blurHash
        createdBy
        updatedBy
        createdAt
        updatedAt
        programGamesId
        __typename
      }
      vodEntryId
      vodEntry {
        id
        body
        contentIdString
        dashUrl
        dashPlaylist
        hlsUrl
        hlsPlaylist
        stage
        publishedAt
        createdBy
        updatedBy
        errorStatus
        errorDescripton
        createdAt
        updatedAt
        __typename
      }
      programId
      program {
        id
        name
        description
        tvRating
        sport
        leagueId
        tags
        createdBy
        updatedBy
        imageFilename
        blurHash
        seasons
        publishAtDate
        expirationAtDate
        onlyShowEpisodesInAProgramAsOneItem
        availabilityStatus
        createdAt
        updatedAt
        __typename
      }
      tvRating
      contentType
      applicationType
      nlseAssetHouseCode
      episode
      episodeId
      episodeOrder
      episodeSeason
      isSupplementalContent
      supplementalContentOrder
      mainContentId
      publishAtDate
      expirationAtDate
      availabilityStatus
      contentLength
      seasonYear
      seasonType
      location
      leagueId
      league {
        id
        imageURL
        sport
        sportTypeTags
        region
        description
        title
        imageFilename
        blurHash
        createdBy
        updatedBy
        primaryColor
        secondaryColor
        textColorForPrimaryColor
        textColorForSecondaryColor
        backgroundColor
        textColorForBackgroundColor
        abbreviation
        fullName
        buyTicketsUrl
        publishBuyTicketsAtDate
        expirationBuyTicketsAtDate
        logoImageId
        bannerImageId
        extraImage1Id
        extraImage2Id
        extraImage3Id
        extraImage4Id
        extraImage5Id
        yearFounded
        yearInauguralSeason
        numberOfTeams
        leagueInfoUrl
        leaguePromoVideoContentId
        currentSeason
        createdAt
        updatedAt
        streamingSportsAndLeaguesPageLeaguesId
        __typename
      }
      sport
      tags
      stats
      searchSink
      imageFilename
      blurHash
      createdBy
      updatedBy
      reservationArn
      reservationAuthorized
      reservationName
      reservationPlaybackURL
      reservationTags
      reservationType
      oldReservationArn
      liveToVodPlaybackURL
      errorStatus
      errorMessageForVideoUpload
      createdAt
      updatedAt
      __typename
    }
    count
    createdAt
    updatedAt
    __typename
  }
}
` ; /* GeneratedMutation<
  APITypes.DeleteWatchProgressMutationVariables,
  APITypes.DeleteWatchProgressMutation
 ; */ 
exports.createFrontendTelevisionProviderInformation = /* GraphQL */ `mutation CreateFrontendTelevisionProviderInformation(
  $input: CreateFrontendTelevisionProviderInformationInput!
  $condition: ModelFrontendTelevisionProviderInformationConditionInput
) {
  createFrontendTelevisionProviderInformation(
    input: $input
    condition: $condition
  ) {
    id
    provider
    description
    conduits
    providerType
    providerURL
    imageURL
    alt
    street
    city
    zipcode
    popular
    imageFilename
    blurHash
    createdBy
    updatedBy
    createdAt
    updatedAt
    __typename
  }
}
` ; /* GeneratedMutation<
  APITypes.CreateFrontendTelevisionProviderInformationMutationVariables,
  APITypes.CreateFrontendTelevisionProviderInformationMutation
 ; */ 
exports.updateFrontendTelevisionProviderInformation = /* GraphQL */ `mutation UpdateFrontendTelevisionProviderInformation(
  $input: UpdateFrontendTelevisionProviderInformationInput!
  $condition: ModelFrontendTelevisionProviderInformationConditionInput
) {
  updateFrontendTelevisionProviderInformation(
    input: $input
    condition: $condition
  ) {
    id
    provider
    description
    conduits
    providerType
    providerURL
    imageURL
    alt
    street
    city
    zipcode
    popular
    imageFilename
    blurHash
    createdBy
    updatedBy
    createdAt
    updatedAt
    __typename
  }
}
` ; /* GeneratedMutation<
  APITypes.UpdateFrontendTelevisionProviderInformationMutationVariables,
  APITypes.UpdateFrontendTelevisionProviderInformationMutation
 ; */ 
exports.deleteFrontendTelevisionProviderInformation = /* GraphQL */ `mutation DeleteFrontendTelevisionProviderInformation(
  $input: DeleteFrontendTelevisionProviderInformationInput!
  $condition: ModelFrontendTelevisionProviderInformationConditionInput
) {
  deleteFrontendTelevisionProviderInformation(
    input: $input
    condition: $condition
  ) {
    id
    provider
    description
    conduits
    providerType
    providerURL
    imageURL
    alt
    street
    city
    zipcode
    popular
    imageFilename
    blurHash
    createdBy
    updatedBy
    createdAt
    updatedAt
    __typename
  }
}
` ; /* GeneratedMutation<
  APITypes.DeleteFrontendTelevisionProviderInformationMutationVariables,
  APITypes.DeleteFrontendTelevisionProviderInformationMutation
 ; */ 
exports.createFrontendLeague = /* GraphQL */ `mutation CreateFrontendLeague(
  $input: CreateFrontendLeagueInput!
  $condition: ModelFrontendLeagueConditionInput
) {
  createFrontendLeague(input: $input, condition: $condition) {
    id
    imageURL
    sport
    sportTypeTags
    region
    description
    title
    imageFilename
    blurHash
    createdBy
    updatedBy
    primaryColor
    secondaryColor
    textColorForPrimaryColor
    textColorForSecondaryColor
    backgroundColor
    textColorForBackgroundColor
    abbreviation
    fullName
    buyTicketsUrl
    publishBuyTicketsAtDate
    expirationBuyTicketsAtDate
    logoImageId
    logoImage {
      id
      name
      altText
      imageFilename
      blurHash
      description
      tags
      createdBy
      updatedBy
      searchSink
      createdAt
      updatedAt
      __typename
    }
    bannerImageId
    bannerImage {
      id
      name
      altText
      imageFilename
      blurHash
      description
      tags
      createdBy
      updatedBy
      searchSink
      createdAt
      updatedAt
      __typename
    }
    extraImage1Id
    extraImage1 {
      id
      name
      altText
      imageFilename
      blurHash
      description
      tags
      createdBy
      updatedBy
      searchSink
      createdAt
      updatedAt
      __typename
    }
    extraImage2Id
    extraImage2 {
      id
      name
      altText
      imageFilename
      blurHash
      description
      tags
      createdBy
      updatedBy
      searchSink
      createdAt
      updatedAt
      __typename
    }
    extraImage3Id
    extraImage3 {
      id
      name
      altText
      imageFilename
      blurHash
      description
      tags
      createdBy
      updatedBy
      searchSink
      createdAt
      updatedAt
      __typename
    }
    extraImage4Id
    extraImage4 {
      id
      name
      altText
      imageFilename
      blurHash
      description
      tags
      createdBy
      updatedBy
      searchSink
      createdAt
      updatedAt
      __typename
    }
    extraImage5Id
    extraImage5 {
      id
      name
      altText
      imageFilename
      blurHash
      description
      tags
      createdBy
      updatedBy
      searchSink
      createdAt
      updatedAt
      __typename
    }
    yearFounded
    yearInauguralSeason
    numberOfTeams
    leagueInfoUrl
    leaguePromoVideoContentId
    currentSeason
    createdAt
    updatedAt
    streamingSportsAndLeaguesPageLeaguesId
    __typename
  }
}
` ; /* GeneratedMutation<
  APITypes.CreateFrontendLeagueMutationVariables,
  APITypes.CreateFrontendLeagueMutation
 ; */ 
exports.updateFrontendLeague = /* GraphQL */ `mutation UpdateFrontendLeague(
  $input: UpdateFrontendLeagueInput!
  $condition: ModelFrontendLeagueConditionInput
) {
  updateFrontendLeague(input: $input, condition: $condition) {
    id
    imageURL
    sport
    sportTypeTags
    region
    description
    title
    imageFilename
    blurHash
    createdBy
    updatedBy
    primaryColor
    secondaryColor
    textColorForPrimaryColor
    textColorForSecondaryColor
    backgroundColor
    textColorForBackgroundColor
    abbreviation
    fullName
    buyTicketsUrl
    publishBuyTicketsAtDate
    expirationBuyTicketsAtDate
    logoImageId
    logoImage {
      id
      name
      altText
      imageFilename
      blurHash
      description
      tags
      createdBy
      updatedBy
      searchSink
      createdAt
      updatedAt
      __typename
    }
    bannerImageId
    bannerImage {
      id
      name
      altText
      imageFilename
      blurHash
      description
      tags
      createdBy
      updatedBy
      searchSink
      createdAt
      updatedAt
      __typename
    }
    extraImage1Id
    extraImage1 {
      id
      name
      altText
      imageFilename
      blurHash
      description
      tags
      createdBy
      updatedBy
      searchSink
      createdAt
      updatedAt
      __typename
    }
    extraImage2Id
    extraImage2 {
      id
      name
      altText
      imageFilename
      blurHash
      description
      tags
      createdBy
      updatedBy
      searchSink
      createdAt
      updatedAt
      __typename
    }
    extraImage3Id
    extraImage3 {
      id
      name
      altText
      imageFilename
      blurHash
      description
      tags
      createdBy
      updatedBy
      searchSink
      createdAt
      updatedAt
      __typename
    }
    extraImage4Id
    extraImage4 {
      id
      name
      altText
      imageFilename
      blurHash
      description
      tags
      createdBy
      updatedBy
      searchSink
      createdAt
      updatedAt
      __typename
    }
    extraImage5Id
    extraImage5 {
      id
      name
      altText
      imageFilename
      blurHash
      description
      tags
      createdBy
      updatedBy
      searchSink
      createdAt
      updatedAt
      __typename
    }
    yearFounded
    yearInauguralSeason
    numberOfTeams
    leagueInfoUrl
    leaguePromoVideoContentId
    currentSeason
    createdAt
    updatedAt
    streamingSportsAndLeaguesPageLeaguesId
    __typename
  }
}
` ; /* GeneratedMutation<
  APITypes.UpdateFrontendLeagueMutationVariables,
  APITypes.UpdateFrontendLeagueMutation
 ; */ 
exports.deleteFrontendLeague = /* GraphQL */ `mutation DeleteFrontendLeague(
  $input: DeleteFrontendLeagueInput!
  $condition: ModelFrontendLeagueConditionInput
) {
  deleteFrontendLeague(input: $input, condition: $condition) {
    id
    imageURL
    sport
    sportTypeTags
    region
    description
    title
    imageFilename
    blurHash
    createdBy
    updatedBy
    primaryColor
    secondaryColor
    textColorForPrimaryColor
    textColorForSecondaryColor
    backgroundColor
    textColorForBackgroundColor
    abbreviation
    fullName
    buyTicketsUrl
    publishBuyTicketsAtDate
    expirationBuyTicketsAtDate
    logoImageId
    logoImage {
      id
      name
      altText
      imageFilename
      blurHash
      description
      tags
      createdBy
      updatedBy
      searchSink
      createdAt
      updatedAt
      __typename
    }
    bannerImageId
    bannerImage {
      id
      name
      altText
      imageFilename
      blurHash
      description
      tags
      createdBy
      updatedBy
      searchSink
      createdAt
      updatedAt
      __typename
    }
    extraImage1Id
    extraImage1 {
      id
      name
      altText
      imageFilename
      blurHash
      description
      tags
      createdBy
      updatedBy
      searchSink
      createdAt
      updatedAt
      __typename
    }
    extraImage2Id
    extraImage2 {
      id
      name
      altText
      imageFilename
      blurHash
      description
      tags
      createdBy
      updatedBy
      searchSink
      createdAt
      updatedAt
      __typename
    }
    extraImage3Id
    extraImage3 {
      id
      name
      altText
      imageFilename
      blurHash
      description
      tags
      createdBy
      updatedBy
      searchSink
      createdAt
      updatedAt
      __typename
    }
    extraImage4Id
    extraImage4 {
      id
      name
      altText
      imageFilename
      blurHash
      description
      tags
      createdBy
      updatedBy
      searchSink
      createdAt
      updatedAt
      __typename
    }
    extraImage5Id
    extraImage5 {
      id
      name
      altText
      imageFilename
      blurHash
      description
      tags
      createdBy
      updatedBy
      searchSink
      createdAt
      updatedAt
      __typename
    }
    yearFounded
    yearInauguralSeason
    numberOfTeams
    leagueInfoUrl
    leaguePromoVideoContentId
    currentSeason
    createdAt
    updatedAt
    streamingSportsAndLeaguesPageLeaguesId
    __typename
  }
}
` ; /* GeneratedMutation<
  APITypes.DeleteFrontendLeagueMutationVariables,
  APITypes.DeleteFrontendLeagueMutation
 ; */ 
exports.createFrontendAdvertiser = /* GraphQL */ `mutation CreateFrontendAdvertiser(
  $input: CreateFrontendAdvertiserInput!
  $condition: ModelFrontendAdvertiserConditionInput
) {
  createFrontendAdvertiser(input: $input, condition: $condition) {
    id
    image
    companyName
    url
    isPrimarySponsor
    imageFilename
    blurHash
    createdBy
    updatedBy
    createdAt
    updatedAt
    __typename
  }
}
` ; /* GeneratedMutation<
  APITypes.CreateFrontendAdvertiserMutationVariables,
  APITypes.CreateFrontendAdvertiserMutation
 ; */ 
exports.updateFrontendAdvertiser = /* GraphQL */ `mutation UpdateFrontendAdvertiser(
  $input: UpdateFrontendAdvertiserInput!
  $condition: ModelFrontendAdvertiserConditionInput
) {
  updateFrontendAdvertiser(input: $input, condition: $condition) {
    id
    image
    companyName
    url
    isPrimarySponsor
    imageFilename
    blurHash
    createdBy
    updatedBy
    createdAt
    updatedAt
    __typename
  }
}
` ; /* GeneratedMutation<
  APITypes.UpdateFrontendAdvertiserMutationVariables,
  APITypes.UpdateFrontendAdvertiserMutation
 ; */ 
exports.deleteFrontendAdvertiser = /* GraphQL */ `mutation DeleteFrontendAdvertiser(
  $input: DeleteFrontendAdvertiserInput!
  $condition: ModelFrontendAdvertiserConditionInput
) {
  deleteFrontendAdvertiser(input: $input, condition: $condition) {
    id
    image
    companyName
    url
    isPrimarySponsor
    imageFilename
    blurHash
    createdBy
    updatedBy
    createdAt
    updatedAt
    __typename
  }
}
` ; /* GeneratedMutation<
  APITypes.DeleteFrontendAdvertiserMutationVariables,
  APITypes.DeleteFrontendAdvertiserMutation
 ; */ 
exports.createFrontendTermsOfUse = /* GraphQL */ `mutation CreateFrontendTermsOfUse(
  $input: CreateFrontendTermsOfUseInput!
  $condition: ModelFrontendTermsOfUseConditionInput
) {
  createFrontendTermsOfUse(input: $input, condition: $condition) {
    id
    lastUpdated
    termsOfUse
    applicationType
    createdBy
    updatedBy
    createdAt
    updatedAt
    __typename
  }
}
` ; /* GeneratedMutation<
  APITypes.CreateFrontendTermsOfUseMutationVariables,
  APITypes.CreateFrontendTermsOfUseMutation
 ; */ 
exports.updateFrontendTermsOfUse = /* GraphQL */ `mutation UpdateFrontendTermsOfUse(
  $input: UpdateFrontendTermsOfUseInput!
  $condition: ModelFrontendTermsOfUseConditionInput
) {
  updateFrontendTermsOfUse(input: $input, condition: $condition) {
    id
    lastUpdated
    termsOfUse
    applicationType
    createdBy
    updatedBy
    createdAt
    updatedAt
    __typename
  }
}
` ; /* GeneratedMutation<
  APITypes.UpdateFrontendTermsOfUseMutationVariables,
  APITypes.UpdateFrontendTermsOfUseMutation
 ; */ 
exports.deleteFrontendTermsOfUse = /* GraphQL */ `mutation DeleteFrontendTermsOfUse(
  $input: DeleteFrontendTermsOfUseInput!
  $condition: ModelFrontendTermsOfUseConditionInput
) {
  deleteFrontendTermsOfUse(input: $input, condition: $condition) {
    id
    lastUpdated
    termsOfUse
    applicationType
    createdBy
    updatedBy
    createdAt
    updatedAt
    __typename
  }
}
` ; /* GeneratedMutation<
  APITypes.DeleteFrontendTermsOfUseMutationVariables,
  APITypes.DeleteFrontendTermsOfUseMutation
 ; */ 
exports.createFrontendPrivacyPolicy = /* GraphQL */ `mutation CreateFrontendPrivacyPolicy(
  $input: CreateFrontendPrivacyPolicyInput!
  $condition: ModelFrontendPrivacyPolicyConditionInput
) {
  createFrontendPrivacyPolicy(input: $input, condition: $condition) {
    id
    lastUpdated
    privacyPolicy
    applicationType
    createdBy
    updatedBy
    createdAt
    updatedAt
    __typename
  }
}
` ; /* GeneratedMutation<
  APITypes.CreateFrontendPrivacyPolicyMutationVariables,
  APITypes.CreateFrontendPrivacyPolicyMutation
 ; */ 
exports.updateFrontendPrivacyPolicy = /* GraphQL */ `mutation UpdateFrontendPrivacyPolicy(
  $input: UpdateFrontendPrivacyPolicyInput!
  $condition: ModelFrontendPrivacyPolicyConditionInput
) {
  updateFrontendPrivacyPolicy(input: $input, condition: $condition) {
    id
    lastUpdated
    privacyPolicy
    applicationType
    createdBy
    updatedBy
    createdAt
    updatedAt
    __typename
  }
}
` ; /* GeneratedMutation<
  APITypes.UpdateFrontendPrivacyPolicyMutationVariables,
  APITypes.UpdateFrontendPrivacyPolicyMutation
 ; */ 
exports.deleteFrontendPrivacyPolicy = /* GraphQL */ `mutation DeleteFrontendPrivacyPolicy(
  $input: DeleteFrontendPrivacyPolicyInput!
  $condition: ModelFrontendPrivacyPolicyConditionInput
) {
  deleteFrontendPrivacyPolicy(input: $input, condition: $condition) {
    id
    lastUpdated
    privacyPolicy
    applicationType
    createdBy
    updatedBy
    createdAt
    updatedAt
    __typename
  }
}
` ; /* GeneratedMutation<
  APITypes.DeleteFrontendPrivacyPolicyMutationVariables,
  APITypes.DeleteFrontendPrivacyPolicyMutation
 ; */ 
exports.createPrimaryDigitalInnovation = /* GraphQL */ `mutation CreatePrimaryDigitalInnovation(
  $input: CreatePrimaryDigitalInnovationInput!
  $condition: ModelPrimaryDigitalInnovationConditionInput
) {
  createPrimaryDigitalInnovation(input: $input, condition: $condition) {
    id
    title
    description
    imageURL
    order
    extraURL
    createdBy
    updatedBy
    createdAt
    updatedAt
    __typename
  }
}
` ; /* GeneratedMutation<
  APITypes.CreatePrimaryDigitalInnovationMutationVariables,
  APITypes.CreatePrimaryDigitalInnovationMutation
 ; */ 
exports.updatePrimaryDigitalInnovation = /* GraphQL */ `mutation UpdatePrimaryDigitalInnovation(
  $input: UpdatePrimaryDigitalInnovationInput!
  $condition: ModelPrimaryDigitalInnovationConditionInput
) {
  updatePrimaryDigitalInnovation(input: $input, condition: $condition) {
    id
    title
    description
    imageURL
    order
    extraURL
    createdBy
    updatedBy
    createdAt
    updatedAt
    __typename
  }
}
` ; /* GeneratedMutation<
  APITypes.UpdatePrimaryDigitalInnovationMutationVariables,
  APITypes.UpdatePrimaryDigitalInnovationMutation
 ; */ 
exports.deletePrimaryDigitalInnovation = /* GraphQL */ `mutation DeletePrimaryDigitalInnovation(
  $input: DeletePrimaryDigitalInnovationInput!
  $condition: ModelPrimaryDigitalInnovationConditionInput
) {
  deletePrimaryDigitalInnovation(input: $input, condition: $condition) {
    id
    title
    description
    imageURL
    order
    extraURL
    createdBy
    updatedBy
    createdAt
    updatedAt
    __typename
  }
}
` ; /* GeneratedMutation<
  APITypes.DeletePrimaryDigitalInnovationMutationVariables,
  APITypes.DeletePrimaryDigitalInnovationMutation
 ; */ 
exports.createPrimaryNewsTopic = /* GraphQL */ `mutation CreatePrimaryNewsTopic(
  $input: CreatePrimaryNewsTopicInput!
  $condition: ModelPrimaryNewsTopicConditionInput
) {
  createPrimaryNewsTopic(input: $input, condition: $condition) {
    id
    title
    searchFilterKeyWords
    order
    createdBy
    updatedBy
    createdAt
    updatedAt
    __typename
  }
}
` ; /* GeneratedMutation<
  APITypes.CreatePrimaryNewsTopicMutationVariables,
  APITypes.CreatePrimaryNewsTopicMutation
 ; */ 
exports.updatePrimaryNewsTopic = /* GraphQL */ `mutation UpdatePrimaryNewsTopic(
  $input: UpdatePrimaryNewsTopicInput!
  $condition: ModelPrimaryNewsTopicConditionInput
) {
  updatePrimaryNewsTopic(input: $input, condition: $condition) {
    id
    title
    searchFilterKeyWords
    order
    createdBy
    updatedBy
    createdAt
    updatedAt
    __typename
  }
}
` ; /* GeneratedMutation<
  APITypes.UpdatePrimaryNewsTopicMutationVariables,
  APITypes.UpdatePrimaryNewsTopicMutation
 ; */ 
exports.deletePrimaryNewsTopic = /* GraphQL */ `mutation DeletePrimaryNewsTopic(
  $input: DeletePrimaryNewsTopicInput!
  $condition: ModelPrimaryNewsTopicConditionInput
) {
  deletePrimaryNewsTopic(input: $input, condition: $condition) {
    id
    title
    searchFilterKeyWords
    order
    createdBy
    updatedBy
    createdAt
    updatedAt
    __typename
  }
}
` ; /* GeneratedMutation<
  APITypes.DeletePrimaryNewsTopicMutationVariables,
  APITypes.DeletePrimaryNewsTopicMutation
 ; */ 
exports.createPrimarySlider = /* GraphQL */ `mutation CreatePrimarySlider(
  $input: CreatePrimarySliderInput!
  $condition: ModelPrimarySliderConditionInput
) {
  createPrimarySlider(input: $input, condition: $condition) {
    id
    title
    descriptionTop
    descriptionBottom
    imageURL
    order
    providerId
    provider {
      id
      provider
      description
      conduits
      providerType
      providerURL
      imageURL
      alt
      street
      city
      zipcode
      popular
      imageFilename
      blurHash
      createdBy
      updatedBy
      createdAt
      updatedAt
      __typename
    }
    category
    contentId
    content {
      id
      title
      description
      note
      video
      gameId
      watchList {
        nextToken
        __typename
      }
      game {
        id
        name
        description
        homeTeamId
        awayTeamId
        gameStartDateTime
        gameEndDateTime
        status
        tags
        seasonYear
        seasonType
        week
        fullWeek
        GB_LastScanStart
        GB_LastScanEnd
        quicksandStartIntervalSeconds
        quicksandEndIntervalSeconds
        sport
        leagueId
        nlseAssetHouseCode
        stats
        searchSink
        imageFilename
        blurHash
        createdBy
        updatedBy
        createdAt
        updatedAt
        programGamesId
        __typename
      }
      vodEntryId
      vodEntry {
        id
        body
        contentIdString
        dashUrl
        dashPlaylist
        hlsUrl
        hlsPlaylist
        stage
        publishedAt
        createdBy
        updatedBy
        errorStatus
        errorDescripton
        createdAt
        updatedAt
        __typename
      }
      programId
      program {
        id
        name
        description
        tvRating
        sport
        leagueId
        tags
        createdBy
        updatedBy
        imageFilename
        blurHash
        seasons
        publishAtDate
        expirationAtDate
        onlyShowEpisodesInAProgramAsOneItem
        availabilityStatus
        createdAt
        updatedAt
        __typename
      }
      tvRating
      contentType
      applicationType
      nlseAssetHouseCode
      episode
      episodeId
      episodeOrder
      episodeSeason
      isSupplementalContent
      supplementalContentOrder
      mainContentId
      publishAtDate
      expirationAtDate
      availabilityStatus
      contentLength
      seasonYear
      seasonType
      location
      leagueId
      league {
        id
        imageURL
        sport
        sportTypeTags
        region
        description
        title
        imageFilename
        blurHash
        createdBy
        updatedBy
        primaryColor
        secondaryColor
        textColorForPrimaryColor
        textColorForSecondaryColor
        backgroundColor
        textColorForBackgroundColor
        abbreviation
        fullName
        buyTicketsUrl
        publishBuyTicketsAtDate
        expirationBuyTicketsAtDate
        logoImageId
        bannerImageId
        extraImage1Id
        extraImage2Id
        extraImage3Id
        extraImage4Id
        extraImage5Id
        yearFounded
        yearInauguralSeason
        numberOfTeams
        leagueInfoUrl
        leaguePromoVideoContentId
        currentSeason
        createdAt
        updatedAt
        streamingSportsAndLeaguesPageLeaguesId
        __typename
      }
      sport
      tags
      stats
      searchSink
      imageFilename
      blurHash
      createdBy
      updatedBy
      reservationArn
      reservationAuthorized
      reservationName
      reservationPlaybackURL
      reservationTags
      reservationType
      oldReservationArn
      liveToVodPlaybackURL
      errorStatus
      errorMessageForVideoUpload
      createdAt
      updatedAt
      __typename
    }
    programId
    program {
      id
      name
      description
      tvRating
      sport
      leagueId
      league {
        id
        imageURL
        sport
        sportTypeTags
        region
        description
        title
        imageFilename
        blurHash
        createdBy
        updatedBy
        primaryColor
        secondaryColor
        textColorForPrimaryColor
        textColorForSecondaryColor
        backgroundColor
        textColorForBackgroundColor
        abbreviation
        fullName
        buyTicketsUrl
        publishBuyTicketsAtDate
        expirationBuyTicketsAtDate
        logoImageId
        bannerImageId
        extraImage1Id
        extraImage2Id
        extraImage3Id
        extraImage4Id
        extraImage5Id
        yearFounded
        yearInauguralSeason
        numberOfTeams
        leagueInfoUrl
        leaguePromoVideoContentId
        currentSeason
        createdAt
        updatedAt
        streamingSportsAndLeaguesPageLeaguesId
        __typename
      }
      tags
      games {
        nextToken
        __typename
      }
      streamingLiveScheduleEvents {
        nextToken
        __typename
      }
      createdBy
      updatedBy
      imageFilename
      blurHash
      seasons
      publishAtDate
      expirationAtDate
      onlyShowEpisodesInAProgramAsOneItem
      availabilityStatus
      createdAt
      updatedAt
      __typename
    }
    extraURL
    videoURL
    imageFilename
    blurHash
    createdBy
    updatedBy
    expirationDate
    publishedAtDate
    createdAt
    updatedAt
    __typename
  }
}
` ; /* GeneratedMutation<
  APITypes.CreatePrimarySliderMutationVariables,
  APITypes.CreatePrimarySliderMutation
 ; */ 
exports.updatePrimarySlider = /* GraphQL */ `mutation UpdatePrimarySlider(
  $input: UpdatePrimarySliderInput!
  $condition: ModelPrimarySliderConditionInput
) {
  updatePrimarySlider(input: $input, condition: $condition) {
    id
    title
    descriptionTop
    descriptionBottom
    imageURL
    order
    providerId
    provider {
      id
      provider
      description
      conduits
      providerType
      providerURL
      imageURL
      alt
      street
      city
      zipcode
      popular
      imageFilename
      blurHash
      createdBy
      updatedBy
      createdAt
      updatedAt
      __typename
    }
    category
    contentId
    content {
      id
      title
      description
      note
      video
      gameId
      watchList {
        nextToken
        __typename
      }
      game {
        id
        name
        description
        homeTeamId
        awayTeamId
        gameStartDateTime
        gameEndDateTime
        status
        tags
        seasonYear
        seasonType
        week
        fullWeek
        GB_LastScanStart
        GB_LastScanEnd
        quicksandStartIntervalSeconds
        quicksandEndIntervalSeconds
        sport
        leagueId
        nlseAssetHouseCode
        stats
        searchSink
        imageFilename
        blurHash
        createdBy
        updatedBy
        createdAt
        updatedAt
        programGamesId
        __typename
      }
      vodEntryId
      vodEntry {
        id
        body
        contentIdString
        dashUrl
        dashPlaylist
        hlsUrl
        hlsPlaylist
        stage
        publishedAt
        createdBy
        updatedBy
        errorStatus
        errorDescripton
        createdAt
        updatedAt
        __typename
      }
      programId
      program {
        id
        name
        description
        tvRating
        sport
        leagueId
        tags
        createdBy
        updatedBy
        imageFilename
        blurHash
        seasons
        publishAtDate
        expirationAtDate
        onlyShowEpisodesInAProgramAsOneItem
        availabilityStatus
        createdAt
        updatedAt
        __typename
      }
      tvRating
      contentType
      applicationType
      nlseAssetHouseCode
      episode
      episodeId
      episodeOrder
      episodeSeason
      isSupplementalContent
      supplementalContentOrder
      mainContentId
      publishAtDate
      expirationAtDate
      availabilityStatus
      contentLength
      seasonYear
      seasonType
      location
      leagueId
      league {
        id
        imageURL
        sport
        sportTypeTags
        region
        description
        title
        imageFilename
        blurHash
        createdBy
        updatedBy
        primaryColor
        secondaryColor
        textColorForPrimaryColor
        textColorForSecondaryColor
        backgroundColor
        textColorForBackgroundColor
        abbreviation
        fullName
        buyTicketsUrl
        publishBuyTicketsAtDate
        expirationBuyTicketsAtDate
        logoImageId
        bannerImageId
        extraImage1Id
        extraImage2Id
        extraImage3Id
        extraImage4Id
        extraImage5Id
        yearFounded
        yearInauguralSeason
        numberOfTeams
        leagueInfoUrl
        leaguePromoVideoContentId
        currentSeason
        createdAt
        updatedAt
        streamingSportsAndLeaguesPageLeaguesId
        __typename
      }
      sport
      tags
      stats
      searchSink
      imageFilename
      blurHash
      createdBy
      updatedBy
      reservationArn
      reservationAuthorized
      reservationName
      reservationPlaybackURL
      reservationTags
      reservationType
      oldReservationArn
      liveToVodPlaybackURL
      errorStatus
      errorMessageForVideoUpload
      createdAt
      updatedAt
      __typename
    }
    programId
    program {
      id
      name
      description
      tvRating
      sport
      leagueId
      league {
        id
        imageURL
        sport
        sportTypeTags
        region
        description
        title
        imageFilename
        blurHash
        createdBy
        updatedBy
        primaryColor
        secondaryColor
        textColorForPrimaryColor
        textColorForSecondaryColor
        backgroundColor
        textColorForBackgroundColor
        abbreviation
        fullName
        buyTicketsUrl
        publishBuyTicketsAtDate
        expirationBuyTicketsAtDate
        logoImageId
        bannerImageId
        extraImage1Id
        extraImage2Id
        extraImage3Id
        extraImage4Id
        extraImage5Id
        yearFounded
        yearInauguralSeason
        numberOfTeams
        leagueInfoUrl
        leaguePromoVideoContentId
        currentSeason
        createdAt
        updatedAt
        streamingSportsAndLeaguesPageLeaguesId
        __typename
      }
      tags
      games {
        nextToken
        __typename
      }
      streamingLiveScheduleEvents {
        nextToken
        __typename
      }
      createdBy
      updatedBy
      imageFilename
      blurHash
      seasons
      publishAtDate
      expirationAtDate
      onlyShowEpisodesInAProgramAsOneItem
      availabilityStatus
      createdAt
      updatedAt
      __typename
    }
    extraURL
    videoURL
    imageFilename
    blurHash
    createdBy
    updatedBy
    expirationDate
    publishedAtDate
    createdAt
    updatedAt
    __typename
  }
}
` ; /* GeneratedMutation<
  APITypes.UpdatePrimarySliderMutationVariables,
  APITypes.UpdatePrimarySliderMutation
 ; */ 
exports.deletePrimarySlider = /* GraphQL */ `mutation DeletePrimarySlider(
  $input: DeletePrimarySliderInput!
  $condition: ModelPrimarySliderConditionInput
) {
  deletePrimarySlider(input: $input, condition: $condition) {
    id
    title
    descriptionTop
    descriptionBottom
    imageURL
    order
    providerId
    provider {
      id
      provider
      description
      conduits
      providerType
      providerURL
      imageURL
      alt
      street
      city
      zipcode
      popular
      imageFilename
      blurHash
      createdBy
      updatedBy
      createdAt
      updatedAt
      __typename
    }
    category
    contentId
    content {
      id
      title
      description
      note
      video
      gameId
      watchList {
        nextToken
        __typename
      }
      game {
        id
        name
        description
        homeTeamId
        awayTeamId
        gameStartDateTime
        gameEndDateTime
        status
        tags
        seasonYear
        seasonType
        week
        fullWeek
        GB_LastScanStart
        GB_LastScanEnd
        quicksandStartIntervalSeconds
        quicksandEndIntervalSeconds
        sport
        leagueId
        nlseAssetHouseCode
        stats
        searchSink
        imageFilename
        blurHash
        createdBy
        updatedBy
        createdAt
        updatedAt
        programGamesId
        __typename
      }
      vodEntryId
      vodEntry {
        id
        body
        contentIdString
        dashUrl
        dashPlaylist
        hlsUrl
        hlsPlaylist
        stage
        publishedAt
        createdBy
        updatedBy
        errorStatus
        errorDescripton
        createdAt
        updatedAt
        __typename
      }
      programId
      program {
        id
        name
        description
        tvRating
        sport
        leagueId
        tags
        createdBy
        updatedBy
        imageFilename
        blurHash
        seasons
        publishAtDate
        expirationAtDate
        onlyShowEpisodesInAProgramAsOneItem
        availabilityStatus
        createdAt
        updatedAt
        __typename
      }
      tvRating
      contentType
      applicationType
      nlseAssetHouseCode
      episode
      episodeId
      episodeOrder
      episodeSeason
      isSupplementalContent
      supplementalContentOrder
      mainContentId
      publishAtDate
      expirationAtDate
      availabilityStatus
      contentLength
      seasonYear
      seasonType
      location
      leagueId
      league {
        id
        imageURL
        sport
        sportTypeTags
        region
        description
        title
        imageFilename
        blurHash
        createdBy
        updatedBy
        primaryColor
        secondaryColor
        textColorForPrimaryColor
        textColorForSecondaryColor
        backgroundColor
        textColorForBackgroundColor
        abbreviation
        fullName
        buyTicketsUrl
        publishBuyTicketsAtDate
        expirationBuyTicketsAtDate
        logoImageId
        bannerImageId
        extraImage1Id
        extraImage2Id
        extraImage3Id
        extraImage4Id
        extraImage5Id
        yearFounded
        yearInauguralSeason
        numberOfTeams
        leagueInfoUrl
        leaguePromoVideoContentId
        currentSeason
        createdAt
        updatedAt
        streamingSportsAndLeaguesPageLeaguesId
        __typename
      }
      sport
      tags
      stats
      searchSink
      imageFilename
      blurHash
      createdBy
      updatedBy
      reservationArn
      reservationAuthorized
      reservationName
      reservationPlaybackURL
      reservationTags
      reservationType
      oldReservationArn
      liveToVodPlaybackURL
      errorStatus
      errorMessageForVideoUpload
      createdAt
      updatedAt
      __typename
    }
    programId
    program {
      id
      name
      description
      tvRating
      sport
      leagueId
      league {
        id
        imageURL
        sport
        sportTypeTags
        region
        description
        title
        imageFilename
        blurHash
        createdBy
        updatedBy
        primaryColor
        secondaryColor
        textColorForPrimaryColor
        textColorForSecondaryColor
        backgroundColor
        textColorForBackgroundColor
        abbreviation
        fullName
        buyTicketsUrl
        publishBuyTicketsAtDate
        expirationBuyTicketsAtDate
        logoImageId
        bannerImageId
        extraImage1Id
        extraImage2Id
        extraImage3Id
        extraImage4Id
        extraImage5Id
        yearFounded
        yearInauguralSeason
        numberOfTeams
        leagueInfoUrl
        leaguePromoVideoContentId
        currentSeason
        createdAt
        updatedAt
        streamingSportsAndLeaguesPageLeaguesId
        __typename
      }
      tags
      games {
        nextToken
        __typename
      }
      streamingLiveScheduleEvents {
        nextToken
        __typename
      }
      createdBy
      updatedBy
      imageFilename
      blurHash
      seasons
      publishAtDate
      expirationAtDate
      onlyShowEpisodesInAProgramAsOneItem
      availabilityStatus
      createdAt
      updatedAt
      __typename
    }
    extraURL
    videoURL
    imageFilename
    blurHash
    createdBy
    updatedBy
    expirationDate
    publishedAtDate
    createdAt
    updatedAt
    __typename
  }
}
` ; /* GeneratedMutation<
  APITypes.DeletePrimarySliderMutationVariables,
  APITypes.DeletePrimarySliderMutation
 ; */ 
exports.createPrimaryAdvertiserSponsor = /* GraphQL */ `mutation CreatePrimaryAdvertiserSponsor(
  $input: CreatePrimaryAdvertiserSponsorInput!
  $condition: ModelPrimaryAdvertiserSponsorConditionInput
) {
  createPrimaryAdvertiserSponsor(input: $input, condition: $condition) {
    id
    name
    imageURL
    advertiserURL
    isPrimarySponsor
    imageFilename
    blurHash
    createdBy
    updatedBy
    createdAt
    updatedAt
    __typename
  }
}
` ; /* GeneratedMutation<
  APITypes.CreatePrimaryAdvertiserSponsorMutationVariables,
  APITypes.CreatePrimaryAdvertiserSponsorMutation
 ; */ 
exports.updatePrimaryAdvertiserSponsor = /* GraphQL */ `mutation UpdatePrimaryAdvertiserSponsor(
  $input: UpdatePrimaryAdvertiserSponsorInput!
  $condition: ModelPrimaryAdvertiserSponsorConditionInput
) {
  updatePrimaryAdvertiserSponsor(input: $input, condition: $condition) {
    id
    name
    imageURL
    advertiserURL
    isPrimarySponsor
    imageFilename
    blurHash
    createdBy
    updatedBy
    createdAt
    updatedAt
    __typename
  }
}
` ; /* GeneratedMutation<
  APITypes.UpdatePrimaryAdvertiserSponsorMutationVariables,
  APITypes.UpdatePrimaryAdvertiserSponsorMutation
 ; */ 
exports.deletePrimaryAdvertiserSponsor = /* GraphQL */ `mutation DeletePrimaryAdvertiserSponsor(
  $input: DeletePrimaryAdvertiserSponsorInput!
  $condition: ModelPrimaryAdvertiserSponsorConditionInput
) {
  deletePrimaryAdvertiserSponsor(input: $input, condition: $condition) {
    id
    name
    imageURL
    advertiserURL
    isPrimarySponsor
    imageFilename
    blurHash
    createdBy
    updatedBy
    createdAt
    updatedAt
    __typename
  }
}
` ; /* GeneratedMutation<
  APITypes.DeletePrimaryAdvertiserSponsorMutationVariables,
  APITypes.DeletePrimaryAdvertiserSponsorMutation
 ; */ 
exports.createPrimaryContactUs = /* GraphQL */ `mutation CreatePrimaryContactUs(
  $input: CreatePrimaryContactUsInput!
  $condition: ModelPrimaryContactUsConditionInput
) {
  createPrimaryContactUs(input: $input, condition: $condition) {
    id
    firstName
    lastName
    userEmailAddress
    subject
    message
    helpServiceEmail
    divisionType
    createdBy
    updatedBy
    createdAt
    updatedAt
    __typename
  }
}
` ; /* GeneratedMutation<
  APITypes.CreatePrimaryContactUsMutationVariables,
  APITypes.CreatePrimaryContactUsMutation
 ; */ 
exports.updatePrimaryContactUs = /* GraphQL */ `mutation UpdatePrimaryContactUs(
  $input: UpdatePrimaryContactUsInput!
  $condition: ModelPrimaryContactUsConditionInput
) {
  updatePrimaryContactUs(input: $input, condition: $condition) {
    id
    firstName
    lastName
    userEmailAddress
    subject
    message
    helpServiceEmail
    divisionType
    createdBy
    updatedBy
    createdAt
    updatedAt
    __typename
  }
}
` ; /* GeneratedMutation<
  APITypes.UpdatePrimaryContactUsMutationVariables,
  APITypes.UpdatePrimaryContactUsMutation
 ; */ 
exports.deletePrimaryContactUs = /* GraphQL */ `mutation DeletePrimaryContactUs(
  $input: DeletePrimaryContactUsInput!
  $condition: ModelPrimaryContactUsConditionInput
) {
  deletePrimaryContactUs(input: $input, condition: $condition) {
    id
    firstName
    lastName
    userEmailAddress
    subject
    message
    helpServiceEmail
    divisionType
    createdBy
    updatedBy
    createdAt
    updatedAt
    __typename
  }
}
` ; /* GeneratedMutation<
  APITypes.DeletePrimaryContactUsMutationVariables,
  APITypes.DeletePrimaryContactUsMutation
 ; */ 
exports.createPrimaryUserMessaging = /* GraphQL */ `mutation CreatePrimaryUserMessaging(
  $input: CreatePrimaryUserMessagingInput!
  $condition: ModelPrimaryUserMessagingConditionInput
) {
  createPrimaryUserMessaging(input: $input, condition: $condition) {
    id
    message
    pageToDisplayMessage
    expirationDate
    publishedAtDate
    createdBy
    updatedBy
    createdAt
    updatedAt
    __typename
  }
}
` ; /* GeneratedMutation<
  APITypes.CreatePrimaryUserMessagingMutationVariables,
  APITypes.CreatePrimaryUserMessagingMutation
 ; */ 
exports.updatePrimaryUserMessaging = /* GraphQL */ `mutation UpdatePrimaryUserMessaging(
  $input: UpdatePrimaryUserMessagingInput!
  $condition: ModelPrimaryUserMessagingConditionInput
) {
  updatePrimaryUserMessaging(input: $input, condition: $condition) {
    id
    message
    pageToDisplayMessage
    expirationDate
    publishedAtDate
    createdBy
    updatedBy
    createdAt
    updatedAt
    __typename
  }
}
` ; /* GeneratedMutation<
  APITypes.UpdatePrimaryUserMessagingMutationVariables,
  APITypes.UpdatePrimaryUserMessagingMutation
 ; */ 
exports.deletePrimaryUserMessaging = /* GraphQL */ `mutation DeletePrimaryUserMessaging(
  $input: DeletePrimaryUserMessagingInput!
  $condition: ModelPrimaryUserMessagingConditionInput
) {
  deletePrimaryUserMessaging(input: $input, condition: $condition) {
    id
    message
    pageToDisplayMessage
    expirationDate
    publishedAtDate
    createdBy
    updatedBy
    createdAt
    updatedAt
    __typename
  }
}
` ; /* GeneratedMutation<
  APITypes.DeletePrimaryUserMessagingMutationVariables,
  APITypes.DeletePrimaryUserMessagingMutation
 ; */ 
exports.createStreamingUserMessaging = /* GraphQL */ `mutation CreateStreamingUserMessaging(
  $input: CreateStreamingUserMessagingInput!
  $condition: ModelStreamingUserMessagingConditionInput
) {
  createStreamingUserMessaging(input: $input, condition: $condition) {
    id
    message
    pageToDisplayMessage
    expirationDate
    publishedAtDate
    createdBy
    updatedBy
    createdAt
    updatedAt
    __typename
  }
}
` ; /* GeneratedMutation<
  APITypes.CreateStreamingUserMessagingMutationVariables,
  APITypes.CreateStreamingUserMessagingMutation
 ; */ 
exports.updateStreamingUserMessaging = /* GraphQL */ `mutation UpdateStreamingUserMessaging(
  $input: UpdateStreamingUserMessagingInput!
  $condition: ModelStreamingUserMessagingConditionInput
) {
  updateStreamingUserMessaging(input: $input, condition: $condition) {
    id
    message
    pageToDisplayMessage
    expirationDate
    publishedAtDate
    createdBy
    updatedBy
    createdAt
    updatedAt
    __typename
  }
}
` ; /* GeneratedMutation<
  APITypes.UpdateStreamingUserMessagingMutationVariables,
  APITypes.UpdateStreamingUserMessagingMutation
 ; */ 
exports.deleteStreamingUserMessaging = /* GraphQL */ `mutation DeleteStreamingUserMessaging(
  $input: DeleteStreamingUserMessagingInput!
  $condition: ModelStreamingUserMessagingConditionInput
) {
  deleteStreamingUserMessaging(input: $input, condition: $condition) {
    id
    message
    pageToDisplayMessage
    expirationDate
    publishedAtDate
    createdBy
    updatedBy
    createdAt
    updatedAt
    __typename
  }
}
` ; /* GeneratedMutation<
  APITypes.DeleteStreamingUserMessagingMutationVariables,
  APITypes.DeleteStreamingUserMessagingMutation
 ; */ 
exports.createPrimaryCareerJobPost = /* GraphQL */ `mutation CreatePrimaryCareerJobPost(
  $input: CreatePrimaryCareerJobPostInput!
  $condition: ModelPrimaryCareerJobPostConditionInput
) {
  createPrimaryCareerJobPost(input: $input, condition: $condition) {
    id
    jobTitle
    description
    jobPostingURL
    location
    category
    createdAt
    updatedAt
    __typename
  }
}
` ; /* GeneratedMutation<
  APITypes.CreatePrimaryCareerJobPostMutationVariables,
  APITypes.CreatePrimaryCareerJobPostMutation
 ; */ 
exports.updatePrimaryCareerJobPost = /* GraphQL */ `mutation UpdatePrimaryCareerJobPost(
  $input: UpdatePrimaryCareerJobPostInput!
  $condition: ModelPrimaryCareerJobPostConditionInput
) {
  updatePrimaryCareerJobPost(input: $input, condition: $condition) {
    id
    jobTitle
    description
    jobPostingURL
    location
    category
    createdAt
    updatedAt
    __typename
  }
}
` ; /* GeneratedMutation<
  APITypes.UpdatePrimaryCareerJobPostMutationVariables,
  APITypes.UpdatePrimaryCareerJobPostMutation
 ; */ 
exports.deletePrimaryCareerJobPost = /* GraphQL */ `mutation DeletePrimaryCareerJobPost(
  $input: DeletePrimaryCareerJobPostInput!
  $condition: ModelPrimaryCareerJobPostConditionInput
) {
  deletePrimaryCareerJobPost(input: $input, condition: $condition) {
    id
    jobTitle
    description
    jobPostingURL
    location
    category
    createdAt
    updatedAt
    __typename
  }
}
` ; /* GeneratedMutation<
  APITypes.DeletePrimaryCareerJobPostMutationVariables,
  APITypes.DeletePrimaryCareerJobPostMutation
 ; */ 
exports.createStreamingSlider = /* GraphQL */ `mutation CreateStreamingSlider(
  $input: CreateStreamingSliderInput!
  $condition: ModelStreamingSliderConditionInput
) {
  createStreamingSlider(input: $input, condition: $condition) {
    id
    title
    descriptionTop
    descriptionBottom
    imageURL
    order
    providerId
    provider {
      id
      provider
      description
      conduits
      providerType
      providerURL
      imageURL
      alt
      street
      city
      zipcode
      popular
      imageFilename
      blurHash
      createdBy
      updatedBy
      createdAt
      updatedAt
      __typename
    }
    category
    contentId
    content {
      id
      title
      description
      note
      video
      gameId
      watchList {
        nextToken
        __typename
      }
      game {
        id
        name
        description
        homeTeamId
        awayTeamId
        gameStartDateTime
        gameEndDateTime
        status
        tags
        seasonYear
        seasonType
        week
        fullWeek
        GB_LastScanStart
        GB_LastScanEnd
        quicksandStartIntervalSeconds
        quicksandEndIntervalSeconds
        sport
        leagueId
        nlseAssetHouseCode
        stats
        searchSink
        imageFilename
        blurHash
        createdBy
        updatedBy
        createdAt
        updatedAt
        programGamesId
        __typename
      }
      vodEntryId
      vodEntry {
        id
        body
        contentIdString
        dashUrl
        dashPlaylist
        hlsUrl
        hlsPlaylist
        stage
        publishedAt
        createdBy
        updatedBy
        errorStatus
        errorDescripton
        createdAt
        updatedAt
        __typename
      }
      programId
      program {
        id
        name
        description
        tvRating
        sport
        leagueId
        tags
        createdBy
        updatedBy
        imageFilename
        blurHash
        seasons
        publishAtDate
        expirationAtDate
        onlyShowEpisodesInAProgramAsOneItem
        availabilityStatus
        createdAt
        updatedAt
        __typename
      }
      tvRating
      contentType
      applicationType
      nlseAssetHouseCode
      episode
      episodeId
      episodeOrder
      episodeSeason
      isSupplementalContent
      supplementalContentOrder
      mainContentId
      publishAtDate
      expirationAtDate
      availabilityStatus
      contentLength
      seasonYear
      seasonType
      location
      leagueId
      league {
        id
        imageURL
        sport
        sportTypeTags
        region
        description
        title
        imageFilename
        blurHash
        createdBy
        updatedBy
        primaryColor
        secondaryColor
        textColorForPrimaryColor
        textColorForSecondaryColor
        backgroundColor
        textColorForBackgroundColor
        abbreviation
        fullName
        buyTicketsUrl
        publishBuyTicketsAtDate
        expirationBuyTicketsAtDate
        logoImageId
        bannerImageId
        extraImage1Id
        extraImage2Id
        extraImage3Id
        extraImage4Id
        extraImage5Id
        yearFounded
        yearInauguralSeason
        numberOfTeams
        leagueInfoUrl
        leaguePromoVideoContentId
        currentSeason
        createdAt
        updatedAt
        streamingSportsAndLeaguesPageLeaguesId
        __typename
      }
      sport
      tags
      stats
      searchSink
      imageFilename
      blurHash
      createdBy
      updatedBy
      reservationArn
      reservationAuthorized
      reservationName
      reservationPlaybackURL
      reservationTags
      reservationType
      oldReservationArn
      liveToVodPlaybackURL
      errorStatus
      errorMessageForVideoUpload
      createdAt
      updatedAt
      __typename
    }
    programId
    program {
      id
      name
      description
      tvRating
      sport
      leagueId
      league {
        id
        imageURL
        sport
        sportTypeTags
        region
        description
        title
        imageFilename
        blurHash
        createdBy
        updatedBy
        primaryColor
        secondaryColor
        textColorForPrimaryColor
        textColorForSecondaryColor
        backgroundColor
        textColorForBackgroundColor
        abbreviation
        fullName
        buyTicketsUrl
        publishBuyTicketsAtDate
        expirationBuyTicketsAtDate
        logoImageId
        bannerImageId
        extraImage1Id
        extraImage2Id
        extraImage3Id
        extraImage4Id
        extraImage5Id
        yearFounded
        yearInauguralSeason
        numberOfTeams
        leagueInfoUrl
        leaguePromoVideoContentId
        currentSeason
        createdAt
        updatedAt
        streamingSportsAndLeaguesPageLeaguesId
        __typename
      }
      tags
      games {
        nextToken
        __typename
      }
      streamingLiveScheduleEvents {
        nextToken
        __typename
      }
      createdBy
      updatedBy
      imageFilename
      blurHash
      seasons
      publishAtDate
      expirationAtDate
      onlyShowEpisodesInAProgramAsOneItem
      availabilityStatus
      createdAt
      updatedAt
      __typename
    }
    extraURL
    imageFilename
    blurHash
    createdBy
    updatedBy
    expirationDate
    publishedAtDate
    contentStatusType
    createdAt
    updatedAt
    __typename
  }
}
` ; /* GeneratedMutation<
  APITypes.CreateStreamingSliderMutationVariables,
  APITypes.CreateStreamingSliderMutation
 ; */ 
exports.updateStreamingSlider = /* GraphQL */ `mutation UpdateStreamingSlider(
  $input: UpdateStreamingSliderInput!
  $condition: ModelStreamingSliderConditionInput
) {
  updateStreamingSlider(input: $input, condition: $condition) {
    id
    title
    descriptionTop
    descriptionBottom
    imageURL
    order
    providerId
    provider {
      id
      provider
      description
      conduits
      providerType
      providerURL
      imageURL
      alt
      street
      city
      zipcode
      popular
      imageFilename
      blurHash
      createdBy
      updatedBy
      createdAt
      updatedAt
      __typename
    }
    category
    contentId
    content {
      id
      title
      description
      note
      video
      gameId
      watchList {
        nextToken
        __typename
      }
      game {
        id
        name
        description
        homeTeamId
        awayTeamId
        gameStartDateTime
        gameEndDateTime
        status
        tags
        seasonYear
        seasonType
        week
        fullWeek
        GB_LastScanStart
        GB_LastScanEnd
        quicksandStartIntervalSeconds
        quicksandEndIntervalSeconds
        sport
        leagueId
        nlseAssetHouseCode
        stats
        searchSink
        imageFilename
        blurHash
        createdBy
        updatedBy
        createdAt
        updatedAt
        programGamesId
        __typename
      }
      vodEntryId
      vodEntry {
        id
        body
        contentIdString
        dashUrl
        dashPlaylist
        hlsUrl
        hlsPlaylist
        stage
        publishedAt
        createdBy
        updatedBy
        errorStatus
        errorDescripton
        createdAt
        updatedAt
        __typename
      }
      programId
      program {
        id
        name
        description
        tvRating
        sport
        leagueId
        tags
        createdBy
        updatedBy
        imageFilename
        blurHash
        seasons
        publishAtDate
        expirationAtDate
        onlyShowEpisodesInAProgramAsOneItem
        availabilityStatus
        createdAt
        updatedAt
        __typename
      }
      tvRating
      contentType
      applicationType
      nlseAssetHouseCode
      episode
      episodeId
      episodeOrder
      episodeSeason
      isSupplementalContent
      supplementalContentOrder
      mainContentId
      publishAtDate
      expirationAtDate
      availabilityStatus
      contentLength
      seasonYear
      seasonType
      location
      leagueId
      league {
        id
        imageURL
        sport
        sportTypeTags
        region
        description
        title
        imageFilename
        blurHash
        createdBy
        updatedBy
        primaryColor
        secondaryColor
        textColorForPrimaryColor
        textColorForSecondaryColor
        backgroundColor
        textColorForBackgroundColor
        abbreviation
        fullName
        buyTicketsUrl
        publishBuyTicketsAtDate
        expirationBuyTicketsAtDate
        logoImageId
        bannerImageId
        extraImage1Id
        extraImage2Id
        extraImage3Id
        extraImage4Id
        extraImage5Id
        yearFounded
        yearInauguralSeason
        numberOfTeams
        leagueInfoUrl
        leaguePromoVideoContentId
        currentSeason
        createdAt
        updatedAt
        streamingSportsAndLeaguesPageLeaguesId
        __typename
      }
      sport
      tags
      stats
      searchSink
      imageFilename
      blurHash
      createdBy
      updatedBy
      reservationArn
      reservationAuthorized
      reservationName
      reservationPlaybackURL
      reservationTags
      reservationType
      oldReservationArn
      liveToVodPlaybackURL
      errorStatus
      errorMessageForVideoUpload
      createdAt
      updatedAt
      __typename
    }
    programId
    program {
      id
      name
      description
      tvRating
      sport
      leagueId
      league {
        id
        imageURL
        sport
        sportTypeTags
        region
        description
        title
        imageFilename
        blurHash
        createdBy
        updatedBy
        primaryColor
        secondaryColor
        textColorForPrimaryColor
        textColorForSecondaryColor
        backgroundColor
        textColorForBackgroundColor
        abbreviation
        fullName
        buyTicketsUrl
        publishBuyTicketsAtDate
        expirationBuyTicketsAtDate
        logoImageId
        bannerImageId
        extraImage1Id
        extraImage2Id
        extraImage3Id
        extraImage4Id
        extraImage5Id
        yearFounded
        yearInauguralSeason
        numberOfTeams
        leagueInfoUrl
        leaguePromoVideoContentId
        currentSeason
        createdAt
        updatedAt
        streamingSportsAndLeaguesPageLeaguesId
        __typename
      }
      tags
      games {
        nextToken
        __typename
      }
      streamingLiveScheduleEvents {
        nextToken
        __typename
      }
      createdBy
      updatedBy
      imageFilename
      blurHash
      seasons
      publishAtDate
      expirationAtDate
      onlyShowEpisodesInAProgramAsOneItem
      availabilityStatus
      createdAt
      updatedAt
      __typename
    }
    extraURL
    imageFilename
    blurHash
    createdBy
    updatedBy
    expirationDate
    publishedAtDate
    contentStatusType
    createdAt
    updatedAt
    __typename
  }
}
` ; /* GeneratedMutation<
  APITypes.UpdateStreamingSliderMutationVariables,
  APITypes.UpdateStreamingSliderMutation
 ; */ 
exports.deleteStreamingSlider = /* GraphQL */ `mutation DeleteStreamingSlider(
  $input: DeleteStreamingSliderInput!
  $condition: ModelStreamingSliderConditionInput
) {
  deleteStreamingSlider(input: $input, condition: $condition) {
    id
    title
    descriptionTop
    descriptionBottom
    imageURL
    order
    providerId
    provider {
      id
      provider
      description
      conduits
      providerType
      providerURL
      imageURL
      alt
      street
      city
      zipcode
      popular
      imageFilename
      blurHash
      createdBy
      updatedBy
      createdAt
      updatedAt
      __typename
    }
    category
    contentId
    content {
      id
      title
      description
      note
      video
      gameId
      watchList {
        nextToken
        __typename
      }
      game {
        id
        name
        description
        homeTeamId
        awayTeamId
        gameStartDateTime
        gameEndDateTime
        status
        tags
        seasonYear
        seasonType
        week
        fullWeek
        GB_LastScanStart
        GB_LastScanEnd
        quicksandStartIntervalSeconds
        quicksandEndIntervalSeconds
        sport
        leagueId
        nlseAssetHouseCode
        stats
        searchSink
        imageFilename
        blurHash
        createdBy
        updatedBy
        createdAt
        updatedAt
        programGamesId
        __typename
      }
      vodEntryId
      vodEntry {
        id
        body
        contentIdString
        dashUrl
        dashPlaylist
        hlsUrl
        hlsPlaylist
        stage
        publishedAt
        createdBy
        updatedBy
        errorStatus
        errorDescripton
        createdAt
        updatedAt
        __typename
      }
      programId
      program {
        id
        name
        description
        tvRating
        sport
        leagueId
        tags
        createdBy
        updatedBy
        imageFilename
        blurHash
        seasons
        publishAtDate
        expirationAtDate
        onlyShowEpisodesInAProgramAsOneItem
        availabilityStatus
        createdAt
        updatedAt
        __typename
      }
      tvRating
      contentType
      applicationType
      nlseAssetHouseCode
      episode
      episodeId
      episodeOrder
      episodeSeason
      isSupplementalContent
      supplementalContentOrder
      mainContentId
      publishAtDate
      expirationAtDate
      availabilityStatus
      contentLength
      seasonYear
      seasonType
      location
      leagueId
      league {
        id
        imageURL
        sport
        sportTypeTags
        region
        description
        title
        imageFilename
        blurHash
        createdBy
        updatedBy
        primaryColor
        secondaryColor
        textColorForPrimaryColor
        textColorForSecondaryColor
        backgroundColor
        textColorForBackgroundColor
        abbreviation
        fullName
        buyTicketsUrl
        publishBuyTicketsAtDate
        expirationBuyTicketsAtDate
        logoImageId
        bannerImageId
        extraImage1Id
        extraImage2Id
        extraImage3Id
        extraImage4Id
        extraImage5Id
        yearFounded
        yearInauguralSeason
        numberOfTeams
        leagueInfoUrl
        leaguePromoVideoContentId
        currentSeason
        createdAt
        updatedAt
        streamingSportsAndLeaguesPageLeaguesId
        __typename
      }
      sport
      tags
      stats
      searchSink
      imageFilename
      blurHash
      createdBy
      updatedBy
      reservationArn
      reservationAuthorized
      reservationName
      reservationPlaybackURL
      reservationTags
      reservationType
      oldReservationArn
      liveToVodPlaybackURL
      errorStatus
      errorMessageForVideoUpload
      createdAt
      updatedAt
      __typename
    }
    programId
    program {
      id
      name
      description
      tvRating
      sport
      leagueId
      league {
        id
        imageURL
        sport
        sportTypeTags
        region
        description
        title
        imageFilename
        blurHash
        createdBy
        updatedBy
        primaryColor
        secondaryColor
        textColorForPrimaryColor
        textColorForSecondaryColor
        backgroundColor
        textColorForBackgroundColor
        abbreviation
        fullName
        buyTicketsUrl
        publishBuyTicketsAtDate
        expirationBuyTicketsAtDate
        logoImageId
        bannerImageId
        extraImage1Id
        extraImage2Id
        extraImage3Id
        extraImage4Id
        extraImage5Id
        yearFounded
        yearInauguralSeason
        numberOfTeams
        leagueInfoUrl
        leaguePromoVideoContentId
        currentSeason
        createdAt
        updatedAt
        streamingSportsAndLeaguesPageLeaguesId
        __typename
      }
      tags
      games {
        nextToken
        __typename
      }
      streamingLiveScheduleEvents {
        nextToken
        __typename
      }
      createdBy
      updatedBy
      imageFilename
      blurHash
      seasons
      publishAtDate
      expirationAtDate
      onlyShowEpisodesInAProgramAsOneItem
      availabilityStatus
      createdAt
      updatedAt
      __typename
    }
    extraURL
    imageFilename
    blurHash
    createdBy
    updatedBy
    expirationDate
    publishedAtDate
    contentStatusType
    createdAt
    updatedAt
    __typename
  }
}
` ; /* GeneratedMutation<
  APITypes.DeleteStreamingSliderMutationVariables,
  APITypes.DeleteStreamingSliderMutation
 ; */ 
exports.createStreamingSearchTopic = /* GraphQL */ `mutation CreateStreamingSearchTopic(
  $input: CreateStreamingSearchTopicInput!
  $condition: ModelStreamingSearchTopicConditionInput
) {
  createStreamingSearchTopic(input: $input, condition: $condition) {
    id
    title
    description
    searchFilterKeyWords
    order
    createdBy
    updatedBy
    createdAt
    updatedAt
    __typename
  }
}
` ; /* GeneratedMutation<
  APITypes.CreateStreamingSearchTopicMutationVariables,
  APITypes.CreateStreamingSearchTopicMutation
 ; */ 
exports.updateStreamingSearchTopic = /* GraphQL */ `mutation UpdateStreamingSearchTopic(
  $input: UpdateStreamingSearchTopicInput!
  $condition: ModelStreamingSearchTopicConditionInput
) {
  updateStreamingSearchTopic(input: $input, condition: $condition) {
    id
    title
    description
    searchFilterKeyWords
    order
    createdBy
    updatedBy
    createdAt
    updatedAt
    __typename
  }
}
` ; /* GeneratedMutation<
  APITypes.UpdateStreamingSearchTopicMutationVariables,
  APITypes.UpdateStreamingSearchTopicMutation
 ; */ 
exports.deleteStreamingSearchTopic = /* GraphQL */ `mutation DeleteStreamingSearchTopic(
  $input: DeleteStreamingSearchTopicInput!
  $condition: ModelStreamingSearchTopicConditionInput
) {
  deleteStreamingSearchTopic(input: $input, condition: $condition) {
    id
    title
    description
    searchFilterKeyWords
    order
    createdBy
    updatedBy
    createdAt
    updatedAt
    __typename
  }
}
` ; /* GeneratedMutation<
  APITypes.DeleteStreamingSearchTopicMutationVariables,
  APITypes.DeleteStreamingSearchTopicMutation
 ; */ 
exports.createStreamingContactCustomerService = /* GraphQL */ `mutation CreateStreamingContactCustomerService(
  $input: CreateStreamingContactCustomerServiceInput!
  $condition: ModelStreamingContactCustomerServiceConditionInput
) {
  createStreamingContactCustomerService(input: $input, condition: $condition) {
    id
    username
    firstName
    lastName
    userContactEmail
    subject
    body
    customerServiceEmail
    helpTopic
    createdBy
    updatedBy
    createdAt
    updatedAt
    __typename
  }
}
` ; /* GeneratedMutation<
  APITypes.CreateStreamingContactCustomerServiceMutationVariables,
  APITypes.CreateStreamingContactCustomerServiceMutation
 ; */ 
exports.updateStreamingContactCustomerService = /* GraphQL */ `mutation UpdateStreamingContactCustomerService(
  $input: UpdateStreamingContactCustomerServiceInput!
  $condition: ModelStreamingContactCustomerServiceConditionInput
) {
  updateStreamingContactCustomerService(input: $input, condition: $condition) {
    id
    username
    firstName
    lastName
    userContactEmail
    subject
    body
    customerServiceEmail
    helpTopic
    createdBy
    updatedBy
    createdAt
    updatedAt
    __typename
  }
}
` ; /* GeneratedMutation<
  APITypes.UpdateStreamingContactCustomerServiceMutationVariables,
  APITypes.UpdateStreamingContactCustomerServiceMutation
 ; */ 
exports.deleteStreamingContactCustomerService = /* GraphQL */ `mutation DeleteStreamingContactCustomerService(
  $input: DeleteStreamingContactCustomerServiceInput!
  $condition: ModelStreamingContactCustomerServiceConditionInput
) {
  deleteStreamingContactCustomerService(input: $input, condition: $condition) {
    id
    username
    firstName
    lastName
    userContactEmail
    subject
    body
    customerServiceEmail
    helpTopic
    createdBy
    updatedBy
    createdAt
    updatedAt
    __typename
  }
}
` ; /* GeneratedMutation<
  APITypes.DeleteStreamingContactCustomerServiceMutationVariables,
  APITypes.DeleteStreamingContactCustomerServiceMutation
 ; */ 
exports.createStreamingHelpCenter = /* GraphQL */ `mutation CreateStreamingHelpCenter(
  $input: CreateStreamingHelpCenterInput!
  $condition: ModelStreamingHelpCenterConditionInput
) {
  createStreamingHelpCenter(input: $input, condition: $condition) {
    id
    question
    answer
    order
    createdBy
    updatedBy
    createdAt
    updatedAt
    __typename
  }
}
` ; /* GeneratedMutation<
  APITypes.CreateStreamingHelpCenterMutationVariables,
  APITypes.CreateStreamingHelpCenterMutation
 ; */ 
exports.updateStreamingHelpCenter = /* GraphQL */ `mutation UpdateStreamingHelpCenter(
  $input: UpdateStreamingHelpCenterInput!
  $condition: ModelStreamingHelpCenterConditionInput
) {
  updateStreamingHelpCenter(input: $input, condition: $condition) {
    id
    question
    answer
    order
    createdBy
    updatedBy
    createdAt
    updatedAt
    __typename
  }
}
` ; /* GeneratedMutation<
  APITypes.UpdateStreamingHelpCenterMutationVariables,
  APITypes.UpdateStreamingHelpCenterMutation
 ; */ 
exports.deleteStreamingHelpCenter = /* GraphQL */ `mutation DeleteStreamingHelpCenter(
  $input: DeleteStreamingHelpCenterInput!
  $condition: ModelStreamingHelpCenterConditionInput
) {
  deleteStreamingHelpCenter(input: $input, condition: $condition) {
    id
    question
    answer
    order
    createdBy
    updatedBy
    createdAt
    updatedAt
    __typename
  }
}
` ; /* GeneratedMutation<
  APITypes.DeleteStreamingHelpCenterMutationVariables,
  APITypes.DeleteStreamingHelpCenterMutation
 ; */ 
exports.createStreamingLiveScheduleEvent = /* GraphQL */ `mutation CreateStreamingLiveScheduleEvent(
  $input: CreateStreamingLiveScheduleEventInput!
  $condition: ModelStreamingLiveScheduleEventConditionInput
) {
  createStreamingLiveScheduleEvent(input: $input, condition: $condition) {
    id
    title
    description
    imageURL
    DateTime
    filterDateTime
    gameId
    game {
      id
      name
      description
      homeTeamId
      homeTeam {
        id
        name
        sport
        leagueId
        location
        imageURL
        imageFilename
        blurHash
        createdBy
        updatedBy
        createdAt
        updatedAt
        __typename
      }
      awayTeamId
      awayTeam {
        id
        name
        sport
        leagueId
        location
        imageURL
        imageFilename
        blurHash
        createdBy
        updatedBy
        createdAt
        updatedAt
        __typename
      }
      gameStartDateTime
      gameEndDateTime
      status
      tags
      seasonYear
      seasonType
      week
      fullWeek
      GB_LastScanStart
      GB_LastScanEnd
      quicksandStartIntervalSeconds
      quicksandEndIntervalSeconds
      sport
      leagueId
      league {
        id
        imageURL
        sport
        sportTypeTags
        region
        description
        title
        imageFilename
        blurHash
        createdBy
        updatedBy
        primaryColor
        secondaryColor
        textColorForPrimaryColor
        textColorForSecondaryColor
        backgroundColor
        textColorForBackgroundColor
        abbreviation
        fullName
        buyTicketsUrl
        publishBuyTicketsAtDate
        expirationBuyTicketsAtDate
        logoImageId
        bannerImageId
        extraImage1Id
        extraImage2Id
        extraImage3Id
        extraImage4Id
        extraImage5Id
        yearFounded
        yearInauguralSeason
        numberOfTeams
        leagueInfoUrl
        leaguePromoVideoContentId
        currentSeason
        createdAt
        updatedAt
        streamingSportsAndLeaguesPageLeaguesId
        __typename
      }
      nlseAssetHouseCode
      stats
      searchSink
      imageFilename
      blurHash
      createdBy
      updatedBy
      createdAt
      updatedAt
      programGamesId
      __typename
    }
    contentId
    content {
      id
      title
      description
      note
      video
      gameId
      watchList {
        nextToken
        __typename
      }
      game {
        id
        name
        description
        homeTeamId
        awayTeamId
        gameStartDateTime
        gameEndDateTime
        status
        tags
        seasonYear
        seasonType
        week
        fullWeek
        GB_LastScanStart
        GB_LastScanEnd
        quicksandStartIntervalSeconds
        quicksandEndIntervalSeconds
        sport
        leagueId
        nlseAssetHouseCode
        stats
        searchSink
        imageFilename
        blurHash
        createdBy
        updatedBy
        createdAt
        updatedAt
        programGamesId
        __typename
      }
      vodEntryId
      vodEntry {
        id
        body
        contentIdString
        dashUrl
        dashPlaylist
        hlsUrl
        hlsPlaylist
        stage
        publishedAt
        createdBy
        updatedBy
        errorStatus
        errorDescripton
        createdAt
        updatedAt
        __typename
      }
      programId
      program {
        id
        name
        description
        tvRating
        sport
        leagueId
        tags
        createdBy
        updatedBy
        imageFilename
        blurHash
        seasons
        publishAtDate
        expirationAtDate
        onlyShowEpisodesInAProgramAsOneItem
        availabilityStatus
        createdAt
        updatedAt
        __typename
      }
      tvRating
      contentType
      applicationType
      nlseAssetHouseCode
      episode
      episodeId
      episodeOrder
      episodeSeason
      isSupplementalContent
      supplementalContentOrder
      mainContentId
      publishAtDate
      expirationAtDate
      availabilityStatus
      contentLength
      seasonYear
      seasonType
      location
      leagueId
      league {
        id
        imageURL
        sport
        sportTypeTags
        region
        description
        title
        imageFilename
        blurHash
        createdBy
        updatedBy
        primaryColor
        secondaryColor
        textColorForPrimaryColor
        textColorForSecondaryColor
        backgroundColor
        textColorForBackgroundColor
        abbreviation
        fullName
        buyTicketsUrl
        publishBuyTicketsAtDate
        expirationBuyTicketsAtDate
        logoImageId
        bannerImageId
        extraImage1Id
        extraImage2Id
        extraImage3Id
        extraImage4Id
        extraImage5Id
        yearFounded
        yearInauguralSeason
        numberOfTeams
        leagueInfoUrl
        leaguePromoVideoContentId
        currentSeason
        createdAt
        updatedAt
        streamingSportsAndLeaguesPageLeaguesId
        __typename
      }
      sport
      tags
      stats
      searchSink
      imageFilename
      blurHash
      createdBy
      updatedBy
      reservationArn
      reservationAuthorized
      reservationName
      reservationPlaybackURL
      reservationTags
      reservationType
      oldReservationArn
      liveToVodPlaybackURL
      errorStatus
      errorMessageForVideoUpload
      createdAt
      updatedAt
      __typename
    }
    leagueId
    league {
      id
      imageURL
      sport
      sportTypeTags
      region
      description
      title
      imageFilename
      blurHash
      createdBy
      updatedBy
      primaryColor
      secondaryColor
      textColorForPrimaryColor
      textColorForSecondaryColor
      backgroundColor
      textColorForBackgroundColor
      abbreviation
      fullName
      buyTicketsUrl
      publishBuyTicketsAtDate
      expirationBuyTicketsAtDate
      logoImageId
      logoImage {
        id
        name
        altText
        imageFilename
        blurHash
        description
        tags
        createdBy
        updatedBy
        searchSink
        createdAt
        updatedAt
        __typename
      }
      bannerImageId
      bannerImage {
        id
        name
        altText
        imageFilename
        blurHash
        description
        tags
        createdBy
        updatedBy
        searchSink
        createdAt
        updatedAt
        __typename
      }
      extraImage1Id
      extraImage1 {
        id
        name
        altText
        imageFilename
        blurHash
        description
        tags
        createdBy
        updatedBy
        searchSink
        createdAt
        updatedAt
        __typename
      }
      extraImage2Id
      extraImage2 {
        id
        name
        altText
        imageFilename
        blurHash
        description
        tags
        createdBy
        updatedBy
        searchSink
        createdAt
        updatedAt
        __typename
      }
      extraImage3Id
      extraImage3 {
        id
        name
        altText
        imageFilename
        blurHash
        description
        tags
        createdBy
        updatedBy
        searchSink
        createdAt
        updatedAt
        __typename
      }
      extraImage4Id
      extraImage4 {
        id
        name
        altText
        imageFilename
        blurHash
        description
        tags
        createdBy
        updatedBy
        searchSink
        createdAt
        updatedAt
        __typename
      }
      extraImage5Id
      extraImage5 {
        id
        name
        altText
        imageFilename
        blurHash
        description
        tags
        createdBy
        updatedBy
        searchSink
        createdAt
        updatedAt
        __typename
      }
      yearFounded
      yearInauguralSeason
      numberOfTeams
      leagueInfoUrl
      leaguePromoVideoContentId
      currentSeason
      createdAt
      updatedAt
      streamingSportsAndLeaguesPageLeaguesId
      __typename
    }
    tags
    playbackURL
    createdBy
    updatedBy
    version
    imageFilename
    blurHash
    createdAt
    updatedAt
    programStreamingLiveScheduleEventsId
    __typename
  }
}
` ; /* GeneratedMutation<
  APITypes.CreateStreamingLiveScheduleEventMutationVariables,
  APITypes.CreateStreamingLiveScheduleEventMutation
 ; */ 
exports.updateStreamingLiveScheduleEvent = /* GraphQL */ `mutation UpdateStreamingLiveScheduleEvent(
  $input: UpdateStreamingLiveScheduleEventInput!
  $condition: ModelStreamingLiveScheduleEventConditionInput
) {
  updateStreamingLiveScheduleEvent(input: $input, condition: $condition) {
    id
    title
    description
    imageURL
    DateTime
    filterDateTime
    gameId
    game {
      id
      name
      description
      homeTeamId
      homeTeam {
        id
        name
        sport
        leagueId
        location
        imageURL
        imageFilename
        blurHash
        createdBy
        updatedBy
        createdAt
        updatedAt
        __typename
      }
      awayTeamId
      awayTeam {
        id
        name
        sport
        leagueId
        location
        imageURL
        imageFilename
        blurHash
        createdBy
        updatedBy
        createdAt
        updatedAt
        __typename
      }
      gameStartDateTime
      gameEndDateTime
      status
      tags
      seasonYear
      seasonType
      week
      fullWeek
      GB_LastScanStart
      GB_LastScanEnd
      quicksandStartIntervalSeconds
      quicksandEndIntervalSeconds
      sport
      leagueId
      league {
        id
        imageURL
        sport
        sportTypeTags
        region
        description
        title
        imageFilename
        blurHash
        createdBy
        updatedBy
        primaryColor
        secondaryColor
        textColorForPrimaryColor
        textColorForSecondaryColor
        backgroundColor
        textColorForBackgroundColor
        abbreviation
        fullName
        buyTicketsUrl
        publishBuyTicketsAtDate
        expirationBuyTicketsAtDate
        logoImageId
        bannerImageId
        extraImage1Id
        extraImage2Id
        extraImage3Id
        extraImage4Id
        extraImage5Id
        yearFounded
        yearInauguralSeason
        numberOfTeams
        leagueInfoUrl
        leaguePromoVideoContentId
        currentSeason
        createdAt
        updatedAt
        streamingSportsAndLeaguesPageLeaguesId
        __typename
      }
      nlseAssetHouseCode
      stats
      searchSink
      imageFilename
      blurHash
      createdBy
      updatedBy
      createdAt
      updatedAt
      programGamesId
      __typename
    }
    contentId
    content {
      id
      title
      description
      note
      video
      gameId
      watchList {
        nextToken
        __typename
      }
      game {
        id
        name
        description
        homeTeamId
        awayTeamId
        gameStartDateTime
        gameEndDateTime
        status
        tags
        seasonYear
        seasonType
        week
        fullWeek
        GB_LastScanStart
        GB_LastScanEnd
        quicksandStartIntervalSeconds
        quicksandEndIntervalSeconds
        sport
        leagueId
        nlseAssetHouseCode
        stats
        searchSink
        imageFilename
        blurHash
        createdBy
        updatedBy
        createdAt
        updatedAt
        programGamesId
        __typename
      }
      vodEntryId
      vodEntry {
        id
        body
        contentIdString
        dashUrl
        dashPlaylist
        hlsUrl
        hlsPlaylist
        stage
        publishedAt
        createdBy
        updatedBy
        errorStatus
        errorDescripton
        createdAt
        updatedAt
        __typename
      }
      programId
      program {
        id
        name
        description
        tvRating
        sport
        leagueId
        tags
        createdBy
        updatedBy
        imageFilename
        blurHash
        seasons
        publishAtDate
        expirationAtDate
        onlyShowEpisodesInAProgramAsOneItem
        availabilityStatus
        createdAt
        updatedAt
        __typename
      }
      tvRating
      contentType
      applicationType
      nlseAssetHouseCode
      episode
      episodeId
      episodeOrder
      episodeSeason
      isSupplementalContent
      supplementalContentOrder
      mainContentId
      publishAtDate
      expirationAtDate
      availabilityStatus
      contentLength
      seasonYear
      seasonType
      location
      leagueId
      league {
        id
        imageURL
        sport
        sportTypeTags
        region
        description
        title
        imageFilename
        blurHash
        createdBy
        updatedBy
        primaryColor
        secondaryColor
        textColorForPrimaryColor
        textColorForSecondaryColor
        backgroundColor
        textColorForBackgroundColor
        abbreviation
        fullName
        buyTicketsUrl
        publishBuyTicketsAtDate
        expirationBuyTicketsAtDate
        logoImageId
        bannerImageId
        extraImage1Id
        extraImage2Id
        extraImage3Id
        extraImage4Id
        extraImage5Id
        yearFounded
        yearInauguralSeason
        numberOfTeams
        leagueInfoUrl
        leaguePromoVideoContentId
        currentSeason
        createdAt
        updatedAt
        streamingSportsAndLeaguesPageLeaguesId
        __typename
      }
      sport
      tags
      stats
      searchSink
      imageFilename
      blurHash
      createdBy
      updatedBy
      reservationArn
      reservationAuthorized
      reservationName
      reservationPlaybackURL
      reservationTags
      reservationType
      oldReservationArn
      liveToVodPlaybackURL
      errorStatus
      errorMessageForVideoUpload
      createdAt
      updatedAt
      __typename
    }
    leagueId
    league {
      id
      imageURL
      sport
      sportTypeTags
      region
      description
      title
      imageFilename
      blurHash
      createdBy
      updatedBy
      primaryColor
      secondaryColor
      textColorForPrimaryColor
      textColorForSecondaryColor
      backgroundColor
      textColorForBackgroundColor
      abbreviation
      fullName
      buyTicketsUrl
      publishBuyTicketsAtDate
      expirationBuyTicketsAtDate
      logoImageId
      logoImage {
        id
        name
        altText
        imageFilename
        blurHash
        description
        tags
        createdBy
        updatedBy
        searchSink
        createdAt
        updatedAt
        __typename
      }
      bannerImageId
      bannerImage {
        id
        name
        altText
        imageFilename
        blurHash
        description
        tags
        createdBy
        updatedBy
        searchSink
        createdAt
        updatedAt
        __typename
      }
      extraImage1Id
      extraImage1 {
        id
        name
        altText
        imageFilename
        blurHash
        description
        tags
        createdBy
        updatedBy
        searchSink
        createdAt
        updatedAt
        __typename
      }
      extraImage2Id
      extraImage2 {
        id
        name
        altText
        imageFilename
        blurHash
        description
        tags
        createdBy
        updatedBy
        searchSink
        createdAt
        updatedAt
        __typename
      }
      extraImage3Id
      extraImage3 {
        id
        name
        altText
        imageFilename
        blurHash
        description
        tags
        createdBy
        updatedBy
        searchSink
        createdAt
        updatedAt
        __typename
      }
      extraImage4Id
      extraImage4 {
        id
        name
        altText
        imageFilename
        blurHash
        description
        tags
        createdBy
        updatedBy
        searchSink
        createdAt
        updatedAt
        __typename
      }
      extraImage5Id
      extraImage5 {
        id
        name
        altText
        imageFilename
        blurHash
        description
        tags
        createdBy
        updatedBy
        searchSink
        createdAt
        updatedAt
        __typename
      }
      yearFounded
      yearInauguralSeason
      numberOfTeams
      leagueInfoUrl
      leaguePromoVideoContentId
      currentSeason
      createdAt
      updatedAt
      streamingSportsAndLeaguesPageLeaguesId
      __typename
    }
    tags
    playbackURL
    createdBy
    updatedBy
    version
    imageFilename
    blurHash
    createdAt
    updatedAt
    programStreamingLiveScheduleEventsId
    __typename
  }
}
` ; /* GeneratedMutation<
  APITypes.UpdateStreamingLiveScheduleEventMutationVariables,
  APITypes.UpdateStreamingLiveScheduleEventMutation
 ; */ 
exports.deleteStreamingLiveScheduleEvent = /* GraphQL */ `mutation DeleteStreamingLiveScheduleEvent(
  $input: DeleteStreamingLiveScheduleEventInput!
  $condition: ModelStreamingLiveScheduleEventConditionInput
) {
  deleteStreamingLiveScheduleEvent(input: $input, condition: $condition) {
    id
    title
    description
    imageURL
    DateTime
    filterDateTime
    gameId
    game {
      id
      name
      description
      homeTeamId
      homeTeam {
        id
        name
        sport
        leagueId
        location
        imageURL
        imageFilename
        blurHash
        createdBy
        updatedBy
        createdAt
        updatedAt
        __typename
      }
      awayTeamId
      awayTeam {
        id
        name
        sport
        leagueId
        location
        imageURL
        imageFilename
        blurHash
        createdBy
        updatedBy
        createdAt
        updatedAt
        __typename
      }
      gameStartDateTime
      gameEndDateTime
      status
      tags
      seasonYear
      seasonType
      week
      fullWeek
      GB_LastScanStart
      GB_LastScanEnd
      quicksandStartIntervalSeconds
      quicksandEndIntervalSeconds
      sport
      leagueId
      league {
        id
        imageURL
        sport
        sportTypeTags
        region
        description
        title
        imageFilename
        blurHash
        createdBy
        updatedBy
        primaryColor
        secondaryColor
        textColorForPrimaryColor
        textColorForSecondaryColor
        backgroundColor
        textColorForBackgroundColor
        abbreviation
        fullName
        buyTicketsUrl
        publishBuyTicketsAtDate
        expirationBuyTicketsAtDate
        logoImageId
        bannerImageId
        extraImage1Id
        extraImage2Id
        extraImage3Id
        extraImage4Id
        extraImage5Id
        yearFounded
        yearInauguralSeason
        numberOfTeams
        leagueInfoUrl
        leaguePromoVideoContentId
        currentSeason
        createdAt
        updatedAt
        streamingSportsAndLeaguesPageLeaguesId
        __typename
      }
      nlseAssetHouseCode
      stats
      searchSink
      imageFilename
      blurHash
      createdBy
      updatedBy
      createdAt
      updatedAt
      programGamesId
      __typename
    }
    contentId
    content {
      id
      title
      description
      note
      video
      gameId
      watchList {
        nextToken
        __typename
      }
      game {
        id
        name
        description
        homeTeamId
        awayTeamId
        gameStartDateTime
        gameEndDateTime
        status
        tags
        seasonYear
        seasonType
        week
        fullWeek
        GB_LastScanStart
        GB_LastScanEnd
        quicksandStartIntervalSeconds
        quicksandEndIntervalSeconds
        sport
        leagueId
        nlseAssetHouseCode
        stats
        searchSink
        imageFilename
        blurHash
        createdBy
        updatedBy
        createdAt
        updatedAt
        programGamesId
        __typename
      }
      vodEntryId
      vodEntry {
        id
        body
        contentIdString
        dashUrl
        dashPlaylist
        hlsUrl
        hlsPlaylist
        stage
        publishedAt
        createdBy
        updatedBy
        errorStatus
        errorDescripton
        createdAt
        updatedAt
        __typename
      }
      programId
      program {
        id
        name
        description
        tvRating
        sport
        leagueId
        tags
        createdBy
        updatedBy
        imageFilename
        blurHash
        seasons
        publishAtDate
        expirationAtDate
        onlyShowEpisodesInAProgramAsOneItem
        availabilityStatus
        createdAt
        updatedAt
        __typename
      }
      tvRating
      contentType
      applicationType
      nlseAssetHouseCode
      episode
      episodeId
      episodeOrder
      episodeSeason
      isSupplementalContent
      supplementalContentOrder
      mainContentId
      publishAtDate
      expirationAtDate
      availabilityStatus
      contentLength
      seasonYear
      seasonType
      location
      leagueId
      league {
        id
        imageURL
        sport
        sportTypeTags
        region
        description
        title
        imageFilename
        blurHash
        createdBy
        updatedBy
        primaryColor
        secondaryColor
        textColorForPrimaryColor
        textColorForSecondaryColor
        backgroundColor
        textColorForBackgroundColor
        abbreviation
        fullName
        buyTicketsUrl
        publishBuyTicketsAtDate
        expirationBuyTicketsAtDate
        logoImageId
        bannerImageId
        extraImage1Id
        extraImage2Id
        extraImage3Id
        extraImage4Id
        extraImage5Id
        yearFounded
        yearInauguralSeason
        numberOfTeams
        leagueInfoUrl
        leaguePromoVideoContentId
        currentSeason
        createdAt
        updatedAt
        streamingSportsAndLeaguesPageLeaguesId
        __typename
      }
      sport
      tags
      stats
      searchSink
      imageFilename
      blurHash
      createdBy
      updatedBy
      reservationArn
      reservationAuthorized
      reservationName
      reservationPlaybackURL
      reservationTags
      reservationType
      oldReservationArn
      liveToVodPlaybackURL
      errorStatus
      errorMessageForVideoUpload
      createdAt
      updatedAt
      __typename
    }
    leagueId
    league {
      id
      imageURL
      sport
      sportTypeTags
      region
      description
      title
      imageFilename
      blurHash
      createdBy
      updatedBy
      primaryColor
      secondaryColor
      textColorForPrimaryColor
      textColorForSecondaryColor
      backgroundColor
      textColorForBackgroundColor
      abbreviation
      fullName
      buyTicketsUrl
      publishBuyTicketsAtDate
      expirationBuyTicketsAtDate
      logoImageId
      logoImage {
        id
        name
        altText
        imageFilename
        blurHash
        description
        tags
        createdBy
        updatedBy
        searchSink
        createdAt
        updatedAt
        __typename
      }
      bannerImageId
      bannerImage {
        id
        name
        altText
        imageFilename
        blurHash
        description
        tags
        createdBy
        updatedBy
        searchSink
        createdAt
        updatedAt
        __typename
      }
      extraImage1Id
      extraImage1 {
        id
        name
        altText
        imageFilename
        blurHash
        description
        tags
        createdBy
        updatedBy
        searchSink
        createdAt
        updatedAt
        __typename
      }
      extraImage2Id
      extraImage2 {
        id
        name
        altText
        imageFilename
        blurHash
        description
        tags
        createdBy
        updatedBy
        searchSink
        createdAt
        updatedAt
        __typename
      }
      extraImage3Id
      extraImage3 {
        id
        name
        altText
        imageFilename
        blurHash
        description
        tags
        createdBy
        updatedBy
        searchSink
        createdAt
        updatedAt
        __typename
      }
      extraImage4Id
      extraImage4 {
        id
        name
        altText
        imageFilename
        blurHash
        description
        tags
        createdBy
        updatedBy
        searchSink
        createdAt
        updatedAt
        __typename
      }
      extraImage5Id
      extraImage5 {
        id
        name
        altText
        imageFilename
        blurHash
        description
        tags
        createdBy
        updatedBy
        searchSink
        createdAt
        updatedAt
        __typename
      }
      yearFounded
      yearInauguralSeason
      numberOfTeams
      leagueInfoUrl
      leaguePromoVideoContentId
      currentSeason
      createdAt
      updatedAt
      streamingSportsAndLeaguesPageLeaguesId
      __typename
    }
    tags
    playbackURL
    createdBy
    updatedBy
    version
    imageFilename
    blurHash
    createdAt
    updatedAt
    programStreamingLiveScheduleEventsId
    __typename
  }
}
` ; /* GeneratedMutation<
  APITypes.DeleteStreamingLiveScheduleEventMutationVariables,
  APITypes.DeleteStreamingLiveScheduleEventMutation
 ; */ 
exports.createStreamingAdvertiserSponsor = /* GraphQL */ `mutation CreateStreamingAdvertiserSponsor(
  $input: CreateStreamingAdvertiserSponsorInput!
  $condition: ModelStreamingAdvertiserSponsorConditionInput
) {
  createStreamingAdvertiserSponsor(input: $input, condition: $condition) {
    id
    companyName
    companyImageURL
    displayTitle
    displayDescription
    displayImageURL
    advertiserURL
    order
    createdBy
    updatedBy
    createdAt
    updatedAt
    __typename
  }
}
` ; /* GeneratedMutation<
  APITypes.CreateStreamingAdvertiserSponsorMutationVariables,
  APITypes.CreateStreamingAdvertiserSponsorMutation
 ; */ 
exports.updateStreamingAdvertiserSponsor = /* GraphQL */ `mutation UpdateStreamingAdvertiserSponsor(
  $input: UpdateStreamingAdvertiserSponsorInput!
  $condition: ModelStreamingAdvertiserSponsorConditionInput
) {
  updateStreamingAdvertiserSponsor(input: $input, condition: $condition) {
    id
    companyName
    companyImageURL
    displayTitle
    displayDescription
    displayImageURL
    advertiserURL
    order
    createdBy
    updatedBy
    createdAt
    updatedAt
    __typename
  }
}
` ; /* GeneratedMutation<
  APITypes.UpdateStreamingAdvertiserSponsorMutationVariables,
  APITypes.UpdateStreamingAdvertiserSponsorMutation
 ; */ 
exports.deleteStreamingAdvertiserSponsor = /* GraphQL */ `mutation DeleteStreamingAdvertiserSponsor(
  $input: DeleteStreamingAdvertiserSponsorInput!
  $condition: ModelStreamingAdvertiserSponsorConditionInput
) {
  deleteStreamingAdvertiserSponsor(input: $input, condition: $condition) {
    id
    companyName
    companyImageURL
    displayTitle
    displayDescription
    displayImageURL
    advertiserURL
    order
    createdBy
    updatedBy
    createdAt
    updatedAt
    __typename
  }
}
` ; /* GeneratedMutation<
  APITypes.DeleteStreamingAdvertiserSponsorMutationVariables,
  APITypes.DeleteStreamingAdvertiserSponsorMutation
 ; */ 
exports.createNewsArticle = /* GraphQL */ `mutation CreateNewsArticle(
  $input: CreateNewsArticleInput!
  $condition: ModelNewsArticleConditionInput
) {
  createNewsArticle(input: $input, condition: $condition) {
    id
    title
    imageURL
    subtitle
    text
    summary
    dateTime
    releaseURL
    shortTitle
    tags
    leagueId
    league {
      id
      imageURL
      sport
      sportTypeTags
      region
      description
      title
      imageFilename
      blurHash
      createdBy
      updatedBy
      primaryColor
      secondaryColor
      textColorForPrimaryColor
      textColorForSecondaryColor
      backgroundColor
      textColorForBackgroundColor
      abbreviation
      fullName
      buyTicketsUrl
      publishBuyTicketsAtDate
      expirationBuyTicketsAtDate
      logoImageId
      logoImage {
        id
        name
        altText
        imageFilename
        blurHash
        description
        tags
        createdBy
        updatedBy
        searchSink
        createdAt
        updatedAt
        __typename
      }
      bannerImageId
      bannerImage {
        id
        name
        altText
        imageFilename
        blurHash
        description
        tags
        createdBy
        updatedBy
        searchSink
        createdAt
        updatedAt
        __typename
      }
      extraImage1Id
      extraImage1 {
        id
        name
        altText
        imageFilename
        blurHash
        description
        tags
        createdBy
        updatedBy
        searchSink
        createdAt
        updatedAt
        __typename
      }
      extraImage2Id
      extraImage2 {
        id
        name
        altText
        imageFilename
        blurHash
        description
        tags
        createdBy
        updatedBy
        searchSink
        createdAt
        updatedAt
        __typename
      }
      extraImage3Id
      extraImage3 {
        id
        name
        altText
        imageFilename
        blurHash
        description
        tags
        createdBy
        updatedBy
        searchSink
        createdAt
        updatedAt
        __typename
      }
      extraImage4Id
      extraImage4 {
        id
        name
        altText
        imageFilename
        blurHash
        description
        tags
        createdBy
        updatedBy
        searchSink
        createdAt
        updatedAt
        __typename
      }
      extraImage5Id
      extraImage5 {
        id
        name
        altText
        imageFilename
        blurHash
        description
        tags
        createdBy
        updatedBy
        searchSink
        createdAt
        updatedAt
        __typename
      }
      yearFounded
      yearInauguralSeason
      numberOfTeams
      leagueInfoUrl
      leaguePromoVideoContentId
      currentSeason
      createdAt
      updatedAt
      streamingSportsAndLeaguesPageLeaguesId
      __typename
    }
    sport
    sportTypeTags
    relatedArticles {
      items {
        id
        title
        imageURL
        subtitle
        text
        summary
        dateTime
        releaseURL
        shortTitle
        tags
        leagueId
        sport
        sportTypeTags
        stage
        publishedAt
        createdBy
        updatedBy
        frontendNewsMedium
        extraURL
        pinned
        contentId
        applicationType
        authorId
        photoCredit
        searchSink
        imageFilename
        blurHash
        createdAt
        updatedAt
        newsArticleRelatedArticlesId
        __typename
      }
      nextToken
      __typename
    }
    stage
    publishedAt
    createdBy
    updatedBy
    frontendNewsMedium
    extraURL
    pinned
    contentId
    content {
      id
      title
      description
      note
      video
      gameId
      watchList {
        nextToken
        __typename
      }
      game {
        id
        name
        description
        homeTeamId
        awayTeamId
        gameStartDateTime
        gameEndDateTime
        status
        tags
        seasonYear
        seasonType
        week
        fullWeek
        GB_LastScanStart
        GB_LastScanEnd
        quicksandStartIntervalSeconds
        quicksandEndIntervalSeconds
        sport
        leagueId
        nlseAssetHouseCode
        stats
        searchSink
        imageFilename
        blurHash
        createdBy
        updatedBy
        createdAt
        updatedAt
        programGamesId
        __typename
      }
      vodEntryId
      vodEntry {
        id
        body
        contentIdString
        dashUrl
        dashPlaylist
        hlsUrl
        hlsPlaylist
        stage
        publishedAt
        createdBy
        updatedBy
        errorStatus
        errorDescripton
        createdAt
        updatedAt
        __typename
      }
      programId
      program {
        id
        name
        description
        tvRating
        sport
        leagueId
        tags
        createdBy
        updatedBy
        imageFilename
        blurHash
        seasons
        publishAtDate
        expirationAtDate
        onlyShowEpisodesInAProgramAsOneItem
        availabilityStatus
        createdAt
        updatedAt
        __typename
      }
      tvRating
      contentType
      applicationType
      nlseAssetHouseCode
      episode
      episodeId
      episodeOrder
      episodeSeason
      isSupplementalContent
      supplementalContentOrder
      mainContentId
      publishAtDate
      expirationAtDate
      availabilityStatus
      contentLength
      seasonYear
      seasonType
      location
      leagueId
      league {
        id
        imageURL
        sport
        sportTypeTags
        region
        description
        title
        imageFilename
        blurHash
        createdBy
        updatedBy
        primaryColor
        secondaryColor
        textColorForPrimaryColor
        textColorForSecondaryColor
        backgroundColor
        textColorForBackgroundColor
        abbreviation
        fullName
        buyTicketsUrl
        publishBuyTicketsAtDate
        expirationBuyTicketsAtDate
        logoImageId
        bannerImageId
        extraImage1Id
        extraImage2Id
        extraImage3Id
        extraImage4Id
        extraImage5Id
        yearFounded
        yearInauguralSeason
        numberOfTeams
        leagueInfoUrl
        leaguePromoVideoContentId
        currentSeason
        createdAt
        updatedAt
        streamingSportsAndLeaguesPageLeaguesId
        __typename
      }
      sport
      tags
      stats
      searchSink
      imageFilename
      blurHash
      createdBy
      updatedBy
      reservationArn
      reservationAuthorized
      reservationName
      reservationPlaybackURL
      reservationTags
      reservationType
      oldReservationArn
      liveToVodPlaybackURL
      errorStatus
      errorMessageForVideoUpload
      createdAt
      updatedAt
      __typename
    }
    applicationType
    authorId
    author {
      id
      fullName
      designation
      imageFilename
      imageURL
      blurHash
      createdBy
      updatedBy
      createdAt
      updatedAt
      __typename
    }
    photoCredit
    searchSink
    imageFilename
    blurHash
    createdAt
    updatedAt
    newsArticleRelatedArticlesId
    __typename
  }
}
` ; /* GeneratedMutation<
  APITypes.CreateNewsArticleMutationVariables,
  APITypes.CreateNewsArticleMutation
 ; */ 
exports.updateNewsArticle = /* GraphQL */ `mutation UpdateNewsArticle(
  $input: UpdateNewsArticleInput!
  $condition: ModelNewsArticleConditionInput
) {
  updateNewsArticle(input: $input, condition: $condition) {
    id
    title
    imageURL
    subtitle
    text
    summary
    dateTime
    releaseURL
    shortTitle
    tags
    leagueId
    league {
      id
      imageURL
      sport
      sportTypeTags
      region
      description
      title
      imageFilename
      blurHash
      createdBy
      updatedBy
      primaryColor
      secondaryColor
      textColorForPrimaryColor
      textColorForSecondaryColor
      backgroundColor
      textColorForBackgroundColor
      abbreviation
      fullName
      buyTicketsUrl
      publishBuyTicketsAtDate
      expirationBuyTicketsAtDate
      logoImageId
      logoImage {
        id
        name
        altText
        imageFilename
        blurHash
        description
        tags
        createdBy
        updatedBy
        searchSink
        createdAt
        updatedAt
        __typename
      }
      bannerImageId
      bannerImage {
        id
        name
        altText
        imageFilename
        blurHash
        description
        tags
        createdBy
        updatedBy
        searchSink
        createdAt
        updatedAt
        __typename
      }
      extraImage1Id
      extraImage1 {
        id
        name
        altText
        imageFilename
        blurHash
        description
        tags
        createdBy
        updatedBy
        searchSink
        createdAt
        updatedAt
        __typename
      }
      extraImage2Id
      extraImage2 {
        id
        name
        altText
        imageFilename
        blurHash
        description
        tags
        createdBy
        updatedBy
        searchSink
        createdAt
        updatedAt
        __typename
      }
      extraImage3Id
      extraImage3 {
        id
        name
        altText
        imageFilename
        blurHash
        description
        tags
        createdBy
        updatedBy
        searchSink
        createdAt
        updatedAt
        __typename
      }
      extraImage4Id
      extraImage4 {
        id
        name
        altText
        imageFilename
        blurHash
        description
        tags
        createdBy
        updatedBy
        searchSink
        createdAt
        updatedAt
        __typename
      }
      extraImage5Id
      extraImage5 {
        id
        name
        altText
        imageFilename
        blurHash
        description
        tags
        createdBy
        updatedBy
        searchSink
        createdAt
        updatedAt
        __typename
      }
      yearFounded
      yearInauguralSeason
      numberOfTeams
      leagueInfoUrl
      leaguePromoVideoContentId
      currentSeason
      createdAt
      updatedAt
      streamingSportsAndLeaguesPageLeaguesId
      __typename
    }
    sport
    sportTypeTags
    relatedArticles {
      items {
        id
        title
        imageURL
        subtitle
        text
        summary
        dateTime
        releaseURL
        shortTitle
        tags
        leagueId
        sport
        sportTypeTags
        stage
        publishedAt
        createdBy
        updatedBy
        frontendNewsMedium
        extraURL
        pinned
        contentId
        applicationType
        authorId
        photoCredit
        searchSink
        imageFilename
        blurHash
        createdAt
        updatedAt
        newsArticleRelatedArticlesId
        __typename
      }
      nextToken
      __typename
    }
    stage
    publishedAt
    createdBy
    updatedBy
    frontendNewsMedium
    extraURL
    pinned
    contentId
    content {
      id
      title
      description
      note
      video
      gameId
      watchList {
        nextToken
        __typename
      }
      game {
        id
        name
        description
        homeTeamId
        awayTeamId
        gameStartDateTime
        gameEndDateTime
        status
        tags
        seasonYear
        seasonType
        week
        fullWeek
        GB_LastScanStart
        GB_LastScanEnd
        quicksandStartIntervalSeconds
        quicksandEndIntervalSeconds
        sport
        leagueId
        nlseAssetHouseCode
        stats
        searchSink
        imageFilename
        blurHash
        createdBy
        updatedBy
        createdAt
        updatedAt
        programGamesId
        __typename
      }
      vodEntryId
      vodEntry {
        id
        body
        contentIdString
        dashUrl
        dashPlaylist
        hlsUrl
        hlsPlaylist
        stage
        publishedAt
        createdBy
        updatedBy
        errorStatus
        errorDescripton
        createdAt
        updatedAt
        __typename
      }
      programId
      program {
        id
        name
        description
        tvRating
        sport
        leagueId
        tags
        createdBy
        updatedBy
        imageFilename
        blurHash
        seasons
        publishAtDate
        expirationAtDate
        onlyShowEpisodesInAProgramAsOneItem
        availabilityStatus
        createdAt
        updatedAt
        __typename
      }
      tvRating
      contentType
      applicationType
      nlseAssetHouseCode
      episode
      episodeId
      episodeOrder
      episodeSeason
      isSupplementalContent
      supplementalContentOrder
      mainContentId
      publishAtDate
      expirationAtDate
      availabilityStatus
      contentLength
      seasonYear
      seasonType
      location
      leagueId
      league {
        id
        imageURL
        sport
        sportTypeTags
        region
        description
        title
        imageFilename
        blurHash
        createdBy
        updatedBy
        primaryColor
        secondaryColor
        textColorForPrimaryColor
        textColorForSecondaryColor
        backgroundColor
        textColorForBackgroundColor
        abbreviation
        fullName
        buyTicketsUrl
        publishBuyTicketsAtDate
        expirationBuyTicketsAtDate
        logoImageId
        bannerImageId
        extraImage1Id
        extraImage2Id
        extraImage3Id
        extraImage4Id
        extraImage5Id
        yearFounded
        yearInauguralSeason
        numberOfTeams
        leagueInfoUrl
        leaguePromoVideoContentId
        currentSeason
        createdAt
        updatedAt
        streamingSportsAndLeaguesPageLeaguesId
        __typename
      }
      sport
      tags
      stats
      searchSink
      imageFilename
      blurHash
      createdBy
      updatedBy
      reservationArn
      reservationAuthorized
      reservationName
      reservationPlaybackURL
      reservationTags
      reservationType
      oldReservationArn
      liveToVodPlaybackURL
      errorStatus
      errorMessageForVideoUpload
      createdAt
      updatedAt
      __typename
    }
    applicationType
    authorId
    author {
      id
      fullName
      designation
      imageFilename
      imageURL
      blurHash
      createdBy
      updatedBy
      createdAt
      updatedAt
      __typename
    }
    photoCredit
    searchSink
    imageFilename
    blurHash
    createdAt
    updatedAt
    newsArticleRelatedArticlesId
    __typename
  }
}
` ; /* GeneratedMutation<
  APITypes.UpdateNewsArticleMutationVariables,
  APITypes.UpdateNewsArticleMutation
 ; */ 
exports.deleteNewsArticle = /* GraphQL */ `mutation DeleteNewsArticle(
  $input: DeleteNewsArticleInput!
  $condition: ModelNewsArticleConditionInput
) {
  deleteNewsArticle(input: $input, condition: $condition) {
    id
    title
    imageURL
    subtitle
    text
    summary
    dateTime
    releaseURL
    shortTitle
    tags
    leagueId
    league {
      id
      imageURL
      sport
      sportTypeTags
      region
      description
      title
      imageFilename
      blurHash
      createdBy
      updatedBy
      primaryColor
      secondaryColor
      textColorForPrimaryColor
      textColorForSecondaryColor
      backgroundColor
      textColorForBackgroundColor
      abbreviation
      fullName
      buyTicketsUrl
      publishBuyTicketsAtDate
      expirationBuyTicketsAtDate
      logoImageId
      logoImage {
        id
        name
        altText
        imageFilename
        blurHash
        description
        tags
        createdBy
        updatedBy
        searchSink
        createdAt
        updatedAt
        __typename
      }
      bannerImageId
      bannerImage {
        id
        name
        altText
        imageFilename
        blurHash
        description
        tags
        createdBy
        updatedBy
        searchSink
        createdAt
        updatedAt
        __typename
      }
      extraImage1Id
      extraImage1 {
        id
        name
        altText
        imageFilename
        blurHash
        description
        tags
        createdBy
        updatedBy
        searchSink
        createdAt
        updatedAt
        __typename
      }
      extraImage2Id
      extraImage2 {
        id
        name
        altText
        imageFilename
        blurHash
        description
        tags
        createdBy
        updatedBy
        searchSink
        createdAt
        updatedAt
        __typename
      }
      extraImage3Id
      extraImage3 {
        id
        name
        altText
        imageFilename
        blurHash
        description
        tags
        createdBy
        updatedBy
        searchSink
        createdAt
        updatedAt
        __typename
      }
      extraImage4Id
      extraImage4 {
        id
        name
        altText
        imageFilename
        blurHash
        description
        tags
        createdBy
        updatedBy
        searchSink
        createdAt
        updatedAt
        __typename
      }
      extraImage5Id
      extraImage5 {
        id
        name
        altText
        imageFilename
        blurHash
        description
        tags
        createdBy
        updatedBy
        searchSink
        createdAt
        updatedAt
        __typename
      }
      yearFounded
      yearInauguralSeason
      numberOfTeams
      leagueInfoUrl
      leaguePromoVideoContentId
      currentSeason
      createdAt
      updatedAt
      streamingSportsAndLeaguesPageLeaguesId
      __typename
    }
    sport
    sportTypeTags
    relatedArticles {
      items {
        id
        title
        imageURL
        subtitle
        text
        summary
        dateTime
        releaseURL
        shortTitle
        tags
        leagueId
        sport
        sportTypeTags
        stage
        publishedAt
        createdBy
        updatedBy
        frontendNewsMedium
        extraURL
        pinned
        contentId
        applicationType
        authorId
        photoCredit
        searchSink
        imageFilename
        blurHash
        createdAt
        updatedAt
        newsArticleRelatedArticlesId
        __typename
      }
      nextToken
      __typename
    }
    stage
    publishedAt
    createdBy
    updatedBy
    frontendNewsMedium
    extraURL
    pinned
    contentId
    content {
      id
      title
      description
      note
      video
      gameId
      watchList {
        nextToken
        __typename
      }
      game {
        id
        name
        description
        homeTeamId
        awayTeamId
        gameStartDateTime
        gameEndDateTime
        status
        tags
        seasonYear
        seasonType
        week
        fullWeek
        GB_LastScanStart
        GB_LastScanEnd
        quicksandStartIntervalSeconds
        quicksandEndIntervalSeconds
        sport
        leagueId
        nlseAssetHouseCode
        stats
        searchSink
        imageFilename
        blurHash
        createdBy
        updatedBy
        createdAt
        updatedAt
        programGamesId
        __typename
      }
      vodEntryId
      vodEntry {
        id
        body
        contentIdString
        dashUrl
        dashPlaylist
        hlsUrl
        hlsPlaylist
        stage
        publishedAt
        createdBy
        updatedBy
        errorStatus
        errorDescripton
        createdAt
        updatedAt
        __typename
      }
      programId
      program {
        id
        name
        description
        tvRating
        sport
        leagueId
        tags
        createdBy
        updatedBy
        imageFilename
        blurHash
        seasons
        publishAtDate
        expirationAtDate
        onlyShowEpisodesInAProgramAsOneItem
        availabilityStatus
        createdAt
        updatedAt
        __typename
      }
      tvRating
      contentType
      applicationType
      nlseAssetHouseCode
      episode
      episodeId
      episodeOrder
      episodeSeason
      isSupplementalContent
      supplementalContentOrder
      mainContentId
      publishAtDate
      expirationAtDate
      availabilityStatus
      contentLength
      seasonYear
      seasonType
      location
      leagueId
      league {
        id
        imageURL
        sport
        sportTypeTags
        region
        description
        title
        imageFilename
        blurHash
        createdBy
        updatedBy
        primaryColor
        secondaryColor
        textColorForPrimaryColor
        textColorForSecondaryColor
        backgroundColor
        textColorForBackgroundColor
        abbreviation
        fullName
        buyTicketsUrl
        publishBuyTicketsAtDate
        expirationBuyTicketsAtDate
        logoImageId
        bannerImageId
        extraImage1Id
        extraImage2Id
        extraImage3Id
        extraImage4Id
        extraImage5Id
        yearFounded
        yearInauguralSeason
        numberOfTeams
        leagueInfoUrl
        leaguePromoVideoContentId
        currentSeason
        createdAt
        updatedAt
        streamingSportsAndLeaguesPageLeaguesId
        __typename
      }
      sport
      tags
      stats
      searchSink
      imageFilename
      blurHash
      createdBy
      updatedBy
      reservationArn
      reservationAuthorized
      reservationName
      reservationPlaybackURL
      reservationTags
      reservationType
      oldReservationArn
      liveToVodPlaybackURL
      errorStatus
      errorMessageForVideoUpload
      createdAt
      updatedAt
      __typename
    }
    applicationType
    authorId
    author {
      id
      fullName
      designation
      imageFilename
      imageURL
      blurHash
      createdBy
      updatedBy
      createdAt
      updatedAt
      __typename
    }
    photoCredit
    searchSink
    imageFilename
    blurHash
    createdAt
    updatedAt
    newsArticleRelatedArticlesId
    __typename
  }
}
` ; /* GeneratedMutation<
  APITypes.DeleteNewsArticleMutationVariables,
  APITypes.DeleteNewsArticleMutation
 ; */ 
exports.createAuthor = /* GraphQL */ `mutation CreateAuthor(
  $input: CreateAuthorInput!
  $condition: ModelAuthorConditionInput
) {
  createAuthor(input: $input, condition: $condition) {
    id
    fullName
    designation
    imageFilename
    imageURL
    blurHash
    createdBy
    updatedBy
    createdAt
    updatedAt
    __typename
  }
}
` ; /* GeneratedMutation<
  APITypes.CreateAuthorMutationVariables,
  APITypes.CreateAuthorMutation
 ; */ 
exports.updateAuthor = /* GraphQL */ `mutation UpdateAuthor(
  $input: UpdateAuthorInput!
  $condition: ModelAuthorConditionInput
) {
  updateAuthor(input: $input, condition: $condition) {
    id
    fullName
    designation
    imageFilename
    imageURL
    blurHash
    createdBy
    updatedBy
    createdAt
    updatedAt
    __typename
  }
}
` ; /* GeneratedMutation<
  APITypes.UpdateAuthorMutationVariables,
  APITypes.UpdateAuthorMutation
 ; */ 
exports.deleteAuthor = /* GraphQL */ `mutation DeleteAuthor(
  $input: DeleteAuthorInput!
  $condition: ModelAuthorConditionInput
) {
  deleteAuthor(input: $input, condition: $condition) {
    id
    fullName
    designation
    imageFilename
    imageURL
    blurHash
    createdBy
    updatedBy
    createdAt
    updatedAt
    __typename
  }
}
` ; /* GeneratedMutation<
  APITypes.DeleteAuthorMutationVariables,
  APITypes.DeleteAuthorMutation
 ; */ 
exports.createTVScheduledEvent = /* GraphQL */ `mutation CreateTVScheduledEvent(
  $input: CreateTVScheduledEventInput!
  $condition: ModelTVScheduledEventConditionInput
) {
  createTVScheduledEvent(input: $input, condition: $condition) {
    id
    title
    description
    dateTime
    filterDateTime
    createdBy
    updatedBy
    version
    createdAt
    updatedAt
    __typename
  }
}
` ; /* GeneratedMutation<
  APITypes.CreateTVScheduledEventMutationVariables,
  APITypes.CreateTVScheduledEventMutation
 ; */ 
exports.updateTVScheduledEvent = /* GraphQL */ `mutation UpdateTVScheduledEvent(
  $input: UpdateTVScheduledEventInput!
  $condition: ModelTVScheduledEventConditionInput
) {
  updateTVScheduledEvent(input: $input, condition: $condition) {
    id
    title
    description
    dateTime
    filterDateTime
    createdBy
    updatedBy
    version
    createdAt
    updatedAt
    __typename
  }
}
` ; /* GeneratedMutation<
  APITypes.UpdateTVScheduledEventMutationVariables,
  APITypes.UpdateTVScheduledEventMutation
 ; */ 
exports.deleteTVScheduledEvent = /* GraphQL */ `mutation DeleteTVScheduledEvent(
  $input: DeleteTVScheduledEventInput!
  $condition: ModelTVScheduledEventConditionInput
) {
  deleteTVScheduledEvent(input: $input, condition: $condition) {
    id
    title
    description
    dateTime
    filterDateTime
    createdBy
    updatedBy
    version
    createdAt
    updatedAt
    __typename
  }
}
` ; /* GeneratedMutation<
  APITypes.DeleteTVScheduledEventMutationVariables,
  APITypes.DeleteTVScheduledEventMutation
 ; */ 
exports.createSportsTeam = /* GraphQL */ `mutation CreateSportsTeam(
  $input: CreateSportsTeamInput!
  $condition: ModelSportsTeamConditionInput
) {
  createSportsTeam(input: $input, condition: $condition) {
    id
    name
    sport
    leagueId
    league {
      id
      imageURL
      sport
      sportTypeTags
      region
      description
      title
      imageFilename
      blurHash
      createdBy
      updatedBy
      primaryColor
      secondaryColor
      textColorForPrimaryColor
      textColorForSecondaryColor
      backgroundColor
      textColorForBackgroundColor
      abbreviation
      fullName
      buyTicketsUrl
      publishBuyTicketsAtDate
      expirationBuyTicketsAtDate
      logoImageId
      logoImage {
        id
        name
        altText
        imageFilename
        blurHash
        description
        tags
        createdBy
        updatedBy
        searchSink
        createdAt
        updatedAt
        __typename
      }
      bannerImageId
      bannerImage {
        id
        name
        altText
        imageFilename
        blurHash
        description
        tags
        createdBy
        updatedBy
        searchSink
        createdAt
        updatedAt
        __typename
      }
      extraImage1Id
      extraImage1 {
        id
        name
        altText
        imageFilename
        blurHash
        description
        tags
        createdBy
        updatedBy
        searchSink
        createdAt
        updatedAt
        __typename
      }
      extraImage2Id
      extraImage2 {
        id
        name
        altText
        imageFilename
        blurHash
        description
        tags
        createdBy
        updatedBy
        searchSink
        createdAt
        updatedAt
        __typename
      }
      extraImage3Id
      extraImage3 {
        id
        name
        altText
        imageFilename
        blurHash
        description
        tags
        createdBy
        updatedBy
        searchSink
        createdAt
        updatedAt
        __typename
      }
      extraImage4Id
      extraImage4 {
        id
        name
        altText
        imageFilename
        blurHash
        description
        tags
        createdBy
        updatedBy
        searchSink
        createdAt
        updatedAt
        __typename
      }
      extraImage5Id
      extraImage5 {
        id
        name
        altText
        imageFilename
        blurHash
        description
        tags
        createdBy
        updatedBy
        searchSink
        createdAt
        updatedAt
        __typename
      }
      yearFounded
      yearInauguralSeason
      numberOfTeams
      leagueInfoUrl
      leaguePromoVideoContentId
      currentSeason
      createdAt
      updatedAt
      streamingSportsAndLeaguesPageLeaguesId
      __typename
    }
    location
    imageURL
    imageFilename
    blurHash
    createdBy
    updatedBy
    createdAt
    updatedAt
    __typename
  }
}
` ; /* GeneratedMutation<
  APITypes.CreateSportsTeamMutationVariables,
  APITypes.CreateSportsTeamMutation
 ; */ 
exports.updateSportsTeam = /* GraphQL */ `mutation UpdateSportsTeam(
  $input: UpdateSportsTeamInput!
  $condition: ModelSportsTeamConditionInput
) {
  updateSportsTeam(input: $input, condition: $condition) {
    id
    name
    sport
    leagueId
    league {
      id
      imageURL
      sport
      sportTypeTags
      region
      description
      title
      imageFilename
      blurHash
      createdBy
      updatedBy
      primaryColor
      secondaryColor
      textColorForPrimaryColor
      textColorForSecondaryColor
      backgroundColor
      textColorForBackgroundColor
      abbreviation
      fullName
      buyTicketsUrl
      publishBuyTicketsAtDate
      expirationBuyTicketsAtDate
      logoImageId
      logoImage {
        id
        name
        altText
        imageFilename
        blurHash
        description
        tags
        createdBy
        updatedBy
        searchSink
        createdAt
        updatedAt
        __typename
      }
      bannerImageId
      bannerImage {
        id
        name
        altText
        imageFilename
        blurHash
        description
        tags
        createdBy
        updatedBy
        searchSink
        createdAt
        updatedAt
        __typename
      }
      extraImage1Id
      extraImage1 {
        id
        name
        altText
        imageFilename
        blurHash
        description
        tags
        createdBy
        updatedBy
        searchSink
        createdAt
        updatedAt
        __typename
      }
      extraImage2Id
      extraImage2 {
        id
        name
        altText
        imageFilename
        blurHash
        description
        tags
        createdBy
        updatedBy
        searchSink
        createdAt
        updatedAt
        __typename
      }
      extraImage3Id
      extraImage3 {
        id
        name
        altText
        imageFilename
        blurHash
        description
        tags
        createdBy
        updatedBy
        searchSink
        createdAt
        updatedAt
        __typename
      }
      extraImage4Id
      extraImage4 {
        id
        name
        altText
        imageFilename
        blurHash
        description
        tags
        createdBy
        updatedBy
        searchSink
        createdAt
        updatedAt
        __typename
      }
      extraImage5Id
      extraImage5 {
        id
        name
        altText
        imageFilename
        blurHash
        description
        tags
        createdBy
        updatedBy
        searchSink
        createdAt
        updatedAt
        __typename
      }
      yearFounded
      yearInauguralSeason
      numberOfTeams
      leagueInfoUrl
      leaguePromoVideoContentId
      currentSeason
      createdAt
      updatedAt
      streamingSportsAndLeaguesPageLeaguesId
      __typename
    }
    location
    imageURL
    imageFilename
    blurHash
    createdBy
    updatedBy
    createdAt
    updatedAt
    __typename
  }
}
` ; /* GeneratedMutation<
  APITypes.UpdateSportsTeamMutationVariables,
  APITypes.UpdateSportsTeamMutation
 ; */ 
exports.deleteSportsTeam = /* GraphQL */ `mutation DeleteSportsTeam(
  $input: DeleteSportsTeamInput!
  $condition: ModelSportsTeamConditionInput
) {
  deleteSportsTeam(input: $input, condition: $condition) {
    id
    name
    sport
    leagueId
    league {
      id
      imageURL
      sport
      sportTypeTags
      region
      description
      title
      imageFilename
      blurHash
      createdBy
      updatedBy
      primaryColor
      secondaryColor
      textColorForPrimaryColor
      textColorForSecondaryColor
      backgroundColor
      textColorForBackgroundColor
      abbreviation
      fullName
      buyTicketsUrl
      publishBuyTicketsAtDate
      expirationBuyTicketsAtDate
      logoImageId
      logoImage {
        id
        name
        altText
        imageFilename
        blurHash
        description
        tags
        createdBy
        updatedBy
        searchSink
        createdAt
        updatedAt
        __typename
      }
      bannerImageId
      bannerImage {
        id
        name
        altText
        imageFilename
        blurHash
        description
        tags
        createdBy
        updatedBy
        searchSink
        createdAt
        updatedAt
        __typename
      }
      extraImage1Id
      extraImage1 {
        id
        name
        altText
        imageFilename
        blurHash
        description
        tags
        createdBy
        updatedBy
        searchSink
        createdAt
        updatedAt
        __typename
      }
      extraImage2Id
      extraImage2 {
        id
        name
        altText
        imageFilename
        blurHash
        description
        tags
        createdBy
        updatedBy
        searchSink
        createdAt
        updatedAt
        __typename
      }
      extraImage3Id
      extraImage3 {
        id
        name
        altText
        imageFilename
        blurHash
        description
        tags
        createdBy
        updatedBy
        searchSink
        createdAt
        updatedAt
        __typename
      }
      extraImage4Id
      extraImage4 {
        id
        name
        altText
        imageFilename
        blurHash
        description
        tags
        createdBy
        updatedBy
        searchSink
        createdAt
        updatedAt
        __typename
      }
      extraImage5Id
      extraImage5 {
        id
        name
        altText
        imageFilename
        blurHash
        description
        tags
        createdBy
        updatedBy
        searchSink
        createdAt
        updatedAt
        __typename
      }
      yearFounded
      yearInauguralSeason
      numberOfTeams
      leagueInfoUrl
      leaguePromoVideoContentId
      currentSeason
      createdAt
      updatedAt
      streamingSportsAndLeaguesPageLeaguesId
      __typename
    }
    location
    imageURL
    imageFilename
    blurHash
    createdBy
    updatedBy
    createdAt
    updatedAt
    __typename
  }
}
` ; /* GeneratedMutation<
  APITypes.DeleteSportsTeamMutationVariables,
  APITypes.DeleteSportsTeamMutation
 ; */ 
exports.createLeadershipTeamMember = /* GraphQL */ `mutation CreateLeadershipTeamMember(
  $input: CreateLeadershipTeamMemberInput!
  $condition: ModelLeadershipTeamMemberConditionInput
) {
  createLeadershipTeamMember(input: $input, condition: $condition) {
    id
    fullName
    jobTitle
    description
    imageURL
    createdBy
    updatedBy
    createdAt
    updatedAt
    __typename
  }
}
` ; /* GeneratedMutation<
  APITypes.CreateLeadershipTeamMemberMutationVariables,
  APITypes.CreateLeadershipTeamMemberMutation
 ; */ 
exports.updateLeadershipTeamMember = /* GraphQL */ `mutation UpdateLeadershipTeamMember(
  $input: UpdateLeadershipTeamMemberInput!
  $condition: ModelLeadershipTeamMemberConditionInput
) {
  updateLeadershipTeamMember(input: $input, condition: $condition) {
    id
    fullName
    jobTitle
    description
    imageURL
    createdBy
    updatedBy
    createdAt
    updatedAt
    __typename
  }
}
` ; /* GeneratedMutation<
  APITypes.UpdateLeadershipTeamMemberMutationVariables,
  APITypes.UpdateLeadershipTeamMemberMutation
 ; */ 
exports.deleteLeadershipTeamMember = /* GraphQL */ `mutation DeleteLeadershipTeamMember(
  $input: DeleteLeadershipTeamMemberInput!
  $condition: ModelLeadershipTeamMemberConditionInput
) {
  deleteLeadershipTeamMember(input: $input, condition: $condition) {
    id
    fullName
    jobTitle
    description
    imageURL
    createdBy
    updatedBy
    createdAt
    updatedAt
    __typename
  }
}
` ; /* GeneratedMutation<
  APITypes.DeleteLeadershipTeamMemberMutationVariables,
  APITypes.DeleteLeadershipTeamMemberMutation
 ; */ 
exports.createLibraryImage = /* GraphQL */ `mutation CreateLibraryImage(
  $input: CreateLibraryImageInput!
  $condition: ModelLibraryImageConditionInput
) {
  createLibraryImage(input: $input, condition: $condition) {
    id
    name
    altText
    imageFilename
    blurHash
    description
    tags
    createdBy
    updatedBy
    searchSink
    createdAt
    updatedAt
    __typename
  }
}
` ; /* GeneratedMutation<
  APITypes.CreateLibraryImageMutationVariables,
  APITypes.CreateLibraryImageMutation
 ; */ 
exports.updateLibraryImage = /* GraphQL */ `mutation UpdateLibraryImage(
  $input: UpdateLibraryImageInput!
  $condition: ModelLibraryImageConditionInput
) {
  updateLibraryImage(input: $input, condition: $condition) {
    id
    name
    altText
    imageFilename
    blurHash
    description
    tags
    createdBy
    updatedBy
    searchSink
    createdAt
    updatedAt
    __typename
  }
}
` ; /* GeneratedMutation<
  APITypes.UpdateLibraryImageMutationVariables,
  APITypes.UpdateLibraryImageMutation
 ; */ 
exports.deleteLibraryImage = /* GraphQL */ `mutation DeleteLibraryImage(
  $input: DeleteLibraryImageInput!
  $condition: ModelLibraryImageConditionInput
) {
  deleteLibraryImage(input: $input, condition: $condition) {
    id
    name
    altText
    imageFilename
    blurHash
    description
    tags
    createdBy
    updatedBy
    searchSink
    createdAt
    updatedAt
    __typename
  }
}
` ; /* GeneratedMutation<
  APITypes.DeleteLibraryImageMutationVariables,
  APITypes.DeleteLibraryImageMutation
 ; */ 
exports.createBanner = /* GraphQL */ `mutation CreateBanner(
  $input: CreateBannerInput!
  $condition: ModelBannerConditionInput
) {
  createBanner(input: $input, condition: $condition) {
    imageId
    image {
      id
      name
      altText
      imageFilename
      blurHash
      description
      tags
      createdBy
      updatedBy
      searchSink
      createdAt
      updatedAt
      __typename
    }
    title
    description
    layout
    tags
    buttonUrl
    buttonText
    sport
    leagueId
    league {
      id
      imageURL
      sport
      sportTypeTags
      region
      description
      title
      imageFilename
      blurHash
      createdBy
      updatedBy
      primaryColor
      secondaryColor
      textColorForPrimaryColor
      textColorForSecondaryColor
      backgroundColor
      textColorForBackgroundColor
      abbreviation
      fullName
      buyTicketsUrl
      publishBuyTicketsAtDate
      expirationBuyTicketsAtDate
      logoImageId
      logoImage {
        id
        name
        altText
        imageFilename
        blurHash
        description
        tags
        createdBy
        updatedBy
        searchSink
        createdAt
        updatedAt
        __typename
      }
      bannerImageId
      bannerImage {
        id
        name
        altText
        imageFilename
        blurHash
        description
        tags
        createdBy
        updatedBy
        searchSink
        createdAt
        updatedAt
        __typename
      }
      extraImage1Id
      extraImage1 {
        id
        name
        altText
        imageFilename
        blurHash
        description
        tags
        createdBy
        updatedBy
        searchSink
        createdAt
        updatedAt
        __typename
      }
      extraImage2Id
      extraImage2 {
        id
        name
        altText
        imageFilename
        blurHash
        description
        tags
        createdBy
        updatedBy
        searchSink
        createdAt
        updatedAt
        __typename
      }
      extraImage3Id
      extraImage3 {
        id
        name
        altText
        imageFilename
        blurHash
        description
        tags
        createdBy
        updatedBy
        searchSink
        createdAt
        updatedAt
        __typename
      }
      extraImage4Id
      extraImage4 {
        id
        name
        altText
        imageFilename
        blurHash
        description
        tags
        createdBy
        updatedBy
        searchSink
        createdAt
        updatedAt
        __typename
      }
      extraImage5Id
      extraImage5 {
        id
        name
        altText
        imageFilename
        blurHash
        description
        tags
        createdBy
        updatedBy
        searchSink
        createdAt
        updatedAt
        __typename
      }
      yearFounded
      yearInauguralSeason
      numberOfTeams
      leagueInfoUrl
      leaguePromoVideoContentId
      currentSeason
      createdAt
      updatedAt
      streamingSportsAndLeaguesPageLeaguesId
      __typename
    }
    id
    createdAt
    updatedAt
    __typename
  }
}
` ; /* GeneratedMutation<
  APITypes.CreateBannerMutationVariables,
  APITypes.CreateBannerMutation
 ; */ 
exports.updateBanner = /* GraphQL */ `mutation UpdateBanner(
  $input: UpdateBannerInput!
  $condition: ModelBannerConditionInput
) {
  updateBanner(input: $input, condition: $condition) {
    imageId
    image {
      id
      name
      altText
      imageFilename
      blurHash
      description
      tags
      createdBy
      updatedBy
      searchSink
      createdAt
      updatedAt
      __typename
    }
    title
    description
    layout
    tags
    buttonUrl
    buttonText
    sport
    leagueId
    league {
      id
      imageURL
      sport
      sportTypeTags
      region
      description
      title
      imageFilename
      blurHash
      createdBy
      updatedBy
      primaryColor
      secondaryColor
      textColorForPrimaryColor
      textColorForSecondaryColor
      backgroundColor
      textColorForBackgroundColor
      abbreviation
      fullName
      buyTicketsUrl
      publishBuyTicketsAtDate
      expirationBuyTicketsAtDate
      logoImageId
      logoImage {
        id
        name
        altText
        imageFilename
        blurHash
        description
        tags
        createdBy
        updatedBy
        searchSink
        createdAt
        updatedAt
        __typename
      }
      bannerImageId
      bannerImage {
        id
        name
        altText
        imageFilename
        blurHash
        description
        tags
        createdBy
        updatedBy
        searchSink
        createdAt
        updatedAt
        __typename
      }
      extraImage1Id
      extraImage1 {
        id
        name
        altText
        imageFilename
        blurHash
        description
        tags
        createdBy
        updatedBy
        searchSink
        createdAt
        updatedAt
        __typename
      }
      extraImage2Id
      extraImage2 {
        id
        name
        altText
        imageFilename
        blurHash
        description
        tags
        createdBy
        updatedBy
        searchSink
        createdAt
        updatedAt
        __typename
      }
      extraImage3Id
      extraImage3 {
        id
        name
        altText
        imageFilename
        blurHash
        description
        tags
        createdBy
        updatedBy
        searchSink
        createdAt
        updatedAt
        __typename
      }
      extraImage4Id
      extraImage4 {
        id
        name
        altText
        imageFilename
        blurHash
        description
        tags
        createdBy
        updatedBy
        searchSink
        createdAt
        updatedAt
        __typename
      }
      extraImage5Id
      extraImage5 {
        id
        name
        altText
        imageFilename
        blurHash
        description
        tags
        createdBy
        updatedBy
        searchSink
        createdAt
        updatedAt
        __typename
      }
      yearFounded
      yearInauguralSeason
      numberOfTeams
      leagueInfoUrl
      leaguePromoVideoContentId
      currentSeason
      createdAt
      updatedAt
      streamingSportsAndLeaguesPageLeaguesId
      __typename
    }
    id
    createdAt
    updatedAt
    __typename
  }
}
` ; /* GeneratedMutation<
  APITypes.UpdateBannerMutationVariables,
  APITypes.UpdateBannerMutation
 ; */ 
exports.deleteBanner = /* GraphQL */ `mutation DeleteBanner(
  $input: DeleteBannerInput!
  $condition: ModelBannerConditionInput
) {
  deleteBanner(input: $input, condition: $condition) {
    imageId
    image {
      id
      name
      altText
      imageFilename
      blurHash
      description
      tags
      createdBy
      updatedBy
      searchSink
      createdAt
      updatedAt
      __typename
    }
    title
    description
    layout
    tags
    buttonUrl
    buttonText
    sport
    leagueId
    league {
      id
      imageURL
      sport
      sportTypeTags
      region
      description
      title
      imageFilename
      blurHash
      createdBy
      updatedBy
      primaryColor
      secondaryColor
      textColorForPrimaryColor
      textColorForSecondaryColor
      backgroundColor
      textColorForBackgroundColor
      abbreviation
      fullName
      buyTicketsUrl
      publishBuyTicketsAtDate
      expirationBuyTicketsAtDate
      logoImageId
      logoImage {
        id
        name
        altText
        imageFilename
        blurHash
        description
        tags
        createdBy
        updatedBy
        searchSink
        createdAt
        updatedAt
        __typename
      }
      bannerImageId
      bannerImage {
        id
        name
        altText
        imageFilename
        blurHash
        description
        tags
        createdBy
        updatedBy
        searchSink
        createdAt
        updatedAt
        __typename
      }
      extraImage1Id
      extraImage1 {
        id
        name
        altText
        imageFilename
        blurHash
        description
        tags
        createdBy
        updatedBy
        searchSink
        createdAt
        updatedAt
        __typename
      }
      extraImage2Id
      extraImage2 {
        id
        name
        altText
        imageFilename
        blurHash
        description
        tags
        createdBy
        updatedBy
        searchSink
        createdAt
        updatedAt
        __typename
      }
      extraImage3Id
      extraImage3 {
        id
        name
        altText
        imageFilename
        blurHash
        description
        tags
        createdBy
        updatedBy
        searchSink
        createdAt
        updatedAt
        __typename
      }
      extraImage4Id
      extraImage4 {
        id
        name
        altText
        imageFilename
        blurHash
        description
        tags
        createdBy
        updatedBy
        searchSink
        createdAt
        updatedAt
        __typename
      }
      extraImage5Id
      extraImage5 {
        id
        name
        altText
        imageFilename
        blurHash
        description
        tags
        createdBy
        updatedBy
        searchSink
        createdAt
        updatedAt
        __typename
      }
      yearFounded
      yearInauguralSeason
      numberOfTeams
      leagueInfoUrl
      leaguePromoVideoContentId
      currentSeason
      createdAt
      updatedAt
      streamingSportsAndLeaguesPageLeaguesId
      __typename
    }
    id
    createdAt
    updatedAt
    __typename
  }
}
` ; /* GeneratedMutation<
  APITypes.DeleteBannerMutationVariables,
  APITypes.DeleteBannerMutation
 ; */ 
exports.createHomePageLeaguesRow = /* GraphQL */ `mutation CreateHomePageLeaguesRow(
  $input: CreateHomePageLeaguesRowInput!
  $condition: ModelHomePageLeaguesRowConditionInput
) {
  createHomePageLeaguesRow(input: $input, condition: $condition) {
    id
    order
    leagueId
    league {
      id
      imageURL
      sport
      sportTypeTags
      region
      description
      title
      imageFilename
      blurHash
      createdBy
      updatedBy
      primaryColor
      secondaryColor
      textColorForPrimaryColor
      textColorForSecondaryColor
      backgroundColor
      textColorForBackgroundColor
      abbreviation
      fullName
      buyTicketsUrl
      publishBuyTicketsAtDate
      expirationBuyTicketsAtDate
      logoImageId
      logoImage {
        id
        name
        altText
        imageFilename
        blurHash
        description
        tags
        createdBy
        updatedBy
        searchSink
        createdAt
        updatedAt
        __typename
      }
      bannerImageId
      bannerImage {
        id
        name
        altText
        imageFilename
        blurHash
        description
        tags
        createdBy
        updatedBy
        searchSink
        createdAt
        updatedAt
        __typename
      }
      extraImage1Id
      extraImage1 {
        id
        name
        altText
        imageFilename
        blurHash
        description
        tags
        createdBy
        updatedBy
        searchSink
        createdAt
        updatedAt
        __typename
      }
      extraImage2Id
      extraImage2 {
        id
        name
        altText
        imageFilename
        blurHash
        description
        tags
        createdBy
        updatedBy
        searchSink
        createdAt
        updatedAt
        __typename
      }
      extraImage3Id
      extraImage3 {
        id
        name
        altText
        imageFilename
        blurHash
        description
        tags
        createdBy
        updatedBy
        searchSink
        createdAt
        updatedAt
        __typename
      }
      extraImage4Id
      extraImage4 {
        id
        name
        altText
        imageFilename
        blurHash
        description
        tags
        createdBy
        updatedBy
        searchSink
        createdAt
        updatedAt
        __typename
      }
      extraImage5Id
      extraImage5 {
        id
        name
        altText
        imageFilename
        blurHash
        description
        tags
        createdBy
        updatedBy
        searchSink
        createdAt
        updatedAt
        __typename
      }
      yearFounded
      yearInauguralSeason
      numberOfTeams
      leagueInfoUrl
      leaguePromoVideoContentId
      currentSeason
      createdAt
      updatedAt
      streamingSportsAndLeaguesPageLeaguesId
      __typename
    }
    createdBy
    updatedBy
    createdAt
    updatedAt
    __typename
  }
}
` ; /* GeneratedMutation<
  APITypes.CreateHomePageLeaguesRowMutationVariables,
  APITypes.CreateHomePageLeaguesRowMutation
 ; */ 
exports.updateHomePageLeaguesRow = /* GraphQL */ `mutation UpdateHomePageLeaguesRow(
  $input: UpdateHomePageLeaguesRowInput!
  $condition: ModelHomePageLeaguesRowConditionInput
) {
  updateHomePageLeaguesRow(input: $input, condition: $condition) {
    id
    order
    leagueId
    league {
      id
      imageURL
      sport
      sportTypeTags
      region
      description
      title
      imageFilename
      blurHash
      createdBy
      updatedBy
      primaryColor
      secondaryColor
      textColorForPrimaryColor
      textColorForSecondaryColor
      backgroundColor
      textColorForBackgroundColor
      abbreviation
      fullName
      buyTicketsUrl
      publishBuyTicketsAtDate
      expirationBuyTicketsAtDate
      logoImageId
      logoImage {
        id
        name
        altText
        imageFilename
        blurHash
        description
        tags
        createdBy
        updatedBy
        searchSink
        createdAt
        updatedAt
        __typename
      }
      bannerImageId
      bannerImage {
        id
        name
        altText
        imageFilename
        blurHash
        description
        tags
        createdBy
        updatedBy
        searchSink
        createdAt
        updatedAt
        __typename
      }
      extraImage1Id
      extraImage1 {
        id
        name
        altText
        imageFilename
        blurHash
        description
        tags
        createdBy
        updatedBy
        searchSink
        createdAt
        updatedAt
        __typename
      }
      extraImage2Id
      extraImage2 {
        id
        name
        altText
        imageFilename
        blurHash
        description
        tags
        createdBy
        updatedBy
        searchSink
        createdAt
        updatedAt
        __typename
      }
      extraImage3Id
      extraImage3 {
        id
        name
        altText
        imageFilename
        blurHash
        description
        tags
        createdBy
        updatedBy
        searchSink
        createdAt
        updatedAt
        __typename
      }
      extraImage4Id
      extraImage4 {
        id
        name
        altText
        imageFilename
        blurHash
        description
        tags
        createdBy
        updatedBy
        searchSink
        createdAt
        updatedAt
        __typename
      }
      extraImage5Id
      extraImage5 {
        id
        name
        altText
        imageFilename
        blurHash
        description
        tags
        createdBy
        updatedBy
        searchSink
        createdAt
        updatedAt
        __typename
      }
      yearFounded
      yearInauguralSeason
      numberOfTeams
      leagueInfoUrl
      leaguePromoVideoContentId
      currentSeason
      createdAt
      updatedAt
      streamingSportsAndLeaguesPageLeaguesId
      __typename
    }
    createdBy
    updatedBy
    createdAt
    updatedAt
    __typename
  }
}
` ; /* GeneratedMutation<
  APITypes.UpdateHomePageLeaguesRowMutationVariables,
  APITypes.UpdateHomePageLeaguesRowMutation
 ; */ 
exports.deleteHomePageLeaguesRow = /* GraphQL */ `mutation DeleteHomePageLeaguesRow(
  $input: DeleteHomePageLeaguesRowInput!
  $condition: ModelHomePageLeaguesRowConditionInput
) {
  deleteHomePageLeaguesRow(input: $input, condition: $condition) {
    id
    order
    leagueId
    league {
      id
      imageURL
      sport
      sportTypeTags
      region
      description
      title
      imageFilename
      blurHash
      createdBy
      updatedBy
      primaryColor
      secondaryColor
      textColorForPrimaryColor
      textColorForSecondaryColor
      backgroundColor
      textColorForBackgroundColor
      abbreviation
      fullName
      buyTicketsUrl
      publishBuyTicketsAtDate
      expirationBuyTicketsAtDate
      logoImageId
      logoImage {
        id
        name
        altText
        imageFilename
        blurHash
        description
        tags
        createdBy
        updatedBy
        searchSink
        createdAt
        updatedAt
        __typename
      }
      bannerImageId
      bannerImage {
        id
        name
        altText
        imageFilename
        blurHash
        description
        tags
        createdBy
        updatedBy
        searchSink
        createdAt
        updatedAt
        __typename
      }
      extraImage1Id
      extraImage1 {
        id
        name
        altText
        imageFilename
        blurHash
        description
        tags
        createdBy
        updatedBy
        searchSink
        createdAt
        updatedAt
        __typename
      }
      extraImage2Id
      extraImage2 {
        id
        name
        altText
        imageFilename
        blurHash
        description
        tags
        createdBy
        updatedBy
        searchSink
        createdAt
        updatedAt
        __typename
      }
      extraImage3Id
      extraImage3 {
        id
        name
        altText
        imageFilename
        blurHash
        description
        tags
        createdBy
        updatedBy
        searchSink
        createdAt
        updatedAt
        __typename
      }
      extraImage4Id
      extraImage4 {
        id
        name
        altText
        imageFilename
        blurHash
        description
        tags
        createdBy
        updatedBy
        searchSink
        createdAt
        updatedAt
        __typename
      }
      extraImage5Id
      extraImage5 {
        id
        name
        altText
        imageFilename
        blurHash
        description
        tags
        createdBy
        updatedBy
        searchSink
        createdAt
        updatedAt
        __typename
      }
      yearFounded
      yearInauguralSeason
      numberOfTeams
      leagueInfoUrl
      leaguePromoVideoContentId
      currentSeason
      createdAt
      updatedAt
      streamingSportsAndLeaguesPageLeaguesId
      __typename
    }
    createdBy
    updatedBy
    createdAt
    updatedAt
    __typename
  }
}
` ; /* GeneratedMutation<
  APITypes.DeleteHomePageLeaguesRowMutationVariables,
  APITypes.DeleteHomePageLeaguesRowMutation
 ; */ 
exports.createUserContentWatchList = /* GraphQL */ `mutation CreateUserContentWatchList(
  $input: CreateUserContentWatchListInput!
  $condition: ModelUserContentWatchListConditionInput
) {
  createUserContentWatchList(input: $input, condition: $condition) {
    id
    userID
    contentID
    user {
      id
      name
      email
      createdAt
      updatedAt
      watchList {
        nextToken
        __typename
      }
      watchProgresses {
        nextToken
        __typename
      }
      notifications
      appSettingsPreferences
      stats
      __typename
    }
    content {
      id
      title
      description
      note
      video
      gameId
      watchList {
        nextToken
        __typename
      }
      game {
        id
        name
        description
        homeTeamId
        awayTeamId
        gameStartDateTime
        gameEndDateTime
        status
        tags
        seasonYear
        seasonType
        week
        fullWeek
        GB_LastScanStart
        GB_LastScanEnd
        quicksandStartIntervalSeconds
        quicksandEndIntervalSeconds
        sport
        leagueId
        nlseAssetHouseCode
        stats
        searchSink
        imageFilename
        blurHash
        createdBy
        updatedBy
        createdAt
        updatedAt
        programGamesId
        __typename
      }
      vodEntryId
      vodEntry {
        id
        body
        contentIdString
        dashUrl
        dashPlaylist
        hlsUrl
        hlsPlaylist
        stage
        publishedAt
        createdBy
        updatedBy
        errorStatus
        errorDescripton
        createdAt
        updatedAt
        __typename
      }
      programId
      program {
        id
        name
        description
        tvRating
        sport
        leagueId
        tags
        createdBy
        updatedBy
        imageFilename
        blurHash
        seasons
        publishAtDate
        expirationAtDate
        onlyShowEpisodesInAProgramAsOneItem
        availabilityStatus
        createdAt
        updatedAt
        __typename
      }
      tvRating
      contentType
      applicationType
      nlseAssetHouseCode
      episode
      episodeId
      episodeOrder
      episodeSeason
      isSupplementalContent
      supplementalContentOrder
      mainContentId
      publishAtDate
      expirationAtDate
      availabilityStatus
      contentLength
      seasonYear
      seasonType
      location
      leagueId
      league {
        id
        imageURL
        sport
        sportTypeTags
        region
        description
        title
        imageFilename
        blurHash
        createdBy
        updatedBy
        primaryColor
        secondaryColor
        textColorForPrimaryColor
        textColorForSecondaryColor
        backgroundColor
        textColorForBackgroundColor
        abbreviation
        fullName
        buyTicketsUrl
        publishBuyTicketsAtDate
        expirationBuyTicketsAtDate
        logoImageId
        bannerImageId
        extraImage1Id
        extraImage2Id
        extraImage3Id
        extraImage4Id
        extraImage5Id
        yearFounded
        yearInauguralSeason
        numberOfTeams
        leagueInfoUrl
        leaguePromoVideoContentId
        currentSeason
        createdAt
        updatedAt
        streamingSportsAndLeaguesPageLeaguesId
        __typename
      }
      sport
      tags
      stats
      searchSink
      imageFilename
      blurHash
      createdBy
      updatedBy
      reservationArn
      reservationAuthorized
      reservationName
      reservationPlaybackURL
      reservationTags
      reservationType
      oldReservationArn
      liveToVodPlaybackURL
      errorStatus
      errorMessageForVideoUpload
      createdAt
      updatedAt
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` ; /* GeneratedMutation<
  APITypes.CreateUserContentWatchListMutationVariables,
  APITypes.CreateUserContentWatchListMutation
 ; */ 
exports.updateUserContentWatchList = /* GraphQL */ `mutation UpdateUserContentWatchList(
  $input: UpdateUserContentWatchListInput!
  $condition: ModelUserContentWatchListConditionInput
) {
  updateUserContentWatchList(input: $input, condition: $condition) {
    id
    userID
    contentID
    user {
      id
      name
      email
      createdAt
      updatedAt
      watchList {
        nextToken
        __typename
      }
      watchProgresses {
        nextToken
        __typename
      }
      notifications
      appSettingsPreferences
      stats
      __typename
    }
    content {
      id
      title
      description
      note
      video
      gameId
      watchList {
        nextToken
        __typename
      }
      game {
        id
        name
        description
        homeTeamId
        awayTeamId
        gameStartDateTime
        gameEndDateTime
        status
        tags
        seasonYear
        seasonType
        week
        fullWeek
        GB_LastScanStart
        GB_LastScanEnd
        quicksandStartIntervalSeconds
        quicksandEndIntervalSeconds
        sport
        leagueId
        nlseAssetHouseCode
        stats
        searchSink
        imageFilename
        blurHash
        createdBy
        updatedBy
        createdAt
        updatedAt
        programGamesId
        __typename
      }
      vodEntryId
      vodEntry {
        id
        body
        contentIdString
        dashUrl
        dashPlaylist
        hlsUrl
        hlsPlaylist
        stage
        publishedAt
        createdBy
        updatedBy
        errorStatus
        errorDescripton
        createdAt
        updatedAt
        __typename
      }
      programId
      program {
        id
        name
        description
        tvRating
        sport
        leagueId
        tags
        createdBy
        updatedBy
        imageFilename
        blurHash
        seasons
        publishAtDate
        expirationAtDate
        onlyShowEpisodesInAProgramAsOneItem
        availabilityStatus
        createdAt
        updatedAt
        __typename
      }
      tvRating
      contentType
      applicationType
      nlseAssetHouseCode
      episode
      episodeId
      episodeOrder
      episodeSeason
      isSupplementalContent
      supplementalContentOrder
      mainContentId
      publishAtDate
      expirationAtDate
      availabilityStatus
      contentLength
      seasonYear
      seasonType
      location
      leagueId
      league {
        id
        imageURL
        sport
        sportTypeTags
        region
        description
        title
        imageFilename
        blurHash
        createdBy
        updatedBy
        primaryColor
        secondaryColor
        textColorForPrimaryColor
        textColorForSecondaryColor
        backgroundColor
        textColorForBackgroundColor
        abbreviation
        fullName
        buyTicketsUrl
        publishBuyTicketsAtDate
        expirationBuyTicketsAtDate
        logoImageId
        bannerImageId
        extraImage1Id
        extraImage2Id
        extraImage3Id
        extraImage4Id
        extraImage5Id
        yearFounded
        yearInauguralSeason
        numberOfTeams
        leagueInfoUrl
        leaguePromoVideoContentId
        currentSeason
        createdAt
        updatedAt
        streamingSportsAndLeaguesPageLeaguesId
        __typename
      }
      sport
      tags
      stats
      searchSink
      imageFilename
      blurHash
      createdBy
      updatedBy
      reservationArn
      reservationAuthorized
      reservationName
      reservationPlaybackURL
      reservationTags
      reservationType
      oldReservationArn
      liveToVodPlaybackURL
      errorStatus
      errorMessageForVideoUpload
      createdAt
      updatedAt
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` ; /* GeneratedMutation<
  APITypes.UpdateUserContentWatchListMutationVariables,
  APITypes.UpdateUserContentWatchListMutation
 ; */ 
exports.deleteUserContentWatchList = /* GraphQL */ `mutation DeleteUserContentWatchList(
  $input: DeleteUserContentWatchListInput!
  $condition: ModelUserContentWatchListConditionInput
) {
  deleteUserContentWatchList(input: $input, condition: $condition) {
    id
    userID
    contentID
    user {
      id
      name
      email
      createdAt
      updatedAt
      watchList {
        nextToken
        __typename
      }
      watchProgresses {
        nextToken
        __typename
      }
      notifications
      appSettingsPreferences
      stats
      __typename
    }
    content {
      id
      title
      description
      note
      video
      gameId
      watchList {
        nextToken
        __typename
      }
      game {
        id
        name
        description
        homeTeamId
        awayTeamId
        gameStartDateTime
        gameEndDateTime
        status
        tags
        seasonYear
        seasonType
        week
        fullWeek
        GB_LastScanStart
        GB_LastScanEnd
        quicksandStartIntervalSeconds
        quicksandEndIntervalSeconds
        sport
        leagueId
        nlseAssetHouseCode
        stats
        searchSink
        imageFilename
        blurHash
        createdBy
        updatedBy
        createdAt
        updatedAt
        programGamesId
        __typename
      }
      vodEntryId
      vodEntry {
        id
        body
        contentIdString
        dashUrl
        dashPlaylist
        hlsUrl
        hlsPlaylist
        stage
        publishedAt
        createdBy
        updatedBy
        errorStatus
        errorDescripton
        createdAt
        updatedAt
        __typename
      }
      programId
      program {
        id
        name
        description
        tvRating
        sport
        leagueId
        tags
        createdBy
        updatedBy
        imageFilename
        blurHash
        seasons
        publishAtDate
        expirationAtDate
        onlyShowEpisodesInAProgramAsOneItem
        availabilityStatus
        createdAt
        updatedAt
        __typename
      }
      tvRating
      contentType
      applicationType
      nlseAssetHouseCode
      episode
      episodeId
      episodeOrder
      episodeSeason
      isSupplementalContent
      supplementalContentOrder
      mainContentId
      publishAtDate
      expirationAtDate
      availabilityStatus
      contentLength
      seasonYear
      seasonType
      location
      leagueId
      league {
        id
        imageURL
        sport
        sportTypeTags
        region
        description
        title
        imageFilename
        blurHash
        createdBy
        updatedBy
        primaryColor
        secondaryColor
        textColorForPrimaryColor
        textColorForSecondaryColor
        backgroundColor
        textColorForBackgroundColor
        abbreviation
        fullName
        buyTicketsUrl
        publishBuyTicketsAtDate
        expirationBuyTicketsAtDate
        logoImageId
        bannerImageId
        extraImage1Id
        extraImage2Id
        extraImage3Id
        extraImage4Id
        extraImage5Id
        yearFounded
        yearInauguralSeason
        numberOfTeams
        leagueInfoUrl
        leaguePromoVideoContentId
        currentSeason
        createdAt
        updatedAt
        streamingSportsAndLeaguesPageLeaguesId
        __typename
      }
      sport
      tags
      stats
      searchSink
      imageFilename
      blurHash
      createdBy
      updatedBy
      reservationArn
      reservationAuthorized
      reservationName
      reservationPlaybackURL
      reservationTags
      reservationType
      oldReservationArn
      liveToVodPlaybackURL
      errorStatus
      errorMessageForVideoUpload
      createdAt
      updatedAt
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` ; /* GeneratedMutation<
  APITypes.DeleteUserContentWatchListMutationVariables,
  APITypes.DeleteUserContentWatchListMutation
 ; */ 
exports.vodMultipartUploadPresignedUrl = /* GraphQL */ `mutation VodMultipartUploadPresignedUrl($filename: String!, $numParts: Int!) {
  vodMultipartUploadPresignedUrl(filename: $filename, numParts: $numParts) {
    uploadId
    urls
    __typename
  }
}
` ; /* GeneratedMutation<
  APITypes.VodMultipartUploadPresignedUrlMutationVariables,
  APITypes.VodMultipartUploadPresignedUrlMutation
 ; */ 
exports.vodUploadPresignedUrl = /* GraphQL */ `mutation VodUploadPresignedUrl($filename: String!, $bucketName: String) {
  vodUploadPresignedUrl(filename: $filename, bucketName: $bucketName)
}
` ; /* GeneratedMutation<
  APITypes.VodUploadPresignedUrlMutationVariables,
  APITypes.VodUploadPresignedUrlMutation
 ; */ 
exports.completeVideoUpload = /* GraphQL */ `mutation CompleteVideoUpload($input: CompleteVideoUploadInput) {
  completeVideoUpload(input: $input)
}
` ; /* GeneratedMutation<
  APITypes.CompleteVideoUploadMutationVariables,
  APITypes.CompleteVideoUploadMutation
 ; */ 
exports.deleteVideo = /* GraphQL */ `mutation DeleteVideo($videoId: ID!) {
  deleteVideo(videoId: $videoId)
}
` ; /* GeneratedMutation<
  APITypes.DeleteVideoMutationVariables,
  APITypes.DeleteVideoMutation
 ; */ 
exports.checkEmailIsUsed = /* GraphQL */ `mutation CheckEmailIsUsed($email: AWSEmail) {
  checkEmailIsUsed(email: $email)
}
` ; /* GeneratedMutation<
  APITypes.CheckEmailIsUsedMutationVariables,
  APITypes.CheckEmailIsUsedMutation
 ; */ 
exports.testConfirmUser = /* GraphQL */ `mutation TestConfirmUser($username: String) {
  testConfirmUser(username: $username)
}
` ; /* GeneratedMutation<
  APITypes.TestConfirmUserMutationVariables,
  APITypes.TestConfirmUserMutation
 ; */ 
exports.deleteCognitoAndDBUser = /* GraphQL */ `mutation DeleteCognitoAndDBUser($username: ID!) {
  deleteCognitoAndDBUser(username: $username)
}
` ; /* GeneratedMutation<
  APITypes.DeleteCognitoAndDBUserMutationVariables,
  APITypes.DeleteCognitoAndDBUserMutation
 ; */ 
exports.createChannel = /* GraphQL */ `mutation CreateChannel(
  $channelName: String!
  $resolution: ChannelResolution
  $inputType: InputType
  $contentId: ID!
  $scheduledStartTime: AWSDateTime
) {
  createChannel(
    channelName: $channelName
    resolution: $resolution
    inputType: $inputType
    contentId: $contentId
    scheduledStartTime: $scheduledStartTime
  )
}
` ; /* GeneratedMutation<
  APITypes.CreateChannelMutationVariables,
  APITypes.CreateChannelMutation
 ; */ 
exports.deleteChannel = /* GraphQL */ `mutation DeleteChannel($channelId: ID!) {
  deleteChannel(channelId: $channelId)
}
` ; /* GeneratedMutation<
  APITypes.DeleteChannelMutationVariables,
  APITypes.DeleteChannelMutation
 ; */ 
exports.startChannel = /* GraphQL */ `mutation StartChannel($channelId: ID!) {
  startChannel(channelId: $channelId)
}
` ; /* GeneratedMutation<
  APITypes.StartChannelMutationVariables,
  APITypes.StartChannelMutation
 ; */ 
exports.stopChannel = /* GraphQL */ `mutation StopChannel($channelId: ID!) {
  stopChannel(channelId: $channelId)
}
` ; /* GeneratedMutation<
  APITypes.StopChannelMutationVariables,
  APITypes.StopChannelMutation
 ; */ 
exports.createIVSReservation = /* GraphQL */ `mutation CreateIVSReservation(
  $authorized: Boolean
  $insecureIngest: Boolean
  $latencyMode: IVSLatencyMode
  $name: String!
  $type: IVSType
  $contentId: ID!
) {
  createIVSReservation(
    authorized: $authorized
    insecureIngest: $insecureIngest
    latencyMode: $latencyMode
    name: $name
    type: $type
    contentId: $contentId
  )
}
` ; /* GeneratedMutation<
  APITypes.CreateIVSReservationMutationVariables,
  APITypes.CreateIVSReservationMutation
 ; */ 
exports.getIVSReservation = /* GraphQL */ `mutation GetIVSReservation($arn: String, $name: String) {
  getIVSReservation(arn: $arn, name: $name) {
    arn
    name
    playbackUrl
    tags
    firstStreamStartTime
    streamSessions {
      streamId
      startTime
      endTime
      hasErrorEvent
      __typename
    }
    __typename
  }
}
` ; /* GeneratedMutation<
  APITypes.GetIVSReservationMutationVariables,
  APITypes.GetIVSReservationMutation
 ; */ 
exports.getIVSReservationAdmin = /* GraphQL */ `mutation GetIVSReservationAdmin($arn: String, $name: String) {
  getIVSReservationAdmin(arn: $arn, name: $name) {
    arn
    authorized
    insecureIngest
    latencyMode
    name
    preset
    recordingConfigurationArn
    tags
    type
    ingestEndpoint
    ingestServer
    playbackUrl
    streamKeys
    firstStreamStartTime
    streamSessions {
      streamId
      startTime
      endTime
      hasErrorEvent
      __typename
    }
    __typename
  }
}
` ; /* GeneratedMutation<
  APITypes.GetIVSReservationAdminMutationVariables,
  APITypes.GetIVSReservationAdminMutation
 ; */ 
exports.listIVSReservations = /* GraphQL */ `mutation ListIVSReservations($somearg: String) {
  listIVSReservations(somearg: $somearg) {
    arn
    authorized
    insecureIngest
    latencyMode
    name
    preset
    recordingConfigurationArn
    tags
    type
    ingestEndpoint
    ingestServer
    playbackUrl
    streamKeys
    firstStreamStartTime
    streamSessions {
      streamId
      startTime
      endTime
      hasErrorEvent
      __typename
    }
    __typename
  }
}
` ; /* GeneratedMutation<
  APITypes.ListIVSReservationsMutationVariables,
  APITypes.ListIVSReservationsMutation
 ; */ 
exports.listIVSReservationsStreams = /* GraphQL */ `mutation ListIVSReservationsStreams($somearg: String) {
  listIVSReservationsStreams(somearg: $somearg) {
    Reservation {
      arn
      name
      playbackUrl
      tags
      firstStreamStartTime
      streamSessions {
        streamId
        startTime
        endTime
        hasErrorEvent
        __typename
      }
      __typename
    }
    Stream {
      reservationArn
      health
      playbackUrl
      startTime
      state
      streamId
      viewerCount
      __typename
    }
    __typename
  }
}
` ; /* GeneratedMutation<
  APITypes.ListIVSReservationsStreamsMutationVariables,
  APITypes.ListIVSReservationsStreamsMutation
 ; */ 
exports.listIVSReservationsStreamsAdmin = /* GraphQL */ `mutation ListIVSReservationsStreamsAdmin($somearg: String) {
  listIVSReservationsStreamsAdmin(somearg: $somearg) {
    Reservation {
      arn
      authorized
      insecureIngest
      latencyMode
      name
      preset
      recordingConfigurationArn
      tags
      type
      ingestEndpoint
      ingestServer
      playbackUrl
      streamKeys
      firstStreamStartTime
      streamSessions {
        streamId
        startTime
        endTime
        hasErrorEvent
        __typename
      }
      __typename
    }
    Stream {
      reservationArn
      health
      playbackUrl
      startTime
      state
      streamId
      viewerCount
      __typename
    }
    __typename
  }
}
` ; /* GeneratedMutation<
  APITypes.ListIVSReservationsStreamsAdminMutationVariables,
  APITypes.ListIVSReservationsStreamsAdminMutation
 ; */ 
exports.listIVSReservationsStreamsForNlseSite = /* GraphQL */ `mutation ListIVSReservationsStreamsForNlseSite($somearg: String) {
  listIVSReservationsStreamsForNlseSite(somearg: $somearg)
}
` ; /* GeneratedMutation<
  APITypes.ListIVSReservationsStreamsForNlseSiteMutationVariables,
  APITypes.ListIVSReservationsStreamsForNlseSiteMutation
 ; */ 
exports.deleteIVSReservation = /* GraphQL */ `mutation DeleteIVSReservation($arn: String, $contentId: String, $name: String) {
  deleteIVSReservation(arn: $arn, contentId: $contentId, name: $name)
}
` ; /* GeneratedMutation<
  APITypes.DeleteIVSReservationMutationVariables,
  APITypes.DeleteIVSReservationMutation
 ; */ 
exports.addMetadataToVODUpload = /* GraphQL */ `mutation AddMetadataToVODUpload($metadata: AWSJSON) {
  addMetadataToVODUpload(metadata: $metadata) {
    filename
    state
    errorMessage
    __typename
  }
}
` ; /* GeneratedMutation<
  APITypes.AddMetadataToVODUploadMutationVariables,
  APITypes.AddMetadataToVODUploadMutation
 ; */ 
exports.addIdsToVODUpload = /* GraphQL */ `mutation AddIdsToVODUpload($ids: AWSJSON) {
  addIdsToVODUpload(ids: $ids) {
    filename
    state
    errorMessage
    __typename
  }
}
` ; /* GeneratedMutation<
  APITypes.AddIdsToVODUploadMutationVariables,
  APITypes.AddIdsToVODUploadMutation
 ; */ 
exports.getIVSReservationStreamPublic = /* GraphQL */ `mutation GetIVSReservationStreamPublic($arn: String, $name: String) {
  getIVSReservationStreamPublic(arn: $arn, name: $name) {
    Reservation {
      arn
      name
      playbackUrl
      tags
      firstStreamStartTime
      streamSessions {
        streamId
        startTime
        endTime
        hasErrorEvent
        __typename
      }
      __typename
    }
    Stream {
      reservationArn
      health
      playbackUrl
      startTime
      state
      streamId
      viewerCount
      __typename
    }
    __typename
  }
}
` ; /* GeneratedMutation<
  APITypes.GetIVSReservationStreamPublicMutationVariables,
  APITypes.GetIVSReservationStreamPublicMutation
 ; */ 
