import { API, graphqlOperation, GRAPHQL_AUTH_MODE } from '@aws-amplify/api';
import { createPrimaryContactUs } from '@/graphql/mutations';

// This method receives the input paramters for a contact that a user provides.
// The contact information provided includes:
// 1) First Name
// 2) Last Name
// 3) Email Address
// 4) Subject
// 5) Division
// 6) Message
export const addContact = async ({ 
    firstName, 
    lastName, 
    email, 
    subject, 
    division, 
    message, 
}) => {
    try {
        const query = {
            firstName: firstName.length === 0 ? null : firstName, 
            lastName: lastName.length === 0 ? null : lastName,
            userEmailAddress: email,
            subject,
            message,
            divisionType: division,
            helpServiceEmail: "nlseplussupport@nlse.com",
            createdBy: "Unknown",
            updatedBy: "Unknown"
        }

        const primaryContactCreateResponse = await API.graphql({
            query: createPrimaryContactUs,
            variables: { input: query },
            authMode: GRAPHQL_AUTH_MODE.API_KEY,
        });

        const contactData = primaryContactCreateResponse.data.createPrimaryContactUs;

        return contactData;
    }
    catch(error) {
        return error;
    }
}

// These are the enumerations for Contacts as described in the schema
export const contactDivisions = () => {
    return [
        {text: "Advertiser Sponsor", value:"Advertiser_Sponsor"},
        {text: "Career", value:"Career_Opportunities"},
        {text: "Content", value:"Content"},
        {text: "General", value:"General"},
        {text: "Next Collegiate League", value:"Next_Collegiate_League"},
        {text: "Partnership", value:"Partnership"},
    ]
}